import { Spinner } from "@chakra-ui/react";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import Div from "../html-elements/div.component";
import PostsList from "./posts-list.component";
import useInfiniteScroll from "../../shared/hooks/infinitie-scroll.hook";
import postService from "../../services/post.service";
import userService from "../../services/user.service";
import toastService from "../../services/toast.service";
import { POST_TYPES } from "../../shared/constants";

function PostsFeed({ type = POST_TYPES.HOME, getPostsFn, moreParamsForGetPostsFn = [], group = {}, searchText, handleSetSearchText }) {
    const [posts, setPosts] = useState([]);
    const [userFollows, setUserFollows] = useState({});
    const [page, setPage] = useState(1);
    const [hardReload, setHardReload] = useState({});

    const loadMorePosts = async (options) => {
        let params = [];
        params.push(...moreParamsForGetPostsFn)
        params.push({ params: { limit: 10, page: page, search: encodeURIComponent(searchText) }, ...options })
        const { data, isSuccess, errorMessage } = (await getPostsFn(...params)).data
        if (isSuccess) {
            setPosts(prev => [...prev, ...data])
            return data;
        }

        return [];
    }

    const { isLoading, isError, error, hasNextPage } = useInfiniteScroll(loadMorePosts, page, hardReload);

    const observer = useRef();
    const loaderDiv = useCallback(node => {
        if (isLoading) {
            return;
        }

        if (isError) {
            return;
        }

        if (observer.current) {
            observer.current.disconnect();
        }

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasNextPage) {
                setPage(prev => prev + 1);
            }
        });

        if (node && hasNextPage) {
            observer.current.observe(node);
        }
    }, [error, hasNextPage, isError, isLoading]);

    const initialData = async () => {
        try {
            const allFollowedUserIdsResponse = await userService.getAllFollowedUserIds();
            const allFollowedUserIdsData = allFollowedUserIdsResponse.data;
            const followedUsers = {};

            allFollowedUserIdsData.data.map(d => {
                followedUsers[d.followId] = true;
            });

            setUserFollows(followedUsers);
        } catch (error) {
            console.log(error);
        }

    }

    const onClickReportHandle = async (reason, postId) => {
        try {
            const response = await postService.reportAsync(postId, reason);

            // Notify
            setPosts(prev => {
                const data = [];
                for (const post of prev) {
                    if (post.id === postId) {
                        data.push({ ...post, reported: [{ posts_reports: { date_create: Date.now() } }], isReportedByMe: true });

                        continue;
                    }
                    data.push({ ...post });
                }
                return data;
            });
            toastService.success(response.data.data);
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    const handleDeletePost = async (uuid) => {
        try {
            (type === POST_TYPES.GROUP && (group?.isAdmin || group?.isOwner))
                ? await postService.deleteFromGroupAsync(group.id, uuid)
                : await postService.deleteAsync(uuid);

            setPosts(prev => {
                const data = [];
                for (const post of prev) {
                    if (post.uuid !== uuid) {
                        data.push({ ...post });
                    }
                }

                return data;
            });
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    const toggleFollow = async (userId) => {
        try {
            if (!userFollows[userId]) {
                await userService.follow(userId);
                setUserFollows(prev => ({ ...prev, [userId]: true }));
            } else {
                await userService.unfollow(userId);
                setUserFollows(prev => ({ ...prev, [userId]: false }));
            }
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }

    };

    useEffect(() => {
        setPage(1);
        setPosts([]);
        setHardReload({});
    }, [searchText])

    // Clears homepage search field when leave the homepage
    useEffect(() => {
        initialData();
        return () => {
            if (type === POST_TYPES.HOME && handleSetSearchText) {
                handleSetSearchText('');
            }
        }
    }, []);

    return (
        <Fragment>
            <PostsList
                posts={posts}
                toggleFollow={toggleFollow}
                userFollows={userFollows}
                includePeopWhoLikesAndCommentIt={true}
                repostMode={true}
                addGroupAvatarToPost={type === POST_TYPES.HOME}
                addUserInteractionHeaderToPost={type === POST_TYPES.HOME}
                onClickReport={onClickReportHandle}
                handleDeletePost={handleDeletePost} />
            {!hasNextPage && !posts && <Div>No more posts to show!</Div>}
            <div ref={loaderDiv} />
            {isLoading && <Spinner />}
        </Fragment>)
}

export default PostsFeed;
