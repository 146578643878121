import React, { useState, Fragment, memo } from 'react';
import { Spinner } from '@chakra-ui/react';
import { Document, Page, pdfjs } from 'react-pdf';
import Span from '../html-elements/span.component';
import { STATIC_URL } from '../../shared/constants';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Width takes precedence over length
// Second part is not used, try to remove library
function PdfViewer({
    pdf,
    widthOfPdfAsNumber,
    heightOfPdfAsNumber,
    showOnlyFirstPage = true,
    showfPageNumbers = true,
    isLocal = true,
    colorOfLoading = 'fff' }) {
    const [numPages, setNumPages] = useState(0);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const file = isLocal
        ? pdf
        : { url: `${STATIC_URL}/${pdf}` }

    return (
        <Fragment>
            {showOnlyFirstPage &&
                <Fragment>
                    <Document
                        loading={<Spinner color={colorOfLoading} />}
                        error={''}
                        file={file}
                        onLoadSuccess={onDocumentLoadSuccess}>
                        <Page
                            loading={<Spinner color={colorOfLoading} />}
                            width={widthOfPdfAsNumber}
                            height={heightOfPdfAsNumber}
                            pageNumber={1} />
                    </Document>
                    {showfPageNumbers && <Span fontSize={'14px'}>Page numbers {numPages}</Span>}
                </ Fragment>
            }
            {!showOnlyFirstPage &&
                <Fragment>
                    <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from(new Array(numPages), (el, index) => (<Page key={`page_${index + 1}`} pageNumber={index + 1} />))}
                    </Document>
                    {showfPageNumbers && <Span fontSize={'14px'}> Page numbers {numPages}</Span>}
                </ Fragment>
            }
        </Fragment>
    )
}

export default memo(PdfViewer);
