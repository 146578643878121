import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { HStack, VStack, Flex } from '@chakra-ui/react';
import Div from '../components/html-elements/div.component';
import ImageUploader from '../components/shared/image-uploader.component';
import FormInput from '../components/html-elements/form-input.component';
import WrapperButtonLink from '../components/buttons/wrapper-button-link.component';
import WrapperButton from '../components/buttons/wrapper-button.component';
import FormDropDown from '../components/html-elements/form-drop-down.component';
import FormPhoneInput from '../components/inputs/form-phone-input.component';
import userService from '../services/user.service';
import useAuth from '../shared/hooks/auth.hook';
import { ROUTES, VARIANT_KEYS, FONTS, JOB_POSITIONS } from '../shared/constants';
import Yup from '../shared/yup';
import toastService from '../services/toast.service';

function EditProfilePage() {
  const auth = useAuth();
  const [user, setUser] = useState({ ...auth.user });
  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useNavigate();

  const handleFileSelect = (file) => {
    setSelectedFile(file);
  };

  const removeSelectedPicture = () => {
    setUser(prev => ({ ...prev, img_url: null }));
  }

  const updateAvatarColor = (color) => {
    setUser((prev) => ({ ...prev, avatar_color: color, img_url: null }));
  };

  const updateAsync = async (formData) => {
    try {
      const response = await userService.updateByIdAsync(
        auth.user.id,
        formData
      );
      
      const user = (await userService.getByIdAsync(auth.user.id)).data.data;
      auth.updateEployeeData(user);

      navigate(ROUTES.MY_PROFILE);
    } catch (error) {
      toastService.error(error.response.data.errorMessage);
    }
  };

  const schema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .isNameValid('First name and Last name can contain only letters'),
    phone: Yup
      .string()
      .isPhoneValid('Enter a valid phone number')
      .max(11, 'Phone number should be a maximum of 11 digits')
      .required('Phone number is required'),
    country: Yup
      .string()
      .isCountryValid('Country - min. 2 symbols, only letters, starting with uppercase'),
    city: Yup
      .string().isCityValid('0 or 2+ symbols, only letters, 1st uppercase'),
  });

  const stylingLink = {
    variant: VARIANT_KEYS.bgAndColor.bg_transparent_color_blue4F93F1,
    fontSize: '13px',
    lineHeight: '28px',
    height: '13px',
    p: '0px',
    letterSpacing: '1.1px',
  };

  const stylingButton = {
    variant: VARIANT_KEYS.bgAndColor.bg_oragneFDB71A_oragneFFB81C_color_black141414_black141414,
    fontSize: '22px',
    h: '48px',
    fontWeight: FONTS.normal[700],
    w: '100%',
    isDisabled: auth.user.isMuted,
    _hover: {},
  };

  return (
    <Div h='734px' w={{ sm: '340px' }}>
      <Formik
        initialValues={{
          name: auth.user.name,
          job: auth.user.job,
          city: auth.user.city,
          country: auth.user.country,
          countryCode: auth.user.countryCode,
          phone: auth.user.phone,
        }}
        validationSchema={schema}
        onSubmit={async (values, actions) => {
          const currentValues = { ...values };
          const formData = new FormData();

          Object.keys(user).forEach((key) => {
            if (user[key] !== currentValues[key] && currentValues[key]) {
              formData.append(key, currentValues[key]);
            } else {
              formData.append(key, user[key]);
            }
          });

          if (selectedFile) {
            formData.append('file', selectedFile);
          }

          await updateAsync(formData);
        }}>
        {(props) => (
          <Form style={{ height: '100%' }}>
            <Flex
              justifyContent={'space-between'}
              direction={'column'}
              w={'100%'}
              h={'100%'} >
              <VStack spacing={10}>
                <Div height='128px'>
                  <ImageUploader
                    user={{ ...user }}
                    removeSelectedPicture={removeSelectedPicture}
                    handleSelectPicture={handleFileSelect}
                    updateAvatarColor={updateAvatarColor} />
                </Div>
                <VStack spacing={3}>
                  <FormInput
                    name={'name'}
                    label={'Name'}
                    placeholder={'First name and Last name'}
                    isRequired={true}
                    inputStyling={{ variant: VARIANT_KEYS.INPUT.PRIMARY }}
                    formikProps={props} />
                  <FormPhoneInput
                    formikFieldNames={{ number: 'phone', code: 'countryCode' }}
                    formikProps={props}
                    isRequired={true} />
                  <Div>
                    <FormDropDown
                      styling={{ variant: VARIANT_KEYS.SELECT.PRIMARY }}
                      label={'Job position'}
                      name={'job'}
                      kvp={{ ...JOB_POSITIONS }}
                      formikProps={props} />
                  </Div>
                  <HStack alignItems={'start'} pb='5px'>
                    <FormInput
                      name={'country'}
                      label={'Country'}
                      placeholder={'Bulgaria'}
                      inputStyling={{ variant: VARIANT_KEYS.INPUT.PRIMARY }}
                      formikProps={props} />
                    <FormInput
                      name={'city'}
                      label={'City/State'}
                      placeholder={'Sofia'}
                      inputStyling={{ variant: VARIANT_KEYS.INPUT.PRIMARY }}
                      formikProps={props} />
                  </HStack>
                  <VStack w='100%' paddingBottom={'20px'} alignItems={'start'}>
                    <WrapperButtonLink
                      to={''}
                      message={'Change email'}
                      styling={stylingLink} />
                    <WrapperButtonLink
                      to={''}
                      message={'Change password'}
                      styling={stylingLink} />
                  </VStack>
                </VStack>
              </VStack>
              <Div>
                <WrapperButton
                  styling={stylingButton}
                  message={'Update'}
                  type={'submit'} />
              </Div>
            </Flex>
          </Form>
        )}
      </Formik>
    </Div>
  );
}

export default EditProfilePage;
