import React from 'react';
import { ModalOverlay, Modal, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure } from '@chakra-ui/react';
import Div from '../html-elements/div.component';
import ExternalIcon from '../icons/external-icon.component';
import { MdPhotoCamera } from 'react-icons/md';

function PhotoModal({ title, stylingOuterDiv, stylingInnerIcon, children }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <>
            <Div {...stylingOuterDiv}>
                <ExternalIcon onClick={onOpen} styling={stylingInnerIcon} icon={MdPhotoCamera} />
            </Div>

            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {children}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default PhotoModal;
