import React, { useState, useEffect } from 'react';
import { InputLeftElement, RadioGroup, Radio, VStack, useMediaQuery, Flex } from '@chakra-ui/react';
import Search from '../components/inputs/search-component'
import Div from '../components/html-elements/div.component';
import ExternalIcon from '../components/icons/external-icon.component';
import Span from '../components/html-elements/span.component';
import RecentSearches from '../components/contacts/recent-searches.component';
import UserSearchListResults from '../components/contacts/user-search-list-results.component'
import WrapperButton from '../components/buttons/wrapper-button.component';
import useAuth from '../shared/hooks/auth.hook';
import userService from '../services/user.service';
import { FONTS, VARIANT_KEYS, LOCLA_STORAGE_KEYS, MEDIA } from '../shared/constants';
import { idGenerator } from '../shared/utils/common.utils';
import { FiSearch } from 'react-icons/fi';
import { debounce } from 'lodash';

const RESULTS_PER_PAGE = 5;

function ContactsPage() {
    const gen = idGenerator();

    const [topic, setTopic] = useState('name');
    const [lastSearches, setLastSearches] = useState([]);
    const [search, setSearch] = useState('');
    const [searchMessage, setSearchMessage] = useState('');
    const [data, setData] = useState([]);
    const { user } = useAuth();
    const [isLargerThan750] = useMediaQuery(MEDIA.MIN_WIDTH_750_PX);

    const lastSeachesKey = isLargerThan750
        ? LOCLA_STORAGE_KEYS.LAST_SEARCHES_DESKTOP_KEY
        : LOCLA_STORAGE_KEYS.LAST_SEARCHES_MOBILE_KEY;

    const findEmployees = async (search, topic, offset = 0, limit = 5) => {
        try {
            const response = await userService.findEmpoyeesWithoutCurrenOneAsync(search, topic, offset, limit);

            const data = response.data.data;
            if (data.length) {
                setData(data);
                setSearchMessage('');

                return;
            } else {
                setData(data);
                setSearchMessage('No such users');
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleChange = async (e) => {
        const search = e.target.value;
        setSearch(search);

        if (search.length < 3) {
            return;
        }

        await findEmployees(search, topic);
    };

    const getNextResults = async () => {
        const offset = gen() * RESULTS_PER_PAGE;
        const currentData = [...data];

        await findEmployees(
            search,
            topic,
            offset,
            RESULTS_PER_PAGE,
        );

        setData(prevState => [...currentData, ...prevState]);
    }

    const handleDebounce = debounce(handleChange, 500);

    const stylingInputSearch = {
        variant: VARIANT_KEYS.bg.bg_grayE2E2E2_gray333333,
        fontSize: '13px',
        height: '48px',
        width: '100%',
        letterSpacing: '1.1px',
        fontWeight: FONTS.normal[400],
    }

    const onClickHandle = async (e) => {
        await findEmployees(search, topic);
    };

    const inputElementLeft = <InputLeftElement height='100%'>
        <WrapperButton
            message={<ExternalIcon styling={{ width: '20px', height: '20px' }} icon={FiSearch} />}
            styling={{ variant: VARIANT_KEYS.bgAndColor.bg_transparent_color_black141414_whiteFFFFFF }}
            onClickHandle={onClickHandle}>
        </WrapperButton>
    </InputLeftElement>

    const raioButtons = ['name', 'job', 'phone', 'country', 'city'].map(value => {
        return <Radio key={value} value={value}>{value}</Radio>
    });

    const searchResults = search.length > 2
        ? (data && data.length
            ? <>
                <Div marginTop='40px'><UserSearchListResults data={data} /></Div>
                {
                    data.length === 5 &&
                    <WrapperButton
                        styling={
                            {
                                marginTop: '20px',
                                ...FONTS.normal[400],
                                variant: VARIANT_KEYS.bgAndColor.bg_transparent_color_black141414_whiteFFFFFF
                            }
                        }
                        message={'View more'}
                        onClickHandle={getNextResults} />
                }
            </>
            : <Div>{searchMessage}</Div>)
        : (lastSearches
            ? (<RecentSearches queries={lastSearches} />)
            : (<Div marginTop='15px'>No recent searches</Div>))

    useEffect(() => {
        setLastSearches(getLastSearchesFromLocalStorage());
    }, [isLargerThan750]);

    const getLastSearchesFromLocalStorage = () => {
        const lastSearchesStored = localStorage.getItem(lastSeachesKey);
        return JSON.parse(lastSearchesStored);
    };

    return (
        <Div minH={'734px'}>
            <Flex
                alignItems={'start'}
                textAlign='center'
                direction={'column'}
                w={{ sm: '340px', md: '455px' }}>
                <Div fontSize='20px'>
                    👋 Hello {user && user.name && <Span variant={VARIANT_KEYS.color.color_gray424242_orangeFFB81C}>{user.name}</Span>}
                </Div>
                <Div mt='60px' w={'100%'}>
                    <VStack align={'start'} w={'100%'} spacing={3} textAlign='left'>
                        <Span
                            variant={VARIANT_KEYS.color.color_black000000_whiteFFFFFF}
                            fontSize='16px'
                            fontWeight={FONTS.normal[400]}
                            width='100%'
                            letterSpacing='1.1px'
                            textAlign='left'>
                            Search for contacts from here
                        </Span>
                        <Search
                            handleChange={handleDebounce}
                            inputLeftElement={inputElementLeft}
                            styling={stylingInputSearch}>
                            {searchResults}
                        </Search>
                    </VStack>
                </Div>
                {search.length < 3
                    ? (<Div mt='60px'>
                        <Span
                            fontWeight={FONTS.normal[400]}
                            variant={VARIANT_KEYS.color.color_black000000_whiteFFFFFF}>
                            You can search by:
                        </Span>
                        <RadioGroup onChange={setTopic} value={topic}>
                            <VStack spacing={1} align={'start'}>
                                {raioButtons}
                            </VStack>
                        </RadioGroup>
                    </Div>)
                    : null}
            </Flex>
        </Div>
    )
}

export default ContactsPage;
