import io from 'socket.io-client'
import authService from '../../services/auth.service';
import { DOMAIN_URL } from '../constants';

class SocketHelper {
    static socket = null;
    eventsToRegister = [];

    connect = () => {
        setTimeout(() => {
            if (!this.socket) {
                this.socket = io(
                    `${DOMAIN_URL}`,
                    {
                        auth: { token: authService.getToken() },
                        forceNew: true,
                    });
            }
            this.registerAllEvents();
        }, 1000);
    }

    disconnect = () => {
        this.socket.disconnect();
        this.socket = null;
    }

    addEventToQueue = (event, cb, done) => {
        this.eventsToRegister.push({ event, cb, done });
    }

    registerAllEvents = () => {
        if (this.socket && this.eventsToRegister.length > 0) {
            this.eventsToRegister.forEach(({ event, cb, done }) => {
                this.registerEvent(event, cb);
                if (done) { done(); }
            });
        }
    }

    emit = (event, payload) => {
        this.socket.emit(event, payload);
    }

    registerEvent = (event, cb) => {
        if (this.socket.hasListeners(event)) {
            this.socket.off(event);
        }
        this.socket.on(event, cb);
    }

    isConnected = () => {
        return (this.socket && this.socket.connected);
    }
}

const socketHelper = new SocketHelper();

export default socketHelper;