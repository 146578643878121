import { mode } from "@chakra-ui/theme-tools";
import { COLOR_NAMES } from '../shared/constants';

const styles = {
    global: props => ({
        body: {
            color: mode('gray.800', '#FFFFFF')(props),
            bg: mode('gray.100', COLOR_NAMES.black161717)(props),
            fontFamily: mode('Roboto, san-serif', 'Roboto, san-serif')(props),
        },
    })
};

export default styles;
