import { Image, Text, InputLeftElement } from '@chakra-ui/react';
import Span from '../html-elements/span.component';
import FormDropDown from '../html-elements/form-drop-down.component';
import * as countries from '../../shared/import-flags.js';
import { FONT_FAMILY_NAME, LOCATIONS } from '../../shared/constants';

const countryDropdownStyling = {
  w: '65px',
  position: 'absolute',
  opacity: '0',
  left: '7px',
  top: '-16px',
  zIndex: 999,
  border: 'none',
  outline: 'none',
  _hover: {
    boxShadow: 'none!important',
    outline: 'none',
  },
  _focus: {
    boxShadow: 'none!important',
    outline: 'none',
  },
};

const FormPhoneCodeSelect = ({ formikProps, name }) => {
  const country = formikProps.values[name] || '+359';

  return (
    <InputLeftElement sx={{ mt: '29px' }}>
      <Image
        w='40px'
        pl='10px'
        position='absolute'
        src={
          countries[LOCATIONS.find(c => c.internationalCountryCode === country).countryCode.toLocaleLowerCase()]
        } />
      <FormDropDown
        styling={countryDropdownStyling}
        name={name}
        kvp={Object.fromEntries(
          LOCATIONS.map((location) => [
            location.internationalCountryCode,
            location.location,
          ])
        )}
        formikProps={formikProps}
        onChange={(e) => {
          formikProps.setFieldValue(name, e.target.value);
        }} />
      <Span position='absolute' sx={{ left: '62px' }}>
        <Text fontStyle={FONT_FAMILY_NAME} fontSize='16'>
          {
            LOCATIONS.find(
              (toSelect) => toSelect.internationalCountryCode === country
            ).internationalCountryCode
          }
        </Text>
      </Span>
    </InputLeftElement>
  );
};
export default FormPhoneCodeSelect;
