import React, { Fragment, useState } from 'react';
import Webcam from 'react-webcam';

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
};

function WebcamCapture({ handleImageScreenshot }) {
    const [imgSrc, setimgSrc] = useState(null);
    return (
        <Fragment>
            <Webcam
                audio={false}
                height={720}
                screenshotFormat="image/jpeg"
                width={1280}
                videoConstraints={videoConstraints}>
                {({ getScreenshot }) => (
                    <button
                        onClick={() => {
                            const image = getScreenshot();
                            if (image) {
                                setimgSrc(image);
                                handleImageScreenshot(image);
                            }
                        }}>
                        Take a picture
                    </button>
                )}
            </Webcam>

            {imgSrc && <img src={imgSrc} />}
        </Fragment>
    )
}

export default WebcamCapture;
