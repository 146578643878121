import React from 'react';
import { Box, useStyleConfig } from '@chakra-ui/react'
import { FONT_FAMILY_NAME } from '../../shared/constants';

function Div(props) {
    const { variant, border, ...rest } = props

    const styles = useStyleConfig('Div', { variant, border });

    return <Box __css={styles} {...rest} fontFamily={FONT_FAMILY_NAME} />
}

export default Div;
