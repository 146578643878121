import React from 'react';
import { Link } from 'react-router-dom';
import { VStack } from '@chakra-ui/react';
import Div from '../html-elements/div.component';
import UserSearchItem from './user-search-item.component';
import { ROUTES } from '../../shared/constants';

function UserSearchListResults({ data }) {
    return (
        <VStack spacing={5} alignItems={'start'}>
            {
                data.map((user, i) => {
                    const link = `${ROUTES.USER_PROFILE}/${user.id}`;

                    return (
                        <Div key={user.email + i.toString()}>
                            <Link to={{ pathname: link }} state={{ fromNav: true }} >
                                <Div >
                                    <UserSearchItem user={{ ...user }} />
                                </Div>
                            </Link>
                        </Div>
                    )
                })
            }
        </VStack>
    )
}

export default UserSearchListResults;
