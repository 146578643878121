import { mode } from "@chakra-ui/theme-tools";
import { COLOR_NAMES } from '../../shared/constants';

const Notification = {
    //use variants change variant for clicked
    baseStyle: (props) => ({
        bg: mode(!props.clicked ? COLOR_NAMES.grayF4F4F4 : '', !props.clicked ? COLOR_NAMES.gray4a4a4a : '')(props),
        _hover: mode({ background: COLOR_NAMES.grayE8E8E8 }, { background: COLOR_NAMES.gray3D403D })(props)
    }),
    defaultProps: {},
}

export default Notification;
