import React, { useState } from 'react';
import { Flex, HStack, Avatar, VStack, Textarea, useDisclosure } from '@chakra-ui/react';
import WrapperButton from '../buttons/wrapper-button.component';
import Div from '../html-elements/div.component';
import { COLOR_NAMES, FONTS, FONT_FAMILY_NAME, STATIC_URL, VARIANT_KEYS } from '../../shared/constants';
import { MdFavorite } from 'react-icons/md';
import commentService from '../../services/comment.service';
import useAuth from '../../shared/hooks/auth.hook';
import toastService from '../../services/toast.service';
import postService from '../../services/post.service';
import Comment from './comment.component';
import ExternalIcon from '../icons/external-icon.component';
import LikesCoverModal from '../shared/likes-cover-modal.component';

function ActionBarComment({ data, onClickReport, handleDeleteComment, userGroupRole, userGroupMuteStatus }) {
    const { user, post, id } = data;
    const authUser = useAuth().user;

    const [commentMessage, setCommentMessage] = useState('');
    const [isTextareaActive, setIsTextareaActive] = useState(false);
    const [showRepliesList, setShowRepliesList] = useState(false);
    const [writeReplyField, setWriteReplyField] = useState(false);
    const [replies, setReplies] = useState([]);
    const [repliesCount, setRepliesCount] = useState([data.replies?.length]);
    const [page, setPage] = useState(1);
    const [hasMoreReplies, setHasMoreReplies] = useState(false);

    const handleOnChangeTextarea = (e) => {
        setCommentMessage(e.target.value);
    }

    const handleOnFocusTextarea = async () => {
        setIsTextareaActive(true);
    }


    const { isOpen: isShowUsersOpen, onOpen: onShowUsersOpen, onClose: onShowUsersClose } = useDisclosure();

    const [isLikedByMe, setIsLikedByMe] = useState(data.isLikedByMe || false);
    const [likesCount, setLikesCount] = useState(data.likesCount || 0);

    const getUsersWhoLikedComment = commentService.getUsersWhoLikesCommentAsync;

    const handleLike = async () => {
        const increment = isLikedByMe ? -1 : 1;
        try {
            const response = await commentService.changeCommentLikes({ likes: increment }, data.id);

            setLikesCount(response.data.data);
            setIsLikedByMe(prev => !prev);
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    };
    const addReply = async () => {
        try {
            await postService.createReplycomment({ message: commentMessage }, post.uuid, id);
            const repliesData = (await commentService.getRepliesByCommentId(data.id, 1)).data.data;
            setReplies(repliesData);
            setPage(2);
            setRepliesCount(prev => Number(prev) + 1);
            setIsTextareaActive(false);
            setCommentMessage('');
            setWriteReplyField(false);
            repliesData.length > 4 && setHasMoreReplies(true)
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    const handleDeleteReply = async (commentId, authorId) => {
        try {
            await handleDeleteComment(commentId, authorId)
            setReplies(replies => replies.filter(reply => reply.id !== commentId));
            setRepliesCount(prev => Number(prev) - 1);
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    const handleReplyChange = async ({ message, id }) => {
        try {
            await commentService.editAsync(id, message);
            setReplies(prev => {
                const data = [];
                for (const comment of prev) {
                    if (comment.id === id) {
                        data.push({ ...comment, message: message, isUpdateMode: false });

                        continue;
                    }

                    data.push({ ...comment, isUpdateMode: false });
                }

                return data;
            });
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    const handleModeReplies = (value, id) => {
        setReplies(prev => {
            const data = [];
            for (const comment of prev) {
                if (comment.id === id) {
                    data.push({ ...comment, isUpdateMode: value });

                    continue;
                }

                data.push({ ...comment, isUpdateMode: false });
            }

            return data;
        });
    }

    const loadReplies = async (isOnlyShow) => {
        if (page === 1) {
            try {
                await loadMoreReplies();
            } catch (error) {
                console.log(error);
            }
        }
        // Check is nested reply, if nested add name tag
        if (data.parentCommentId) {
            setCommentMessage(`${data.user.name}  `);
        }

        if (isOnlyShow) {
            setShowRepliesList(prev => !prev)
            setWriteReplyField(false);
            return;
        }

        handleShowRepliesList()
        setWriteReplyField(true);
    }

    const handleShowRepliesList = () => {
        if (!writeReplyField) {
            setShowRepliesList(true);
        }
        else {
            setShowRepliesList(prev => !prev);
        }
    }

    const loadMoreReplies = async () => {
        try {
            const repliesData = (await commentService.getRepliesByCommentId(data.id, page)).data.data;
            repliesData.length < 5
                ? setHasMoreReplies(false)
                : setHasMoreReplies(true);

            setReplies(prev => [...prev, ...repliesData]);
            setPage(prev => prev + 1);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Flex w={'100%'} flexDir={'column'} >
            <Flex w={'100%'} flexDir={'column'}>
                <Flex
                    flexDir={'row'}
                    gap={'6px'}
                    justifyContent={'start'}
                    pl={data.parentCommentId ? '94px' : '54px'}>
                    <WrapperButton
                        onClickHandle={handleLike}
                        styling={{
                            variant: VARIANT_KEYS.color.color_gray5E5E5E_grayB4B4B4,
                            p: '0px',
                            h: '100%',
                        }}
                        message={
                            <Flex letterSpacing={'1.1px'} fontWeight={FONTS.normal[400].fontWeight}>
                                <Div pt='2px' fontSize={'14px'}>Like</Div>
                            </Flex>} />
                    {likesCount
                        ? <HStack>
                            <Div> • </Div>
                            <Flex onClick={onShowUsersOpen} cursor={'pointer'}>
                                <ExternalIcon icon={MdFavorite} styling={
                                    {
                                        w: '16px',
                                        h: '16px',
                                        p: '0px',
                                        mt: '2px',
                                        color: isLikedByMe ? 'red' : 'white',
                                        bg: 'transparent'
                                    }} />
                                {likesCount}
                                <LikesCoverModal
                                    componentId={data.id}
                                    isOpen={isShowUsersOpen}
                                    isClose={onShowUsersClose}
                                    getUsersWhoLiked={getUsersWhoLikedComment} />
                            </Flex>
                        </HStack>
                        : null
                    }
                    <Div fontSize={'14px'} letterSpacing={'1.1px'}>|</Div>
                    <WrapperButton
                        onClickHandle={() => loadReplies(false)}
                        styling={{
                            variant: VARIANT_KEYS.color.color_gray5E5E5E_grayB4B4B4,
                            p: '0px',
                            h: '100%'
                        }}
                        message={
                            <Flex letterSpacing={'1.1px'} fontWeight={FONTS.normal[400].fontWeight}>
                                <Div pt='2px' fontSize={'14px'}>Reply</Div>
                            </Flex>} />
                    {
                        repliesCount > 0
                            ? <WrapperButton
                                onClickHandle={() => loadReplies(true)}
                                styling={{
                                    variant: VARIANT_KEYS.color.ligthColordarkMediumGrayDarColordarkMediumGray,
                                    p: '0px',
                                    h: '100%'
                                }}
                                message={
                                    <Flex letterSpacing={'1.1px'} fontWeight={FONTS.normal[400].fontWeight}>
                                        <Div pt='2px' fontSize={'14px'}>• {repliesCount} Replies</Div>
                                    </Flex>} />
                            : null
                    }
                </Flex>
                {showRepliesList && writeReplyField
                    ? <VStack
                        alignItems={'start'}
                        spacing={4}
                        h='100%'
                        w='100%'
                        pl='40px'
                        pb={'20px'}
                        pt={'20px'} >
                        <Flex w='100%'>
                            {authUser &&
                                <Avatar
                                    name={authUser.name}
                                    bg={authUser.img_url ? 'none' : authUser.avatar_color}
                                    src={authUser.img_url ? `${STATIC_URL}/${authUser.img_url}` : ''} size={'md'} />
                            }
                            <Textarea
                                h={'84px'}
                                ml={'10px'}
                                value={commentMessage}
                                onFocus={handleOnFocusTextarea}
                                onChange={handleOnChangeTextarea}
                                fontFamily={FONT_FAMILY_NAME}
                                fontSize={'14px'}
                                variant={VARIANT_KEYS.TEXTAREA.PRIMARY}
                                placeholder={user.isMutedInGroup ? 'You cannot write comments when you are muted.' : 'Add your comment'}
                                _placeholder={user.isMutedInGroup ? { color: COLOR_NAMES.redDE4946 } : null}
                                disabled={user.isMutedInGroup} />
                        </Flex>
                        {isTextareaActive && commentMessage.trim() &&
                            <Div>
                                <WrapperButton
                                    styling={{
                                        variant: VARIANT_KEYS.bgAndColor.bg_oragneFDB71A_oragneFFB81C_color_black141414_black141414,
                                        fontSize: '22px',
                                        h: '38px',
                                        w: '100px',
                                        ml: '60px',
                                        fontWeight: FONTS.normal[700].fontWeight,
                                        isDisabled: user.isMuted,
                                        _hover: {},
                                    }}
                                    message={'Post'}
                                    onClickHandle={addReply} />
                            </Div>
                        }
                    </VStack>
                    : null
                }
            </Flex>
            {showRepliesList
                ? <Div>
                    {replies.map((currCommentData, i) => {
                        return <Div key={`${currCommentData.id}_comment`} mb={'18px'} mt={'18px'}>
                            <Comment
                                handleMode={handleModeReplies}
                                handleCommentChange={handleReplyChange}
                                handleDeleteComment={handleDeleteReply}
                                onClickReport={onClickReport}
                                userGroupRole={userGroupRole}
                                data={currCommentData}
                                userGroupMuteStatus={userGroupMuteStatus}
                                parentCommentAuthorName={data.user.name}
                                parrentCommentAuthorId={data.author} />
                        </Div>
                    })}
                    {hasMoreReplies
                        ? <HStack cursor={'pointer'} justifyContent={'right'} onClick={loadMoreReplies}>
                            <Div>Load more replies</Div>
                        </HStack>
                        : null
                    }
                    {replies.length && data.parentCommentId ? <Div w={'100%'} h='60px'></Div> : null}
                </Div>
                : null
            }
        </Flex>
    )
}

export default ActionBarComment;
