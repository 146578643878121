import { useEffect, useState } from "react";
import { SOCKET_EVENTS } from "../constants";
import socketHelper from "../utils/socket.helper";

const useNotificationsCount = () => {
    const [hasNewNotifications, setHasNewNotifications] = useState(0);

    useEffect(() => {
        socketHelper.addEventToQueue(
            SOCKET_EVENTS.NEW_NOTIFICATION,
            ({ notificationsCount }) => { setHasNewNotifications(prev => prev + notificationsCount); },
        );
        socketHelper.addEventToQueue(SOCKET_EVENTS.READ_NOTIFICATIONS, () => { setHasNewNotifications(0); });
        socketHelper.registerAllEvents();
        const timeout = setTimeout(() => { socketHelper.emit(SOCKET_EVENTS.UNREAD_NOTIFICATIONS); }, 2000);

        return () => {
            clearTimeout(timeout);
        }
    }, []);

    return { hasNewNotifications };
}
export default useNotificationsCount;