import React from 'react';
import Div from '../html-elements/div.component';

function DefaultContainer({ media, children }) {
    const widhtBorder = media.isLargerThan750
        ? { w: '555px', borderRadius: '6px', minH: '825px' }
        : { w: '100%', border: 'none', minH: '60vh' };

    return (
        <Div
            pb='50px'
            pt='50px'
            align='center'
            margin='auto'
            maxH='auto'
            variant={media.bgColorPage}
            {...widhtBorder} >
            {children}
        </Div>
    )
}

export default DefaultContainer;
