import React from 'react';
import { useColorMode, Box } from '@chakra-ui/react';
import { FONTS, VARIANT_KEYS } from '../../shared/constants';
import ExternalIcon from '../icons/external-icon.component';
import { MdDarkMode, MdLightMode } from 'react-icons/md';
import WrapperButton from '../buttons/wrapper-button.component';


function ThemeMode() {
    const { colorMode, toggleColorMode } = useColorMode()

    const toggleThemeStyling = {
        fontWeight: FONTS.normal[400],
        variant: VARIANT_KEYS.bgAndColor.bg_transparent_color_blue19558D_whiteFFFFFF,
        fontSize: '16px',
        lineheight: '28px',
        letterSpacing: 1.1,
        leftIcon: <ExternalIcon icon={colorMode === 'dark' ? MdLightMode : MdDarkMode} />
    }

    return (
        <Box flexGrow={0.9}>
            <WrapperButton onClickHandle={toggleColorMode} message={`${colorMode === 'dark' ? 'Light mode' : 'Dark mode'} `} styling={toggleThemeStyling} />
        </Box>
    )
}

export default ThemeMode;
