import React from 'react';
import { useNavigate } from 'react-router-dom';
import ExternalIcon from '../icons/external-icon.component';
import { Button } from '@chakra-ui/react'
import { IoChevronBack } from 'react-icons/io5';
import { VARIANT_KEYS } from '../../shared/constants';

function MobileNavigationGoBackButton({ stlyling }) {
    const navigate = useNavigate();
    const icon = <ExternalIcon styling={stlyling} icon={IoChevronBack} />

    return (
        <Button p='0px' variant={VARIANT_KEYS.bgAndColor.bg_transparent_color_blue19558D_whiteFFFFFF} onClick={() => navigate(-1)}>
            {icon}
        </Button>
    )
}

export default MobileNavigationGoBackButton;
