import { useMediaQuery } from '@chakra-ui/react';
import { MEDIA, VARIANT_KEYS } from '../constants';

const useKnowMeMediaQuery = () => {
    const [isLargerThan750] = useMediaQuery(MEDIA.MIN_WIDTH_750_PX);
    const bgColorPage = !isLargerThan750
        ? VARIANT_KEYS.bg.bg_grayE2E2E2_gray333333
        : VARIANT_KEYS.bgAndborder.bg_grayE2E2E2_gray333333_border_1px_grayE2E2E2_1px_gray686868;

    return { isLargerThan750, bgColorPage };
};

export default useKnowMeMediaQuery;
