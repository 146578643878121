import { useEffect } from "react";
import axios from "../../services/axios.service";
import authHeader from '../utils/axios.utils';

const useRequestInterceptor = () => {
    useEffect(() => {
        const interceptor = axios.interceptors.request.use(
            (config) => {
                config.headers = authHeader();

                return config;
            },
            (error) => Promise.reject(error),
        );

        return () => {
            axios.interceptors.request.eject(interceptor);
        }
    }, [])
}

export default useRequestInterceptor;
