import React from 'react';
import Div from '../html-elements/div.component';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';

import 'swiper/css';

function Slider({ containerStyling, spaceBetween, slidesPerView, items }) {
    const { borderRadius, bg, w, pr, pt, pl, pb } = containerStyling || {};

    const currentItems = items.map((item, i) => <SwiperSlide key={`swipper_${i}`}>{item}</SwiperSlide>);

    return (
        <Div borderRadius={borderRadius} bg={bg} w={w} pr={pr} pl={pl} pt={pt} pb={pb}>
            <Swiper
                freeMode={true}
                spaceBetween={spaceBetween}
                modules={[FreeMode]}
                slidesPerView={slidesPerView}
                grabCursor={true}>
                {currentItems}
            </Swiper>
        </Div>
    )
}

export default Slider;
