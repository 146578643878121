import React from 'react';
import { RadioGroup, FormLabel, Stack, Radio } from '@chakra-ui/react';
import { FONT_FAMILY_NAME } from '../../shared/constants';

function FormRadioGroup({
    name,
    label,
    radioValues,
    labelStyling = {},
    radioGroupStyling = {},
    radioStyling = {},
    spacing = 2,
    direction = 'column',
    formikProps }) {
    return (
        <RadioGroup {...radioGroupStyling} id={name} name={name} value={formikProps.values[name]}>
            <Stack spacing={spacing} direction={direction}>
                {label &&
                    <FormLabel
                        fontFamily={FONT_FAMILY_NAME}
                        fontSize={'14px'} {...{ mb: '0px', ...labelStyling }}
                        htmlFor={name}>
                        {label}
                    </FormLabel>}
                {
                    radioValues.map((v, i) => {
                        return (
                            <Radio {...radioStyling} onChange={formikProps.handleChange} key={`${v.toString()}${i}`} value={v}>{v}</Radio>
                        )
                    })
                }
            </Stack>
        </RadioGroup>
    )
}

export default FormRadioGroup;
