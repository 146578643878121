import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Flex, HStack, InputRightElement, Spinner, Divider } from '@chakra-ui/react';
import Search from '../components/inputs/search-component';
import ExternalIcon from '../components/icons/external-icon.component';
import WrapperButton from '../components/buttons/wrapper-button.component';
import Div from '../components/html-elements/div.component';
import Slider from '../components/shared/slider.component';
import Span from '../components/html-elements/span.component';
import GroupList from '../components/groups/group-list.component'
import useKnowMeMediaQuery from '../shared/hooks/media-query.hook';
import useInfiniteScroll from '../shared/hooks/infinitie-scroll.hook';
import groupService from '../services/group.service';
import { debounce } from 'lodash';
import { GROUPS_CATEGORY, BG_GROUPS_CATEGORY, VARIANT_KEYS, FONTS, COLOR_NAMES } from '../shared/constants';
import { MdSearch } from 'react-icons/md';
import toastService from '../services/toast.service';

function GroupsPage() {
    const [groups, setGroups] = useState([]);
    const [categoryButtons, setCategoryButtons] = useState([]);
    const [titleOfGroup, setTitleOfGroup] = useState('');
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchCategory, setSearchCategory] = useState('ALL');
    const [hardReload, setHardReload] = useState({});

    const media = useKnowMeMediaQuery();

    const limit = 10;

    const loadMore = async (options) => {
        if (searchTerm && searchCategory === 'ALL') {
            const data = (await groupService.findGroupsByNameAsync(searchTerm, page, limit, options)).data.data;
            setGroups(prev => [...prev, ...data]);
            return data;
        }
        else if (searchCategory && !searchTerm) {
            const data = (await groupService.findGroupsByCategoryAsync(searchCategory, page, limit, options)).data.data;
            setGroups(prev => [...prev, ...data]);
            return data;
        }
    }

    const { isLoading, isError, error, hasNextPage } = useInfiniteScroll(loadMore, page, hardReload);

    const intObserver = useRef();

    const lastLoadedRef = useCallback(node => {
        if (isLoading) {
            return;
        }

        if (isError) {
            console.log(error)
            return;
        }

        if (intObserver.current) {
            intObserver.current.disconnect();
        }

        intObserver.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasNextPage) {
                setPage(prev => prev + 1);
            }
        });

        if (node) {
            intObserver.current.observe(node);
        }
    }, [hasNextPage, isLoading]);


    const initlializeCategoryButtons = () => {
        const buttons = [];

        Object.keys(BG_GROUPS_CATEGORY).forEach(k => {
            if (k === 'ALL') {
                buttons.push({
                    key: k,
                    bg: BG_GROUPS_CATEGORY[k],
                    isSelected: true,
                    text: 'All',
                });
            } else {
                buttons.push({
                    key: k,
                    bg: BG_GROUPS_CATEGORY[k],
                    isSelected: false,
                    text: GROUPS_CATEGORY[k],
                });
            }
        })

        setCategoryButtons(buttons);
    }

    const toggleJoinToGroup = async (group) => {
        try {
            !group.isCurrentUserIsSubscribed
                ? await groupService.joinToGroup(group.id)
                : await groupService.unjoinToGroup(group.id);

            setGroups(prev => {
                const newGroups = [];
                for (const currentGroup of prev) {
                    if (currentGroup.id === group.id) {
                        if (currentGroup.isCurrentUserIsSubscribed) {
                            newGroups.push({ ...currentGroup, isCurrentUserIsSubscribed: false, membersCount: currentGroup.membersCount - 1 });
                        } else {
                            newGroups.push({ ...currentGroup, isCurrentUserIsSubscribed: true, membersCount: currentGroup.membersCount + 1 });
                        }
                    } else {
                        newGroups.push({ ...currentGroup });
                    }
                }

                return newGroups;
            })
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    const applyToPrivateGroup = async (group) => {
        try {
            if (group.isCurrentUserIsApplied) {
                return;
            }

            const response = (await groupService.applyToGroupAsync(group.id)).data;
            if (!response.isSuccess) {
                return;
            }

            setGroups(prev => {
                const newGroups = [];
                for (const currentGroup of prev) {
                    if (currentGroup.id === group.id) {
                        newGroups.push({ ...currentGroup, isCurrentUserIsApplied: true });
                    } else {
                        newGroups.push({ ...currentGroup });
                    }
                }

                return newGroups;
            })
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    useEffect(() => {
        initlializeCategoryButtons();
    }, [])

    const changeCategory = (categoryKey) => {
        const currentCategoryButton = categoryButtons.find(cb => cb.key === categoryKey);
        if (!currentCategoryButton || currentCategoryButton.isSelected) {
            return false;
        }

        if (categoryKey !== 'ALL') {
            setTitleOfGroup(GROUPS_CATEGORY[categoryKey])
        } else {
            setTitleOfGroup('');
        }

        setCategoryButtons(buttons => {
            const newButtons = [];
            for (const button of buttons) {
                if (button.key === categoryKey) {
                    newButtons.push({ ...button, isSelected: true });
                } else {
                    newButtons.push({ ...button, isSelected: false });
                }
            }

            return newButtons;
        })

        return true;
    }

    const resetPagination = (searchTerm, searchCategory = 'ALL') => {
        setGroups([]);
        setPage(1);
        setHardReload({});
        setSearchTerm(searchTerm);
        setSearchCategory(searchCategory);
    }

    const onClicksetCategory = (categoryKey) => {
        const isCategoryChanged = changeCategory(categoryKey);
        if (isCategoryChanged) {
            resetPagination('', categoryKey);
        }
    }

    const handleChangeSearch = (e) => {
        const search = e.target.value.trim();
        changeCategory('ALL');
        resetPagination(search);
        e.target.blur();
        e.target.focus();
    }

    const handleInputDebounce = debounce(handleChangeSearch, 700);

    const stylingPlaceholder = {
        fontSize: '13px',
        color: COLOR_NAMES.grayAFAFAF,
        fontWeight: FONTS.normal[300]
    }

    const stylingInputSearch = {
        variant: VARIANT_KEYS.bg.bg_grayE2E2E2_gray333333,
        fontSize: '13px',
        height: '48px',
        width: '100%',
        letterSpacing: '1.1px',
        fontWeight: FONTS.normal[400],
        pl: '10px',
    }

    const inputElementRight = <InputRightElement height='100%'>
        <WrapperButton
            message={<ExternalIcon styling={{ width: '20px', height: '20px', color: 'white' }} icon={MdSearch} />}
            styling={{ variant: VARIANT_KEYS.bgAndColor.bg_transparent_color_black141414_whiteFFFFFF }}>
        </WrapperButton>
    </InputRightElement>

    const containerStyling = {
        borderRadius: '4px',
        bg: 'transperant',
        w: '100%',
    }

    const categoryItemButtons = categoryButtons.map((cb, i) => {
        return (<WrapperButton
            onClickHandle={() => onClicksetCategory(cb.key)}
            key={`${cb.key}_${i}`}
            message={cb.text}
            styling={{
                _active: { bg: !cb.isSelected ? cb.bg : COLOR_NAMES.gray666666 },
                _hover: { bg: !cb.isSelected ? cb.bg : COLOR_NAMES.gray666666 },
                _focusVisible: { border: 'none' },
                letterSpacing: '1.1px',
                h: '30px',
                bg: !cb.isSelected ? cb.bg : COLOR_NAMES.gray666666,
                fontSize: '12px',
                pl: '12px',
                pr: '12px',
                pt: '8px',
                pb: '8px',
                w: media.isLargerThan750 ? {} : '100%',
                fontWeight: FONTS.normal[400],
                borderRadius: '6px',
            }} />)
    })

    const loadingCoverMenu = <Div style={{ position: 'absolute', top: '0px', zIndex: '10', width: '100%', height: '150px' }} ></Div>

    return (
        <Div minH='734px' position='relative' w={{ sm: '340px', md: '455px' }}>
            <Search
                outerValue={searchTerm}
                placeholderStyling={stylingPlaceholder}
                placeholder={'Search for groups'}
                handleChange={handleInputDebounce}
                inputRightElement={inputElementRight}
                styling={stylingInputSearch} />

            {media.isLargerThan750
                ? (<Flex mt='20px' rowGap={'10px'} columnGap={'15px'} flexWrap={'wrap'} justifyContent={'start'} w='100%'>
                    {categoryItemButtons}
                </Flex>)

                : (<Div mt='15px'>
                    <Slider
                        containerStyling={containerStyling}
                        spaceBetween={10}
                        slidesPerView={3}
                        items={categoryItemButtons} />
                </Div>)
            }

            {groups && groups.length
                ? (<HStack h='50px' mt='60px' textAlign='start'>
                    <Span display='block' fontSize='44px' color={COLOR_NAMES.gren95A554}>{groups.length}</Span>
                    <Span display='block' letterSpacing='1.1px' fontSize='16px'>
                        {titleOfGroup} {groups.length === 1 ? 'group was' : 'groups were'} found
                    </Span>
                </HStack>)
                : (<Div pb='5px' textAlign='start' mt='60px'>
                    <Span letterSpacing='1.1px' fontSize='16px'>No groups found</Span>
                </Div>)
            }

            <Divider mb='60px' bg={COLOR_NAMES.grayC4C4C4} />

            {groups && groups.length
                ? <Div w='100%'>
                    <GroupList
                        data={groups}
                        toggleJoinToGroup={toggleJoinToGroup}
                        applyToPrivateGroup={applyToPrivateGroup} />
                </Div>
                : null
            }
            {isLoading && <Fragment>
                <Div style={{ marginTop: '30px' }} ><Spinner /></Div>
                {loadingCoverMenu}
            </Fragment>}
            <div ref={lastLoadedRef} style={{ marginTop: '20px' }}></div>
        </Div>
    )
}

export default GroupsPage;
