import { mode } from "@chakra-ui/theme-tools";
import { COLOR_NAMES } from '../../shared/constants';

export const Div = {
    baseStyle: {},
    sizes: {},
    variants: {
        bg_grayE2E2E2_gray333333: (props) => ({
            bg: mode(COLOR_NAMES.grayE2E2E2, COLOR_NAMES.gray333333)(props),
        }),
        bg_grayE2E2E2_gray333333_border_1px_grayE2E2E2_1px_gray686868: (props) => ({
            border: mode('1px solid #E2E2E2', '1px solid #686868')(props),
            bg: mode(COLOR_NAMES.grayE2E2E2, COLOR_NAMES.gray333333)(props),
        }),
        bg_grayE8E8E8_black212320_border_1px_grayC4C4C4_1px_gray666666_radius_6px: (props) => ({
            border: mode('1px solid #C4C4C4', '1px solid #666666')(props),
            borderRadius: '6px',
            bg: mode(COLOR_NAMES.grayE8E8E8, COLOR_NAMES.black212320)(props),
        }),
        color_gray5E5E5E_grayB4B4B4: (props) => ({
            color: mode(COLOR_NAMES.gray5e5e5e, COLOR_NAMES.grayB4B4B4)(props),
        }),
        color_black141414_whiteFFFFFF: (props) => ({
            color: mode(COLOR_NAMES.black141414, 'white')(props),
        }),
        color_gold906811_orangeFFB81C: (props) => ({
            color: mode(COLOR_NAMES.gold906811, COLOR_NAMES.oragneFFB81C)(props),
        }),
        bg_whiteFFFFFF_black212320: (props) => ({
            bg: mode('white', COLOR_NAMES.black212320)(props),
        }),
    },
    defaultProps: {},
}

export default Div;
