import React, { useRef } from 'react';
import { HStack, Image, VStack, Circle } from '@chakra-ui/react';
import PdfViewer from '../posts/pdf-viewer.component';
import VideoViewer from '../posts/video-viewer.component';
import Div from '../html-elements/div.component';
import WrapperButton from '../buttons/wrapper-button.component';
import ExternalIcon from '../icons/external-icon.component';
import { FONTS, VARIANT_KEYS, COLOR_NAMES, POST_FILE_TYPES } from '../../shared/constants';
import imageUpload from '../../static/images/upload.svg';
import { TbLetterX } from 'react-icons/tb';

function UploadWithPreviewButton({
    handleFileSelect,
    handleDeclineFileSelect,
    file,
    fileType = POST_FILE_TYPES.imageType,
    allowOnlyPicture = true }) {
    const fileInputRef = useRef(null);

    return (
        <VStack spacing={3}>
            <Div bg='transparent'>
                <WrapperButton
                    onClickHandle={() => fileInputRef?.current?.click()}
                    styling={{
                        variant: VARIANT_KEYS.bgAndColor.uploadPicture,
                        h: '76px',
                        w: '114px',
                        bg: '#262626',
                        borderRadius: '4px',
                        fontWeight: FONTS.normal[400]
                    }}
                    message={
                        <VStack m='auto' height={'100hv'}>
                            <Div><Image boxSize={'16px'} src={imageUpload} /></Div>
                            <Div textDecoration={'underline'} fontSize={'14px'}>Upload</Div>
                        </VStack>
                    } />

                <input
                    name='file'
                    id='file'
                    type='file'
                    style={{ display: 'none' }}
                    onChange={handleFileSelect}
                    accept={allowOnlyPicture ? 'image/*' : 'image/*, application/pdf, video/*'}
                    ref={fileInputRef}
                />
            </Div>
            {
                file &&
                fileType === POST_FILE_TYPES.imageType &&
                <HStack spacing={0} w='100%' h='52px'>
                    <Image
                        src={file}
                        w={'78px'}
                        h={'52px'}
                        alt={'preview image'}
                    />
                    <Circle
                        onClick={handleDeclineFileSelect}
                        position={'relative'}
                        bottom={'25px'}
                        right={'8px'}
                        size={'16px'}
                        bg={'white'}
                        color={COLOR_NAMES.redF27474}>
                        <ExternalIcon icon={TbLetterX} styling={{ w: '12px', h: '16px' }} />
                    </Circle>
                </HStack>
            }
            {
                file &&
                fileType === POST_FILE_TYPES.pdfType &&
                <HStack spacing={0} w='100%' h='162px'>
                    <Div mt={'10px'}>
                        <PdfViewer pdf={file} widthOfPdfAsNumber={110} />
                    </Div>
                    <Circle
                        onClick={handleDeclineFileSelect}
                        position={'relative'}
                        bottom={'83px'}
                        right={'8px'}
                        size='16px'
                        bg={'white'}
                        color={COLOR_NAMES.redF27474}>
                        <ExternalIcon icon={TbLetterX} styling={{ w: '12px', h: '16px' }} />
                    </Circle>
                </HStack>
            }
            {
                file &&
                fileType === POST_FILE_TYPES.videoType &&
                <HStack spacing={0} w={'100%'} h={'70px'}>
                    <Div w={'140px'} h={'120px'} mt={'10px'}>
                        <VideoViewer src={file} disableDownload={true} />
                    </Div>
                    <Circle
                        onClick={handleDeclineFileSelect}
                        position={'relative'}
                        bottom={'35px'}
                        right={'8px'}
                        size='16px'
                        bg={'white'}
                        color={COLOR_NAMES.redF27474}>
                        <ExternalIcon icon={TbLetterX} styling={{ w: '12px', h: '16px' }} />
                    </Circle>
                </HStack>
            }
        </VStack>
    );
}

export default UploadWithPreviewButton;
