import React from 'react';
import { Textarea } from '@chakra-ui/react';
import Input from './input.component';

function FormTextarea({
    name,
    type = 'text',
    label,
    placeholder,
    isRequired,
    isDisabled,
    isReadOnly,
    textareaStyling,
    errorStyling,
    labelStyling,
    formikProps
}) {
    return <Input
        element={Textarea}
        name={name}
        type={type}
        label={label}
        placeholder={placeholder}
        isRequired={isRequired}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        inputStyling={textareaStyling}
        errorStyling={errorStyling}
        labelStyling={labelStyling}
        formikProps={formikProps} />
}

export default FormTextarea;
