import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Flex, HStack, Spinner, VStack } from '@chakra-ui/react';
import Div from '../components/html-elements/div.component';
import Notification from '../components/notifications/notification.component';
import WrapperButton from '../components/buttons/wrapper-button.component';
import ExternalIcon from '../components/icons/external-icon.component';
import useInfiniteScroll from '../shared/hooks/infinitie-scroll.hook';
import notificationService from '../services/notification.service';
import groupService from '../services/group.service';
import userService from '../services/user.service';
import { COLOR_NAMES, FONTS, NOTIFICATION_ACTIONS, NOTIFICATION_TYPES, ROUTES, VARIANT_KEYS } from '../shared/constants';
import toastService from '../services/toast.service';
import { MdOutlineSettings } from 'react-icons/md';

function NotificationsPage() {
    const [notifications, setNotifications] = useState([]);
    const [page, setPage] = useState(1);

    const notificationGroups = {
        boNotifications: [
            NOTIFICATION_TYPES.DELETED_COMMENT_BO,
            NOTIFICATION_TYPES.DELETED_POST_BO,
            NOTIFICATION_TYPES.EDITED_COMMENT_BO,
            NOTIFICATION_TYPES.EDITED_POST_BO,
            NOTIFICATION_TYPES.MUTED_BO,
            NOTIFICATION_TYPES.INVITE_AS_BO_ADMIN,
            NOTIFICATION_TYPES.CONFIGURATION_CHANGE,
            NOTIFICATION_TYPES.JUSTIFIED_GROUP_REPORT,
            NOTIFICATION_TYPES.INVITE_AS_ONBOARDING_ADMIN,
        ],
        commentNotifications: [
            NOTIFICATION_TYPES.EDITED_COMMENT_BO,
            NOTIFICATION_TYPES.DELETED_COMMENT_BO,
            NOTIFICATION_TYPES.COMMENTED_ON_POST,
            NOTIFICATION_TYPES.DELETED_COMMENT_IN_GROUP,
            NOTIFICATION_TYPES.COMMENT_LIKE,
        ],
        profileNotifications: [
            NOTIFICATION_TYPES.DELETED_GROUP,
            NOTIFICATION_TYPES.GROUP_INVITE_DECLINED,
            NOTIFICATION_TYPES.REMOVED_FROM_GROUP,
            NOTIFICATION_TYPES.JOIN_GROUP,
            NOTIFICATION_TYPES.JOB_POSITION_CHANGE,
            NOTIFICATION_TYPES.PROFILE_REVIEW,
            NOTIFICATION_TYPES.ACCEPTED_IN_GROUP,
            NOTIFICATION_TYPES.REJECTED_FROM_GROUP,
            NOTIFICATION_TYPES.ACCEPTED_INVITE_USER_AS_ADMIN,
            NOTIFICATION_TYPES.REJECTED_INVITE_USER_AS_ADMIN,
            NOTIFICATION_TYPES.ACCEPTED_INVITE_AS_BO_ADMIN,
            NOTIFICATION_TYPES.REJECTED_INVITE_AS_BO_ADMIN,
            NOTIFICATION_TYPES.ACCEPTED_INVITE_AS_ONBOARDING_ADMIN,
            NOTIFICATION_TYPES.REJECTED_INVITE_AS_ONBOARDING_ADMIN,
        ],
        inviteNotifications: [
            NOTIFICATION_TYPES.INVITE_AS_BO_ADMIN,
            NOTIFICATION_TYPES.INVITE_USER_AS_ADMIN,
            NOTIFICATION_TYPES.INVITE_USER_TO_GROUP,
        ],
    };

    const notificationRedirectLinks = {
        [NOTIFICATION_TYPES.POST_LIKE]: `${ROUTES.COMMENT_POST}`,
        [NOTIFICATION_TYPES.DELETED_POST_BO]: `${ROUTES.COMMENT_POST}`,
        [NOTIFICATION_TYPES.EDITED_POST_BO]: `${ROUTES.COMMENT_POST}`,
        [NOTIFICATION_TYPES.DELETED_COMMENT_BO]: `${ROUTES.COMMENT_POST}`,
        [NOTIFICATION_TYPES.EDITED_COMMENT_BO]: `${ROUTES.COMMENT_POST}`,
        [NOTIFICATION_TYPES.DELETED_COMMENT_IN_GROUP]: `${ROUTES.COMMENT_POST}`,
        [NOTIFICATION_TYPES.DELETED_POST_IN_GROUP]: `${ROUTES.COMMENT_POST}`,
        [NOTIFICATION_TYPES.SHARED_POST]: `${ROUTES.COMMENT_POST}`,
        [NOTIFICATION_TYPES.COMMENTED_ON_POST]: `${ROUTES.COMMENT_POST}`,
        [NOTIFICATION_TYPES.PROFILE_REVIEW]: `${ROUTES.USER_PROFILE}`,
        [NOTIFICATION_TYPES.NEW_POST]: `${ROUTES.COMMENT_POST}`,
        [NOTIFICATION_TYPES.JOB_POSITION_CHANGE]: `${ROUTES.USER_PROFILE}`,
        [NOTIFICATION_TYPES.DELETED_GROUP]: `${ROUTES.USER_PROFILE}`,
        [NOTIFICATION_TYPES.GROUP_INVITE_DECLINED]: `${ROUTES.USER_PROFILE}`,
        [NOTIFICATION_TYPES.REMOVED_FROM_GROUP]: `${ROUTES.USER_PROFILE}`,
        [NOTIFICATION_TYPES.JOIN_GROUP]: `${ROUTES.USER_PROFILE}`,
        [NOTIFICATION_TYPES.ACCEPTED_IN_GROUP]: `${ROUTES.USER_PROFILE}`,
        [NOTIFICATION_TYPES.REJECTED_FROM_GROUP]: `${ROUTES.USER_PROFILE}`,
        [NOTIFICATION_TYPES.INVITE_USER_AS_ADMIN]: `${ROUTES.GROUP_DETAILS}`,
        [NOTIFICATION_TYPES.REJECTED_INVITE_USER_AS_ADMIN]: `${ROUTES.USER_PROFILE}`,
        [NOTIFICATION_TYPES.ACCEPTED_INVITE_USER_AS_ADMIN]: `${ROUTES.USER_PROFILE}`,
        [NOTIFICATION_TYPES.INVITE_USER_TO_GROUP]: `${ROUTES.GROUP_DETAILS}`,
        [NOTIFICATION_TYPES.ACCEPTED_INVITE_AS_BO_ADMIN]: `${ROUTES.USER_PROFILE}`,
        [NOTIFICATION_TYPES.REJECTED_INVITE_AS_BO_ADMIN]: `${ROUTES.USER_PROFILE}`,
        [NOTIFICATION_TYPES.JUSTIFIED_GROUP_REPORT]: `${ROUTES.GROUP_DETAILS}`,
        [NOTIFICATION_TYPES.INVITE_AS_BO_ADMIN]: `${ROUTES.BACK_OFFICE}`,
        [NOTIFICATION_TYPES.INVITE_AS_ONBOARDING_ADMIN]: `${ROUTES.ONBOARDING}`,
        [NOTIFICATION_TYPES.ACCEPTED_INVITE_AS_ONBOARDING_ADMIN]: `${ROUTES.USER_PROFILE}`,
        [NOTIFICATION_TYPES.REJECTED_INVITE_AS_ONBOARDING_ADMIN]: `${ROUTES.USER_PROFILE}`,
        [NOTIFICATION_TYPES.COMMENT_LIKE]: `${ROUTES.COMMENT_POST}`,
    };

    const notificationActionHandlers = {
        [NOTIFICATION_TYPES.INVITE_USER_TO_GROUP]: {
            accept: async (groupId, notificationId) => { return await groupService.acceptInvite(groupId, notificationId) },
            deny: async (groupId, notificationId) => { return await groupService.denyInvite(groupId, notificationId) },
        },
        [NOTIFICATION_TYPES.INVITE_USER_AS_ADMIN]: {
            accept: async (groupId, notificationId) => { return await groupService.acceptInviteAsAdmin(groupId, notificationId) },
            deny: async (groupId, notificationId) => { return await groupService.rejectInviteAsAdmin(groupId, notificationId) },
        },
        [NOTIFICATION_TYPES.APPLY_TO_GROUP]: {
            accept: async (groupId, notificationId) => { return await groupService.acceptUserToGroupAsync(groupId, notificationId) },
            deny: async (groupId, notificationId) => { return await groupService.rejectUserFromGroupAsync(groupId, notificationId) },
        },
        [NOTIFICATION_TYPES.INVITE_AS_BO_ADMIN]: {
            accept: async (userId, notificationId) => { return await userService.acceptInviteAsBOAdmin(userId, notificationId) },
            deny: async (userId, notificationId) => { return await userService.rejectInviteAsBOAdmin(userId, notificationId) },
        },
        [NOTIFICATION_TYPES.INVITE_AS_ONBOARDING_ADMIN]: {
            accept: async (userId, notificationId) => { return await userService.acceptInviteAsOnboardingAdmin(userId, notificationId) },
            deny: async (userId, notificationId) => { return await userService.rejectInviteAsOnboardingAdmin(userId, notificationId) },
        }
    };

    useEffect(() => {
        notificationService.seenNotifications();
    }, []);

    const loadMoreNotifications = async (options) => {
        try {
            const { data, isSuccess, errorMessage } = (await notificationService.getAllNotifications(page, 10, options)).data
            if (isSuccess) {
                setNotifications(prev => [...prev, ...data]);
                return data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    const { isLoading, isError, error, hasNextPage } = useInfiniteScroll(loadMoreNotifications, page);

    const observer = useRef();
    const loaderDiv = useCallback(node => {
        if (isLoading) {
            return;
        }

        if (isError) {
            return;
        }

        if (observer.current) {
            observer.current.disconnect();
        }

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasNextPage) {
                setPage(prev => prev + 1);
            }
        });

        if (node && hasNextPage) {
            observer.current.observe(node);
        }
    }, [error, hasNextPage, isError, isLoading]);

    const handleNotifications = (prev, data) => {
        const newData = [];
        for (const oldNotification of prev) {
            oldNotification.notification.id !== data.id
                ? newData.push({ ...oldNotification, notification: { ...oldNotification.notification } })
                : newData.push({ ...oldNotification, notification: { ...oldNotification.notification, ...data } });
        }
        return newData;
    }

    const onAccept = async (type, actionId, notificationId) => {
        try {
            const { data, isSuccess, errorMessage } =
                (await notificationActionHandlers[NOTIFICATION_TYPES[type]].accept(actionId, notificationId)).data;

            if (!isSuccess) {
                return;
            }

            setNotifications(prev => handleNotifications(prev, data));
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    const onDeny = async (type, actionId, notificationId) => {
        try {
            const { data, isSuccess, errorMessage } =
                (await notificationActionHandlers[NOTIFICATION_TYPES[type]].deny(actionId, notificationId)).data;

            if (!isSuccess) {
                return;
            }

            setNotifications(prev => handleNotifications(prev, data));
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    const onDelete = async (id) => {
        try {
            const { data, isSuccess, errorMessage } = (await notificationService.deleteNotification(id)).data;
            if (isSuccess) {
                setNotifications(prev => {
                    const newData = [];
                    for (const notification of prev) {
                        if (notification.notification.id !== id) {
                            newData.push({ ...notification });
                        }
                    }

                    return newData;
                });
            };
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    const onMuteUserNotifications = async (userId) => {
        try {
            const { data, isSuccess, errorMessage } = (await notificationService.muteNotificationsFromUser(userId)).data;
            if (isSuccess) {
                setNotifications(prev => {
                    const newData = [];
                    for (const notification of prev) {
                        if (notification.user.id !== userId) {
                            newData.push({ ...notification });
                        } else {
                            newData.push({ ...notification, user: { ...notification.user, isUserNotificationMuted: true } })
                        }
                    }

                    return newData;
                });
            }
            toastService.success(data);
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    };

    const onNotificationClick = async (id) => {
        try {
            const { data, isSuccess, errorMessage } = (await notificationService.clickedNotification([id])).data;
            if (isSuccess) {
                setNotifications(prev => {
                    const newData = [];
                    for (const notification of prev) {
                        if (notification.notification.id !== id) {
                            newData.push({ ...notification });
                        } else {
                            newData.push({ ...notification, notification: { ...notification.notification, isClicked: true } })
                        }
                    }

                    return newData;
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onMarkAllAsClicked = async () => {
        try {
            const { data, isSuccess, errorMessage } = (await notificationService.clickedNotification([])).data;
            if (isSuccess) {
                setNotifications(prev => {
                    const newData = [];
                    for (const notification of prev) {
                        newData.push({ ...notification, notification: { ...notification.notification, isClicked: true } })
                    }
                    return newData;
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const buildNotificationLink = (notification, sentByUserId) => {
        if (notificationRedirectLinks.hasOwnProperty(NOTIFICATION_TYPES[notification.type])) {
            if ((notification.type === NOTIFICATION_TYPES.INVITE_AS_BO_ADMIN || notification.type === NOTIFICATION_TYPES.INVITE_AS_ONBOARDING_ADMIN) && notification.actionId !== NOTIFICATION_ACTIONS.ACCEPTED) {
                return null;
            }
            return `${notificationRedirectLinks[NOTIFICATION_TYPES[notification.type]]}/${(() => {
                if (notification.type === NOTIFICATION_TYPES.INVITE_AS_BO_ADMIN || notification.type === NOTIFICATION_TYPES.INVITE_AS_ONBOARDING_ADMIN) {
                    return '';
                }
                if (notificationGroups.profileNotifications.includes(notification.type)) {
                    return sentByUserId;
                }
                if (notification.postUuid) {
                    return notification.postUuid;
                }
                return notification.actionId;
            })()}`;
        }
        return null;
    }

    return (
        <Fragment>
            <Link to={`${ROUTES.NOTIFICATIONS_SETTINGS}`}>
                <HStack opacity='0.7' pl='20px' pr='20px' mb='20px' justifyContent={'start'}>
                    <Flex w='fit-content' justifyContent={'center'} alignItems={'center'}>
                        <Flex alignItems='center' h='100%' p='0px' mr='0px'>
                            <ExternalIcon styling={{ w: '18px', h: '18px' }} icon={MdOutlineSettings} />
                        </Flex>
                        <VStack spacing={0}>
                            <Div textAlign='start' w='100%' fontSize='18px' >Settings</Div>
                        </VStack>
                    </Flex>
                </HStack>
            </Link>
            <VStack w='100%' textAlign='left' fontSize='13px'>
                <HStack w='100%'>
                    <Div pl='20px' color={COLOR_NAMES.grayB4B4B4} marginRight={'auto'} fontSize={'17px'}>Recent</Div>
                    <WrapperButton onClickHandle={onMarkAllAsClicked} styling={{ variant: VARIANT_KEYS.bgAndColor.bg_transparent_color_gray757575_orangeFFB81C, fontWeight: FONTS.underlined[300] }} message={'Mark all as read'} />
                </HStack>
                {notifications?.map(({ notification, user }, i) => {
                    return (
                        <VStack key={`${notification.id}-notificaion`} w={'100%'}>
                            <Divider />
                            <Notification onAccept={onAccept} onDeny={onDeny} onDelete={onDelete}
                                onMuteNotificationsByUser={onMuteUserNotifications} onNotificationClick={onNotificationClick}
                                sentByUser={user} notification={notification}
                                isCommentNotification={notificationGroups.commentNotifications.includes(notification.type)}
                                isBoNotification={notificationGroups.boNotifications.includes(notification.type)}
                                isInviteNotification={notificationGroups.inviteNotifications.includes(notification.type)}
                                notificationRedirectLink={buildNotificationLink(notification, user.id)}
                                renderActionButtons={notification.type in notificationActionHandlers
                                    && notification.actionTaken === NOTIFICATION_ACTIONS.NO_ACTION} />
                            {(i === 2) && <Div w='100%' pl='20px' color={COLOR_NAMES.grayB4B4B4} pt='40px' fontSize={'17px'}>
                                Older
                            </Div>}
                        </VStack>)
                }
                )}
                {!hasNextPage && notifications && <Div>No more notifications!</Div>}
                <div ref={loaderDiv} />
                {isLoading && <Spinner />}
            </VStack>
        </Fragment>
    )
}

export default NotificationsPage;