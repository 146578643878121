import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Div from '../components/html-elements/div.component';
import PostsFeed from '../components/posts/posts-feed';
import postService from '../services/post.service';
import { ROUTES } from '../shared/constants';

function UserPostsPage() {
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const id = params.id;
        if (!id) {
            navigate(ROUTES.HOME);

            return;
        }
    }, []);

    return (
        <Div minH={'734px'}>
            <PostsFeed getPostsFn={postService.getUserPosts} moreParamsForGetPostsFn={[params.id]} />
        </Div>
    )
}

export default UserPostsPage;
