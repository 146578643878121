import React, { Fragment } from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import Modal from './modal.component';
import WrapperButton from '../buttons/wrapper-button.component';
import { COLOR_NAMES, MEDIA, VARIANT_KEYS, FONTS } from '../../shared/constants';
import LikesUsersListModal from './likes-users-list-modal.component';

function LikesCoverModal({ componentId, isOpen, isClose, getUsersWhoLiked }) {
    const [isLargerThan550] = useMediaQuery(MEDIA.MIN_WIDTH_550_PX);

    return (
        <Fragment>
            <Modal
                size={!isLargerThan550 ? 'full' : {}}
                scrollBehavior='inside'
                w={isLargerThan550 ? '420px' : '100vw'}
                h={isLargerThan550 ? '800px' : {}}
                isOpen={isOpen}
                isCentered={true}
                bgBlureColor={'rgba(43, 46, 42, 0.9)'}
                bg={COLOR_NAMES.black2B2E2A}
                footerContent={
                    <WrapperButton
                        styling={{
                            variant: VARIANT_KEYS.bgAndborder.cancel,
                            fontSize: '16px',
                            h: '40px',
                            fontWeight: FONTS.normal[500],
                            w: '100%',
                            mt: '20px',
                            mb: '24px',
                        }}
                        onClickHandle={isClose}
                        message={'Close'} />
                }>
                <LikesUsersListModal componentId={componentId} getUsersWhoLiked={getUsersWhoLiked} />
            </Modal>
        </Fragment>
    )
}

export default LikesCoverModal;
