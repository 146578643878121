import React, { memo } from 'react';

function VideoViewer({ src, disableDownload}) {
    return (
        <video key={src} disablePictureInPicture controlsList={disableDownload ? 'nodownload': null} controls>
            <source src={src} />
            Your browser does not support the video tag.
        </video>
    )
}

export default memo(VideoViewer);
