import { HStack, VStack, Tooltip, Avatar, AvatarBadge } from '@chakra-ui/react';
import Div from '../html-elements/div.component';
import { COLOR_NAMES, STATIC_URL } from '../../shared/constants';
import imagePeople from '../../static/images/people.svg';

const PostUserActionsHeader = ({ user, isGroupPost, group }) => {
    let avatars;
    if (isGroupPost) {
        const groupImage = (group.img_url) ? `${STATIC_URL}/${group.img_url}` : imagePeople
        avatars = <Avatar name={group.name} bg={'none'} borderRadius={'5px'} border={`1px solid ${COLOR_NAMES.grayAFAFAF}`}
            src={groupImage} size={'sm'} >
            <Tooltip label={user.name} placement='right' shouldWrapChildren={true}>
                <AvatarBadge border={'none'}>
                    <Avatar name={user.name} src={user.img_url ? `${STATIC_URL}/${user.img_url}` : ''}
                        bg={user.img_url ? 'none' : user.avatar_color} size={'xs'} />
                </AvatarBadge>
            </Tooltip>
        </Avatar>
    }
    else {
        avatars = <Avatar name={user.name} src={user.img_url ? `${STATIC_URL}/${user.img_url}` : ''}
            bg={user.img_url ? 'none' : user.avatar_color} size={'sm'} />
    }


    return (<HStack>
        {avatars}
        <VStack alignItems={'start'}>
            {(isGroupPost) && <Div sx={{ fontSize: '13px', fontWeight: 'bold', marginBottom: '-10px' }}>{group.name}</Div>}
            <Div sx={{ fontSize: (!isGroupPost) ? '13px' : '10px' }}>{user.name}</Div>
        </VStack>
    </HStack>)
}

export default PostUserActionsHeader;