import moment from 'moment';
import Span from '../../components/html-elements/span.component';
import { COLOR_NAMES, ROUTES } from '../constants';
import { Link } from 'react-router-dom';

export const isInvalidImageUrl = (imgUrl) => {
  return (
    !imgUrl ||
    imgUrl.endsWith('null') ||
    imgUrl.endsWith('upload/') ||
    imgUrl.endsWith('/upload') ||
    imgUrl.endsWith('undefined')
  );
};

export function idGenerator() {
  let id = 0;
  return () => ++id;
}

export function capitalizeFirstLetterAndLowercaseRest(text) {
  return `${text[0].toUpperCase()}${text.slice(1).toLowerCase()}`;
}

export function getFormDataWithFile(formFields, selectedFile, fieldName = 'file') {
  const formData = new FormData();
  if (selectedFile != null) {
    formData.append(fieldName, selectedFile);
  }

  Object.keys(formFields).forEach((key) => {
    formData.append(key, formFields[key]);
  });

  return formData;
}

export function buildTitleBaseOnPathname(pathname, routes) {
  const toLowerPathname = pathname.toLowerCase();

  for (const key in routes) {
    const route = routes[key].toLowerCase();
    if (route !== routes.DEFAULT && toLowerPathname.startsWith(route)) {
      const keys = key.split('_');
      const result = [];
      for (const currentKey of keys) {
        result.push(capitalizeFirstLetterAndLowercaseRest(currentKey));
      }

      return result.join(' ');
    }
  }
}

export function parseMarkdown(text, boldWrapper = '**') {
  if (!text) {
    return [''];
  }

  const textArray = text.split(boldWrapper);
  let parsed = [];

  while (textArray.length) {
    parsed.push(<Span key={textArray.length}>{textArray.shift()}</Span>);
    if (textArray.length) {
      parsed.push(<Span key={textArray.length} fontWeight={'bold'}>{textArray.shift()}</Span>);
    }
  }

  return parsed;
}

export function getTimeElapsedObject(dateString) {
  const currentDate = new Date();
  const currentMomentDate = moment(currentDate);
  const commentDate = moment(new Date(dateString));
  const diff = currentMomentDate.diff(commentDate);
  const diffDuration = moment.duration(diff);

  const minutes = Math.floor(diffDuration.asMinutes());
  const hours = Math.floor(diffDuration.asHours());
  const days = Math.floor(diffDuration.asDays());
  const months = Math.floor(diffDuration.asMonths());
  const years = Math.floor(diffDuration.asYears());
  const weeks = Math.floor(diffDuration.asWeeks());

  if (years) {
    return { period: years, periodAbbreviation: 'y' };
  } else if (months || weeks === 4) {
    return { period: months || 1, periodAbbreviation: 'm' };
  } else if (weeks) {
    return { period: weeks, periodAbbreviation: 'w' };
  } else if (days) {
    return { period: days, periodAbbreviation: 'd' };
  } else if (hours) {
    return { period: hours, periodAbbreviation: 'h' };
  } else {
    return { period: minutes || 1, periodAbbreviation: 'min' };
  }
}

export function addTagToNestedReply(text, parentComment, parrentCommentAuthorId) {
  const isNestedReply = text.match(/\*\/\w+ \w+\/\*/g);
  if (!isNestedReply) {
    return text;
  }

  const personNames = betweenMarkers(text, '*/', '/*');
  const textWithoutName = text.replace(`*/${personNames}/*`, '');

  const result = [];
  result.push(<Span
    key={`${personNames.length}${text}`}
    fontWeight={'bold'}
    color={COLOR_NAMES.blue2097F6}>
    {parentComment ? <Link to={`${ROUTES.USER_PROFILE}/${parrentCommentAuthorId}`}>{personNames}</Link> :  personNames }
  </Span>)

  result.push(<Span key={textWithoutName.length}>{textWithoutName}</Span>)

  return result;
}

function betweenMarkers(text, begin, end) {
  var firstChar = text.indexOf(begin) + begin.length;
  var lastChar = text.indexOf(end);
  var targetString = text.substring(firstChar, lastChar);
  
  return targetString;
}
