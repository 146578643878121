import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Center, usePrefersReducedMotion, keyframes } from '@chakra-ui/react';
import WrapperButton from '../components/buttons/wrapper-button.component';
import authService from '../services/auth.service';
import { ROUTES, FONTS, VARIANT_KEYS } from '../shared/constants';

function HomeInvitePage() {
    const navigate = useNavigate();

    const trasnsformY = keyframes`
        0% {
          opacity: 0;
          transform: translateY(-200px);
        }

        100% {
          transform: translateY(0px);
          opacity: 100%;
        }`;

    const prefersReducedMotion = usePrefersReducedMotion();

    const animation = prefersReducedMotion
        ? undefined
        : `${trasnsformY} 2s ease-in`

    const styling = {
        fontWeight: FONTS.normal[700],
        animation,
        variant: VARIANT_KEYS.bgAndColor.bg_oragneFDB71A_oragneFFB81C_color_black141414_black141414,
        border: '6px',
        pt: '12px',
        pb: '12px',
        pr: '24px',
        pl: '24px'
    };

    const clientId = '4eiq3cuiesu39qtd5jojgqf3k8';
    const urlOrigin = window.location.origin;
    const loginUrl = `https://knowme-auth.auth.eu-central-1.amazoncognito.com/login?response_type=code&client_id=${clientId}&scope=&redirect_uri=${urlOrigin}/oauth2/code`;

    const redirect = () => {
        const refreshToken = authService.getRefreshToken();
        const token = authService.getToken();
        if (refreshToken && token) {
            navigate(ROUTES.HOME);

            return;
        }

        window.location.assign(loginUrl)
    }

    return (
        <Fragment>
            <Flex width={'100vw'} height={'100vh'} alignContent={'center'} justifyContent={'center'}>
                <Center>
                    <WrapperButton
                        onClickHandle={redirect}
                        message={'KnowMe'}
                        styling={styling} />
                </Center>
            </Flex>
        </Fragment>
    )
}

export default HomeInvitePage;
