import { useState, useEffect } from 'react'

const useInfiniteScroll = (loadMore, pageNum = 1, hardReload) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState({});
    const [hasNextPage, setHasNextPage] = useState(false);

    const handleData = async (signal) => {
        try {
            const data = await loadMore({ signal });
            if (!data) {
                return;
            }

            setHasNextPage(Boolean(data.length));
            setIsLoading(false);
        } catch (error) {
            if (error.message === 'canceled') {
                // TODO: check how to handle errors here and in components
                // setIsError(true);
                return;
            }

            setIsLoading(false);

            if (signal.aborted) {
                return
            }

            setIsError(true);
            setError({ message: error.message });
        }
    }

    useEffect(() => {
        setIsLoading(true);
        setIsError(false);
        setError({});

        const controller = new AbortController();
        const { signal } = controller;

        setTimeout(() => {
            handleData(signal);
        }, 500);

        return () => controller.abort()

    }, [pageNum, hardReload]);


    return { isLoading, isError, error, hasNextPage };
}

export default useInfiniteScroll
