
import statisticsService from '../../services/statistics.service';
import { TIMERS } from '../../shared/constants';
import { useIdleTimer } from 'react-idle-timer';

const IdleTimerComponent = () => {
    const handleOnIdle = event => {
        statisticsService.setUserIdle();
    }

    const handleOnAction = event => {
        statisticsService.setUserActive();
    }

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: TIMERS.TEN_MINUTES_IN_MILLISECONDS,
        onIdle: handleOnIdle,
        onAction: handleOnAction,
        throttle: TIMERS.FIVE_MINUTES_IN_MILLISECONDS,
        events: [
            'mousemove',
            'keydown',
            'touchmove',
        ],
    })

    // With an option for future improvements
    return null;
}

export default IdleTimerComponent;