import React, { useState, useRef, Fragment } from 'react';
import { VStack, Flex, HStack, Textarea, useMediaQuery, useDisclosure, Avatar } from '@chakra-ui/react';
import Div from '../html-elements/div.component';
import Span from '../html-elements/span.component';
import ControlMenuDekstop from '../shared/control-menu-dekstop.component';
import ControlMenuMobile from '../shared/control-menu-mobile.component';
import ControlMenuModal from '../shared/control-menu-modal.component';
import Dialog from '../shared/dialog.component';
import ReportReason from '../shared/report-reason.component';
import WrapperButton from '../buttons/wrapper-button.component';
import ExternalIcon from '../icons/external-icon.component';
import ActionBarComment from './action-bar-comment.component';
import useAuth from '../../shared/hooks/auth.hook';
import useConfigurations from '../../shared/hooks/configurations.hook';
import toastService from '../../services/toast.service';
import { addTagToNestedReply, getTimeElapsedObject } from '../../shared/utils/common.utils';
import { VARIANT_KEYS, FONTS, MEDIA, FONT_FAMILY_NAME, COLOR_NAMES, GROUP_ROLES, STATIC_URL, CONFIGURATION_KEYS } from '../../shared/constants';
import { MdFlag } from 'react-icons/md';
import { RiEditCircleLine, RiDeleteBin5Fill } from 'react-icons/ri';
import { BiDotsHorizontalRounded } from 'react-icons/bi';

function Comment({
    data,
    onClickReport,
    handleDeleteComment,
    handleCommentChange,
    handleMode,
    userGroupRole,
    userGroupMuteStatus,
    parentCommentAuthorName,
    parrentCommentAuthorId }) {
    const timeElaplsedObject = getTimeElapsedObject(data.createdAt);
    const { user } = useAuth();
    const { configurations } = useConfigurations();
    const ref = useRef(null);

    const [commentChange, setCommentChange] = useState(data.message);
    const [isLargerThan550] = useMediaQuery(MEDIA.MIN_WIDTH_550_PX);
    const { isOpen: isDrrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
    const { isOpen: isReasonOpen, onOpen: onReasonOpen, onClose: onReasonClose } = useDisclosure();
    const { isOpen: isDeleteopen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();

    const onClickReportHandle = (reason) => {
        onClickReport(reason, data.id);
    }

    const deleteComment = () => {
        handleDeleteComment(data.id, data.author);
    }

    const editComment = () => {
        setTimeout(() => { ref?.current.focus() }, 200);
        handleMode(true, data.id);
    }

    const onCloseResonHandleMobile = () => {
        onReasonClose();
        onDrawerClose();
    }

    const onDeleteCloseHandleMobile = () => {
        onDeleteClose();
        onDrawerClose();
    }

    const onClickReportHandleMobile = (reason) => {
        onClickReport(reason, data.id);
        onDrawerClose();
    }

    const onClickCommentHandleMobile = () => {
        editComment();
        onDrawerClose();
    }

    const mainControlContent = <Flex pl='20px' justifyContent={'space-around'} direction={'column'} h='100%'>
        {user.id === data.author &&
            <HStack onClick={isLargerThan550 ? editComment : onClickCommentHandleMobile}>
                <Flex alignItems={'center'} h='100%'>
                    <ExternalIcon styling={{ w: '24px', h: '24px' }} icon={RiEditCircleLine} />
                </Flex>
                <VStack spacing={0}>
                    <Div textAlign='start' w='100%' fontSize='14px'>Edit</Div>
                </VStack>
            </HStack>
        }
        <HStack onClick={onDeleteOpen} h='36px'>
            <Flex alignItems={'center'} h='100%'>
                <ExternalIcon styling={{ w: '24px', h: '24px' }} icon={RiDeleteBin5Fill} />
            </Flex>
            <Div fontSize='14px'>Delete comment</Div>
            <Dialog
                isOpen={isDeleteopen}
                onClose={isLargerThan550 ? onDeleteClose : onDeleteCloseHandleMobile}
                onAction={deleteComment}
                messageBody={`Are you shure you want to delete selected comment`}
                messageHeader={`Delete comment`} />
        </HStack>
    </Flex>

    const reasonControlContent = <Flex pl='20px' justifyContent={'space-around'} direction={'column'} h='100%'>
        <HStack onClick={onReasonOpen}>
            <Flex alignItems={'center'} h='100%'>
                <ExternalIcon styling={{ w: '24px', h: '24px', opacity: data.reportedByMeOn ? '50%' : '100%' }} icon={MdFlag} />
            </Flex>
            {!data.reportedByMeOn
                ? <Fragment>
                    <VStack spacing={0}>
                        <Div textAlign='start' w='100%' fontSize='14px'>Report comment</Div>
                        <Div textAlign='start' fontSize='10px'>This comment is harmful, take or scan</Div>
                    </VStack>
                    <ControlMenuModal isLargerThan550={isLargerThan550} isOpen={isReasonOpen} onClose={onCloseResonHandleMobile}>
                        <ReportReason handleOnClick={isLargerThan550 ? onClickReportHandle : onClickReportHandleMobile} />
                    </ControlMenuModal>
                </Fragment>
                : <Div textAlign='start' w='100%' fontSize='14px' opacity='50%'>{`Reported on: ${new Date(data.reportedByMeOn).toLocaleString()}`}</Div>}

        </HStack>
    </Flex>

    const desktopControl = user.id === data.author || userGroupRole === GROUP_ROLES.ADMIN || userGroupRole === GROUP_ROLES.OWNER
        ? <ControlMenuDekstop isLargerThan550={isLargerThan550} h={'140px'}>
            {mainControlContent}
        </ControlMenuDekstop>
        : <ControlMenuDekstop isLargerThan550={isLargerThan550} h={'140px'}>
            {reasonControlContent}
        </ControlMenuDekstop>

    const mobileControl = user.id === data.author || userGroupRole === GROUP_ROLES.ADMIN || userGroupRole === GROUP_ROLES.OWNER
        ? <ControlMenuMobile
            drawwerButton={<ExternalIcon onClick={onDrawerOpen} styling={{ fontSize: '26px' }} icon={BiDotsHorizontalRounded} />}
            isDrrawerOpen={isDrrawerOpen}
            onDrawerClose={onDrawerClose}
            h={'140px'}>
            {mainControlContent}
        </ControlMenuMobile>
        : <ControlMenuMobile
            drawwerButton={<ExternalIcon onClick={onDrawerOpen} styling={{ fontSize: '26px' }} icon={BiDotsHorizontalRounded} />}
            isDrrawerOpen={isDrrawerOpen}
            onDrawerClose={onDrawerClose}
            h={'140px'}>
            {reasonControlContent}
        </ControlMenuMobile>

    return (
        <Fragment >
            <Flex borderRadius={'5px'} justifyContent={'start'} w='100%' pl={data.parentCommentId ? '40px' : null} >
                <Avatar name={data.user.name} bg={data.user.img_url ? 'none' : data.user.avatar_color}
                    src={data.user.img_url ? `${STATIC_URL}/${data.user.img_url}` : ''} size={'md'} />
                <Div p='10px' ml='10px' variant={VARIANT_KEYS.bg.bg_whiteFFFFFF_black212320} w='100%'>
                    <VStack>
                        <Flex w={'100%'}>
                            <VStack spacing={0} w='100%'>
                                <HStack w='100%' justifyContent='space-between'>
                                    <Div h='16px' w='100%' textAlign='start' fontSize={'14px'} letterSpacing='1.3px'>{data.user.name}</Div>
                                    {!data.isDeleted &&
                                        <Flex w={'100%'} h={!isLargerThan550 ? '16px' : '6px'} justifyContent={'end'}>
                                            {isLargerThan550
                                                ? desktopControl
                                                : mobileControl
                                            }
                                        </Flex>}
                                </HStack>
                                <HStack w='100%'>
                                    <Div w='100%' textAlign='start'>
                                        <Span fontSize={'12px'} letterSpacing='1.3px' variant={VARIANT_KEYS.color.color_gray757575_grayAFAFAF}>
                                            {data.user.job}
                                        </Span>
                                    </Div>
                                    <Div mt='0px'>
                                        <Span fontSize={'12px'} letterSpacing='1.3px' variant={VARIANT_KEYS.color.color_gray757575_grayAFAFAF}>
                                            {timeElaplsedObject.period}
                                            {timeElaplsedObject.periodAbbreviation}
                                        </Span>
                                    </Div>
                                </HStack>
                            </VStack>
                        </Flex>
                        <Div hidden={data.isUpdateMode} w='100%' textAlign='start' letterSpacing='1.3px' fontSize={'14px'} wordBreak='break-word'>
                            {!data.isDeleted
                                ? addTagToNestedReply(data.message, parentCommentAuthorName, parrentCommentAuthorId)
                                : <Div color={COLOR_NAMES.redDE4946}>
                                    This comment was deleted by the Admin.
                                    {data.author === user.id && <Span color={COLOR_NAMES.grayC4C4C4}>You wrote: {data.message}</Span>}
                                </Div>}
                        </Div>
                        <Flex hidden={!data.isUpdateMode} w='100%' direction={'column'}>
                            <Textarea
                                ref={ref}
                                h={'84px'}
                                value={commentChange}
                                onChange={(e) => { setCommentChange(!commentChange ? data.message : e.target.value); }}
                                fontFamily={FONT_FAMILY_NAME}
                                fontSize={'14px'}
                                variant={VARIANT_KEYS.TEXTAREA.PRIMARY}
                                onFocus={() => { if (!configurations[CONFIGURATION_KEYS.commentsEditing].isActive) { toastService.warning(configurations[CONFIGURATION_KEYS.commentsEditing].messageUnavailable) } }}
                                placeholder='Change you comment' />
                            <HStack spacing={2} mt='12px'>
                                <Div>
                                    <WrapperButton
                                        message={'Save changes'}
                                        styling={{
                                            variant: VARIANT_KEYS.bgAndColor.bg_oragneFDB71A_oragneFFB81C_color_black141414_black141414,
                                            fontSize: '16px',
                                            h: '30px',
                                            fontWeight: FONTS.normal[700],
                                            w: '100%',
                                            isDisabled: user.isMuted || !!userGroupMuteStatus || !configurations[CONFIGURATION_KEYS.commentsEditing].isActive,
                                            _hover: {},
                                        }}
                                        onClickHandle={() => handleCommentChange({ message: commentChange, id: data.id })} />
                                </Div>
                                <Div>
                                    <WrapperButton
                                        message={'Cancel'}
                                        styling={{
                                            variant: VARIANT_KEYS.bgAndborder.bg_transparent_color_black141414_whiteFFFFFF_border_1px_FFB81C_whiteFFFFFF,
                                            fontSize: '16px',
                                            h: '30px',
                                            fontWeight: FONTS.normal[700],
                                            w: '100%',
                                            _hover: {},
                                        }}
                                        onClickHandle={() => {
                                            setCommentChange(data.message);
                                            handleMode(false, data.id);
                                        }} />
                                </Div>
                            </HStack>
                        </Flex>
                    </VStack>
                </Div>
            </Flex>
            <ActionBarComment
                data={data}
                onClickReport={onClickReport}
                handleDeleteComment={handleDeleteComment}
                userGroupRole={userGroupRole}
                userGroupMuteStatus={userGroupMuteStatus} />
        </Fragment>
    )
}

export default Comment;
