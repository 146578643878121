import React from 'react';
import { Routes, Route } from 'react-router-dom';

import HomePage from '../../pages/home-page.component';
import AuthenticationPage from '../../pages/authentication-page.component';
import HomeInvitePage from '../../pages/home-invite-page.component';
import ContactsPage from '../../pages/contacts-page.component';
import UserProfilePage from '../../pages/user-profile-page.component';
import MyProfilePage from '../../pages/my-profile-page.component';
import UserProfileEditPage from '../../pages/edit-profile-page.component';
import GroupsPage from '../../pages/groups-page.component';
import InviteMembersPage from '../../pages/invite-members-page.component';
import MembersPage from '../../pages/members.page.component';
import GroupCreatePage from '../../pages/group-create-page.component';
import GroupEditPage from '../../pages/group-edit-page.component';
import GroupDetailsPage from '../../pages/group-details-page.component';
import LikedPage from '../../pages/liked-page.component';
import NotificationsPage from '../../pages/notifications-page.component';
import CommentPostPage from '../../pages/comment-post-page.component';
import CreatePost from '../../pages/create-post-page.component';
import EditPostPage from '../../pages/edit-post-page.component';
import UserPostsPage from '../../pages/user-posts-page.component';
import SignupPage from '../../pages/sign-up-page.component';
import ProtectRouter from './protect-router.component';
import Onboarding from '../../pages/onboarding-page.component';
import { ROUTES } from '../../shared/constants';
import BackOffice from '../../pages/back-office-page.component';
import NotificationsSettingsPage from '../../pages/notifications-setting-page.component';

function Routing({ searchText, handleSetSearchText }) {
  return (
    <Routes>
      <Route path={ROUTES.AUTHENTICATION} element={<AuthenticationPage />}></Route>
      <Route path={ROUTES.DEFAULT} element={<HomeInvitePage />}></Route>
      <Route path={ROUTES.SIGNUP} element={<ProtectRouter><SignupPage /></ProtectRouter>}></Route>
      <Route path={ROUTES.HOME} element={<ProtectRouter><HomePage searchText={searchText} handleSetSearchText={handleSetSearchText} /></ProtectRouter>}></Route>
      <Route path={ROUTES.CREATE_POST} element={<ProtectRouter><CreatePost /></ProtectRouter>}></Route>
      <Route path={`${ROUTES.EDIT_POST}/:uuid`} element={<ProtectRouter><EditPostPage /></ProtectRouter>}></Route>
      <Route path={`${ROUTES.COMMENT_POST}/:uuid`} element={<ProtectRouter><CommentPostPage /></ProtectRouter>}></Route>
      <Route path={`${ROUTES.USER_POSTS}/:id`} element={<ProtectRouter><UserPostsPage /></ProtectRouter>}></Route>
      <Route path={ROUTES.CONTACTS} element={<ProtectRouter><ContactsPage /></ProtectRouter>}></Route>
      <Route path={ROUTES.LIKED} element={<ProtectRouter><LikedPage /></ProtectRouter>}></Route>
      <Route path={ROUTES.MY_PROFILE} element={<ProtectRouter><MyProfilePage /></ProtectRouter>}></Route>
      <Route path={ROUTES.EDIT_PROFILE} element={<ProtectRouter><UserProfileEditPage /></ProtectRouter>}></Route>
      <Route path={ROUTES.NOTIFICATIONS} element={<ProtectRouter><NotificationsPage /></ProtectRouter>}></Route>
      <Route path={`${ROUTES.NOTIFICATIONS}/${ROUTES.NOTIFICATIONS_SETTINGS}`} element={<ProtectRouter><NotificationsSettingsPage /></ProtectRouter>}></Route>
      <Route path={ROUTES.ONBOARDING} element={<ProtectRouter><Onboarding /></ProtectRouter>}></Route>
      <Route path={`${ROUTES.USER_PROFILE}/:id`} element={<ProtectRouter><UserProfilePage /></ProtectRouter>}></Route>
      <Route path={ROUTES.CREATE_GROUP} element={<ProtectRouter><GroupCreatePage /></ProtectRouter>}></Route>
      <Route path={`${ROUTES.EDIT_GROUP}/:id`} element={<ProtectRouter><GroupEditPage /></ProtectRouter>}></Route>
      <Route path={`${ROUTES.GROUP_DETAILS}/:id`} element={<ProtectRouter><GroupDetailsPage /></ProtectRouter>}></Route>
      <Route path={`${ROUTES.INVITE_MEMBERS}/:groupId`} element={<ProtectRouter><InviteMembersPage /></ProtectRouter>}></Route>
      <Route path={`${ROUTES.MEMBERS}/:groupId`} element={<ProtectRouter><MembersPage /></ProtectRouter>}></Route>
      <Route path={ROUTES.GROUPS} element={<ProtectRouter><GroupsPage /></ProtectRouter>}></Route>
      <Route path={`${ROUTES.BACK_OFFICE}`} element={<ProtectRouter><BackOffice /></ProtectRouter>}></Route>
    </Routes>
  );
}

export default Routing;
