import axios from './axios.service';

const DEFAULT = '/employees';
const ALL_WITHOUT_CURRENT_ONE = `${DEFAULT}/all-without-current-one`;
const ALL_FOLLOWED_USER_IDS = `${DEFAULT}/all-followed-ids`;
const IS_FOLLOW_BY_ME = `${DEFAULT}/is-follow-by-me`;
const MEMBERS_IN_GROUP = `${DEFAULT}/members-in-group`;
const TO_BE_INVITED = `${DEFAULT}/to-be-invited`;
const ACCEPT_INVITE_AS_BO_ADMIN = `${DEFAULT}/accept-invite-as-bo-admin`;
const REJECT_INVITE_AS_BO_ADMIN = `${DEFAULT}/reject-invite-as-bo-admin`;
const ACCEPT_INVITE_AS_ONBOARDING_ADMIN = `${DEFAULT}/accept-invite-as-onboarding-admin`;
const REJECT_INVITE_AS_ONBOARDING_ADMIN = `${DEFAULT}/reject-invite-as-onboarding-admin`;

class UserService {
    async getAllFollowedUserIds() {
        return axios.get(ALL_FOLLOWED_USER_IDS);
    }

    async isFollowByMe(followedUserId) {
        return axios.get(`${IS_FOLLOW_BY_ME}/${followedUserId}`);
    }

    async findEmpoyeesAsync(search, topic, offset = 0, limit = 5) {
        return axios.get(
            DEFAULT,
            {
                params: {
                    search: encodeURIComponent(search),
                    topic: topic,
                    offset: offset,
                    limit: limit,
                },
            });
    }

    // Maybe has to be in group service
    async getMembersInGroupAsync(groupId, search, page, limit, options) {
        return axios.get(
            `${MEMBERS_IN_GROUP}/${groupId}`,
            {
                ...options,
                params: {
                    search: encodeURIComponent(search),
                    page: page,
                    limit: limit,
                },
            });
    }

    async findUsersToBeInvitedAsync(groupId, search, page, limit) {
        return axios.get(
            `${TO_BE_INVITED}/${groupId}`,
            {
                params: {
                    search: encodeURIComponent(search),
                    page: page,
                    limit: limit,
                },
            });
    }

    async findEmpoyeesWithoutCurrenOneAsync(search, topic, offset = 0, limit = 5) {
        return axios.get(
            ALL_WITHOUT_CURRENT_ONE,
            {
                params: {
                    search: encodeURIComponent(search),
                    topic: topic,
                    offset: offset,
                    limit: limit,
                },
            });
    }

    // change on BE
    async updateByIdAsync(id, payload) {
        return axios.put(`${DEFAULT}/${id}`, payload, { params: { id: id } });
    }

    async getByIdAsync(id) {
        return axios.get(`${DEFAULT}/${id}`);
    }

    async follow(userId) {
        return axios.post(`${DEFAULT}/${userId}/follow`, {});
    }

    async unfollow(userId) {
        return axios.post(`${DEFAULT}/${userId}/unfollow`, {});
    }

    async acceptInviteAsBOAdmin(userId, notificationId) {
        return axios.post(ACCEPT_INVITE_AS_BO_ADMIN, { userId: userId, notificationId: notificationId });
    }

    async rejectInviteAsBOAdmin(userId,notificationId) {
        return axios.post(REJECT_INVITE_AS_BO_ADMIN, { userId: userId, notificationId: notificationId });
    }

    async acceptInviteAsOnboardingAdmin(userId, notificationId) {
        return axios.post(ACCEPT_INVITE_AS_ONBOARDING_ADMIN, { userId: userId, notificationId: notificationId });
    }

    async rejectInviteAsOnboardingAdmin(userId,notificationId) {
        return axios.post(REJECT_INVITE_AS_ONBOARDING_ADMIN, { userId: userId, notificationId: notificationId });
    }
}

const userService = new UserService();

export default userService;
