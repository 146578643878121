import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-display: auto;
        src: url('../../static/fonts/Roboto-Bold.ttf') format('ttf');
      }
      @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 700;
        font-display: auto;
        src: url('../../static/fonts/Roboto-BoldItalic.ttf') format('ttf');
      }
      @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-display: auto;
        src: url('../../static/fonts/Roboto-Medium.ttf') format('ttf');
      }
      @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 500;
        font-display: auto;
        src: url('../../static/fonts/Roboto-MediumItalic.ttf') format('ttf');
      }
      @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-display: auto;
        src: url('../../static/fonts/Roboto-Regular.ttf') format('ttf');
      }
      @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 400;
        font-display: auto;
        src: url('../../static/fonts/Roboto-Italic.ttf') format('ttf');
      }
      @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-display: auto;
        src: url('../../static/fonts/Roboto-Light.ttf') format('ttf');
      }
      @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 300;
        font-display: auto;
        src: url('../../static/fonts/Roboto-LightItalic.ttf') format('ttf');
      }
      `}
  />
)

export default Fonts
