import { extendTheme } from "@chakra-ui/react";

import { COLOR_NAMES as colors } from '../shared/constants';
import styles from './styles';
import Button from './components/button';
import breakpoints from './foundations/breakpoints';
import Link from './components/nav-link';
import Div from './components/div';
import Span from './components/span';
import Input from './components/input';
import Textarea from './components/textarea';
import FormLabel from './components/form-label';
import Select from './components/select';
import Notification from './components/notification';
import Divider from './components/divider';
import MultiSelect from "./components/multiselect";

const overrides = {
  styles,
  breakpoints,
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  ...colors,
  components: {
    Select,
    Button,
    Link,
    Div,
    Span,
    Input,
    Textarea,
    FormLabel,
    Notification,
    Divider,
    MultiSelect,
  }
}

const theme = extendTheme(overrides);

export default theme;
