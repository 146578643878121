import React from 'react';
import WrapperButton from './wrapper-button.component';
import { Link } from "react-router-dom";

function WrapperButtonLink({ to, state, message, styling }) {
    return (
        <Link to={to} state={state}>
            <WrapperButton message={message} styling={styling} />
        </Link>)
}

export default WrapperButtonLink;
