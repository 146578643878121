import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Avatar, Flex, VStack } from '@chakra-ui/react';
import Div from '../components/html-elements/div.component';
import Span from '../components/html-elements/span.component';
import WrapperButtonLink from '../components/buttons/wrapper-button-link.component';
import CopyToClipboard from '../components/shared/copy-to-clipboard.component';
import UserContactDetails from '../components/user/user-contact-details.component';
import useAuth from '../shared/hooks/auth.hook';
import userService from '../services/user.service';
import {
  FONTS,
  LOCLA_STORAGE_KEYS,
  ROUTES,
  VARIANT_KEYS,
  STATIC_URL,
} from '../shared/constants';
import { MdContentCopy } from 'react-icons/md';

function UserPofileViewPage({ isMyProfile }) {
  const location = useLocation();
  const [userInfo, setUserInfo] = useState({});
  const { id } = useParams();
  const copyToClipboardMargin = isMyProfile ? '74px' : null;
  const { user } = useAuth();

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    const idToGet = id || user.id;
    const response = await userService.getByIdAsync(idToGet);
    const data = response.data.data;

    if (location?.state?.fromNav) {
      saveQueryToLocalStorage(
        location.pathname,
        data.name,
        data.img_url,
        data.avatar_color
      );
    }
  
    setUserInfo({ ...data});
  };

  const saveQueryToLocalStorage = (url, name, imgLink, avatar_color) => {
    internalSaveQueryToLocalStorage(
      { url, name, imgLink, avatar_color },
      LOCLA_STORAGE_KEYS.LAST_SEARCHES_DESKTOP_KEY,
      5
    );
    internalSaveQueryToLocalStorage(
      { url, name, imgLink, avatar_color },
      LOCLA_STORAGE_KEYS.LAST_SEARCHES_MOBILE_KEY,
      4
    );
  };

  const internalSaveQueryToLocalStorage = (
    userInfo,
    localStorageKey,
    numberOfDataInLocalstorage
  ) => {
    const lastSearchesStored = localStorage.getItem(localStorageKey);
    if (!lastSearchesStored) {
      const currentQuery = JSON.stringify([{ ...userInfo }]);
      localStorage.setItem(localStorageKey, currentQuery);

      return;
    }

    const localData = JSON.parse(lastSearchesStored);
    if (
      localData.some(
        (data) =>
          data.name === userInfo.name &&
          data.url === userInfo.url &&
          data.imgLink === userInfo.imgLink &&
          data.avatar_color === userInfo.avatar_color
      )
    ) {
      return;
    }

    localData.push({ ...userInfo });

    if (localData.length > numberOfDataInLocalstorage) {
      localData.shift();
    }

    localStorage.setItem(localStorageKey, JSON.stringify(localData));
  };

  return (
    <Div h={'734px'} w={{ sm: '340px' }}>
      <Flex
        justifyContent={'space-between'}
        direction={'column'}
        w={'100%'}
        h={'100%'} >
        <VStack spacing={14}>
          <VStack spacing={3} w='100%'>
            <Div>
              <Avatar name={userInfo.name} bg={userInfo.img_url ? 'none' : userInfo.avatar_color}
                src={userInfo.img_url ? `${STATIC_URL}/${userInfo.img_url}` : ''} size={'2xl'} />
            </Div>
            <Div>
              <Span
                marginTop='5px'
                display='block'
                height='19px'
                lineHeight='19px'
                {...FONTS.normal[400]}
                fontSize='20px'>
                {userInfo.name}
              </Span>
              <Span
                marginTop='5px'
                display='block'
                height='14px'
                lineHeight='14px'
                {...FONTS.normal[300]}
                fontSize='16px' >
                {userInfo.job}
              </Span>
              <Span
                marginTop='5px'
                display='block'
                height='14px'
                lineHeight='14px'
                {...FONTS.normal[300]}
                fontSize='14px'
                color='#FDB71A' >
                {userInfo.country}, {userInfo.city}
              </Span>
            </Div>
          </VStack>
          {!isMyProfile ? (
            <UserContactDetails
              isFollowedByMeValue={userInfo.isFollowedByMe}
              userInfoId={userInfo.id} />
          )
            : null}
          <Div pr='17px' pl='17px' w='100%'>
            <Div marginTop={copyToClipboardMargin} marginBottom='20px'>
              <CopyToClipboard
                iconVariantColor={VARIANT_KEYS.color.color_gold906811_orangeFFB81C}
                icon={MdContentCopy}
                copiedValue={
                  `${userInfo.countryCode}${userInfo.phone}`
                }
                value={
                  `${userInfo.countryCode} ${userInfo.phone}`
                }
                label='Phone Number' />
            </Div>
            <Div>
              <CopyToClipboard
                iconVariantColor={VARIANT_KEYS.color.color_gold906811_orangeFFB81C}
                icon={MdContentCopy}
                value={userInfo.email}
                copiedValue={userInfo.email}
                label='Email' />
            </Div>
          </Div>
        </VStack>
        {isMyProfile ? (
          <Div w='100%'>
            <WrapperButtonLink
              to={ROUTES.EDIT_PROFILE}
              styling={{
                ...FONTS.normal[700],
                padding: '25px',
                fontSize: '22px',
                w: '100%',
                variant:
                  VARIANT_KEYS.bgAndColor
                    .bg_transparent_color_black141414_whiteFFFFFF_border_1px_FFB81C,
              }}
              message='Edit' />
          </Div>
        )
          : null}
      </Flex>
    </Div>
  );
}

export default UserPofileViewPage;
