import React from 'react';
import { Box, useStyleConfig } from '@chakra-ui/react'
import {FONT_FAMILY_NAME} from '../../shared/constants';

function Span(props) {
    const { variant, border, ...rest } = props

    const styles = useStyleConfig('Span', { variant });

    return <Box fontFamily={FONT_FAMILY_NAME} as='span' __css={styles} {...rest} />
}

export default Span;
