import React, { Fragment, useEffect } from 'react';
import { Input, InputGroup } from '@chakra-ui/react';
import { FONT_FAMILY_NAME } from '../../shared/constants';

function Search({ handleChange, outerValue, inputLeftElement, inputRightElement, placeholder, placeholderStyling, styling, children }) {
    const rest = { ...styling, fontFamily: FONT_FAMILY_NAME };
    const [value, setValue] = React.useState('')
    const onChangeHandle = (e) => {
        setValue(e.target.value);
        handleChange(e);
    }

    useEffect(() => {
        setValue(outerValue || '');
    }, [outerValue])

    return (
        <Fragment>
            <InputGroup>
                {inputLeftElement}
                <Input
                    value={value}
                    _placeholder={{ ...placeholderStyling }}
                    placeholder={placeholder}
                    onChange={onChangeHandle}
                    type='search'
                    {...rest} />
                {inputRightElement}
            </InputGroup>
            {children}
        </Fragment>
    )
}

export default Search;
