import React from 'react';
import { Flex, VStack, Textarea, HStack } from '@chakra-ui/react';
import Span from '../html-elements/span.component';
import Div from '../html-elements/div.component';
import WrapperButton from '../buttons/wrapper-button.component';
import Post from './post.component';
import postService from '../../services/post.service';
import useAuth from '../../shared/hooks/auth.hook';
import { getFormDataWithFile } from '../../shared/utils/common.utils';
import { FONTS, VARIANT_KEYS, FONT_FAMILY_NAME } from '../../shared/constants';
import toastService from '../../services/toast.service';

function ModalActionBarContent({ data, onClose, repostedMessage, onChangeMessageHandle }) {
    const { user } = useAuth();

    return (
        <Flex direction={'column'} h='100%' justifyContent='space-around'>
            <VStack>
                <HStack justifyContent={'start'} w='100%' letterSpacing={'1.1px'}>
                    <Div fontSize='16px'>Share your thoughts</Div>
                    <HStack alignContent={'center'} color={'white'} opacity='60%' spacing={1}>
                        <Span
                            borderRadius='12px'
                            h='6px'
                            w='6px'
                            bg={'white'}
                            opacity='60%' />
                        <Span fontSize='12px' >
                            optional
                        </Span>
                    </HStack>
                </HStack>
                <Textarea
                    h={'120px'}
                    value={repostedMessage}
                    onChange={onChangeMessageHandle}
                    fontFamily={FONT_FAMILY_NAME}
                    fontSize={'14px'}
                    variant={VARIANT_KEYS.TEXTAREA.PRIMARY}
                    _placeholder={{ color: 'white', opacity: '60%' }}
                    placeholder='Share your thoughts' />
            </VStack>
            <Post
                isControlMenueShown={false}
                data={data}
                useLikeInfo={true} />
            <HStack justifyContent={'space-between'}>
                <Div>
                    <WrapperButton
                        styling={{
                            variant: VARIANT_KEYS.bgAndborder.bg_transparent_color_black141414_whiteFFFFFF_border_1px_FFB81C_whiteFFFFFF,
                            fontSize: '16px',
                            h: '40px',
                            fontWeight: FONTS.normal[700],
                            w: '100%',
                        }}
                        onClickHandle={onClose}
                        message={'Cancel'} />
                </Div>
                <Div w='200px'>
                    <WrapperButton
                        styling={{
                            variant: VARIANT_KEYS.bgAndColor.bg_oragneFDB71A_oragneFFB81C_color_black141414_black141414,
                            fontSize: '16px',
                            h: '40px',
                            fontWeight: FONTS.normal[700],
                            w: '100%',
                            isDisabled: user.isMuted,
                            _hover: {},
                        }}
                        onClickHandle={async () => {
                            try {
                                const { message, file, id, fileType } = data;
                                const form = getFormDataWithFile({ fileType, message, file, repostedMessage, isReposted: true, parrentId: id }, null);
                                const response = await postService.createAsync(form);
                                toastService.success(response.data.data);
                                onClose();
                            } catch (error) {
                                toastService.error(error.response.data.errorMessage);
                            }
                        }}
                        message={'Post'} />
                </Div>
            </HStack>
        </Flex>
    )
}

export default ModalActionBarContent;
