import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { VStack, HStack, Flex, Textarea } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import UploadWithPreviewButton from '../components/buttons/upload-with-preview-button.component';
import FormTextarea from '../components/html-elements/form-textarea.component';
import WrapperButton from '../components/buttons/wrapper-button.component';
import Div from '../components/html-elements/div.component';
import Post from '../components/posts/post.component';
import Span from '../components/html-elements/span.component';
import postService from '../services/post.service';
import fileService from '../services/file.service';
import toastService from '../services/toast.service';
import useAuth from '../shared/hooks/auth.hook';
import useConfigurations from '../shared/hooks/configurations.hook';
import {
    VARIANT_KEYS,
    FONTS, FONT_FAMILY_NAME,
    ROUTES, STATIC_URL,
    MAP_FROM_MIME_TYPE_TO_FILE_TYPE,
	CONFIGURATION_KEYS
} from '../shared/constants';
import Yup from '../shared/yup';

function EditPostPage() {
    const [post, setPost] = useState(null);
    const [file, setFile] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [repostedMessage, setRepostedMessage] = useState('');
    const [fileType, setFileType] = useState(null);

    const { configurations } = useConfigurations();
    const { user } = useAuth();
    const navigate = useNavigate();
    const params = useParams();

    const handleFileSelect = (e) => {
        if (e.target.files.length !== 0) {
            const selectedFile = e.target.files[0];

            const type = MAP_FROM_MIME_TYPE_TO_FILE_TYPE[selectedFile?.type];
            if (!type) {
                return;
            }

            setFileType(type);
            setSelectedFile(selectedFile);
            const url = URL.createObjectURL(selectedFile);
            setFile(url);
        }
    };

    const handleDeclineFile = () => {
        setSelectedFile(null);
        setFile(null);
        setPost(prev => ({ ...prev, file: null }));
    }

    // We have to see and to use one approache
    const handleSubmit = async (values) => {
        try {
            const formData = new FormData();
            if (selectedFile) {
                formData.append('file', selectedFile);
            }

            formData.append('message', values.message);
            formData.append('file', post.file);
            formData.append('fileType', fileType);

            await postService.editAsync(post.uuid, formData);

            // Check for error
            navigate(ROUTES.HOME);
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    //  Check to be deleted
    const handleSubmitRepost = async () => {
        try {
            await postService.editRepostedAsync(post.id, repostedMessage);

            // Check for error
            navigate(ROUTES.HOME);
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    const initialData = async () => {
        try {
            const post = await (await postService.authorPostByUuid(params.uuid)).data.data;
            if (!post) {
                navigate(ROUTES.HOME);

                return;
            }

            if (post && post.file) {
                setFile(`${STATIC_URL}/${post.file}`);
            }

            if (post.isReposted) {
                setRepostedMessage(post.repostedMessage || '');
            }

            if (post.file) {
                setTimeout(async () => {
                    const response = await fileService.getFileAsBlobFromS3(post.file);
                    const file = new Blob([response.data]);
                    const fileURL = URL.createObjectURL(file);

                    setFile(fileURL);
                }, 0);
            }

            setFileType(post.fileType);
            setPost(post);
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    useEffect(() => {
        if (!params || !params.uuid) {
            navigate(ROUTES.HOME);
        }

        initialData();
    }, []);

    useEffect(() => {
        if (!configurations[CONFIGURATION_KEYS.postsEditing].isActive) {
            toastService.warning(configurations[CONFIGURATION_KEYS.postsEditing].messageUnavailable);
        }
    }, [configurations])

    const schema = Yup.object().shape({
        message: Yup.string().required('Please enter post message:'),
    });

    const stylingButton = {
        variant: VARIANT_KEYS.bgAndColor.bg_oragneFDB71A_oragneFFB81C_color_black141414_black141414,
        fontSize: '22px',
        h: '48px',
        borderRadius: '6px',
        fontWeight: FONTS.normal[700],
        w: '100%',
        marginTop: '116px',
        isDisabled: user.isMuted || !configurations[CONFIGURATION_KEYS.postsEditing].isActive,
        _hover: {},
    }

    return (
        <Div w={{ sm: '340px' }}>
            {post && post.message &&
                <Fragment>
                    {post.isReposted
                        ? (<Flex direction={'column'} h='100%' justifyContent='space-around'>
                            <VStack>
                                <HStack justifyContent={'start'} w='100%' letterSpacing={'1.1px'}>
                                    <Div fontSize='16px'>Share your thoughts</Div>
                                    <HStack alignContent={'center'} color={'white'} opacity='60%' spacing={1}>
                                        <Span
                                            borderRadius='12px'
                                            h='6px'
                                            w='6px'
                                            bg={'white'}
                                            opacity='60%' />
                                        <Span fontSize='12px' >
                                            optional
                                        </Span>
                                    </HStack>
                                </HStack>
                                <Textarea
                                    h={'120px'}
                                    value={repostedMessage}
                                    onChange={(e) => setRepostedMessage(e.target.value)}
                                    fontFamily={FONT_FAMILY_NAME}
                                    fontSize={'14px'}
                                    variant={VARIANT_KEYS.TEXTAREA.PRIMARY}
                                    _placeholder={{ color: 'white', opacity: '60%' }}
                                    placeholder='Share your thoughts' />
                            </VStack>
                            <Div mt='20px'>
                                <Post
                                    isControlMenueShown={false}
                                    data={post}
                                    useLikeInfo={true} />
                            </Div>

                            <Div w='100%'>
                                <WrapperButton
                                    styling={{ ...stylingButton, marginTop: '100px' }}
                                    onClickHandle={handleSubmitRepost}
                                    message={'Update'} />
                            </Div>
                        </Flex>)
                        : (<Formik
                            initialValues={{ message: post.message }}
                            validationSchema={schema}
                            onSubmit={async (values, actions) => {
                                await handleSubmit(values);
                            }}>
                            {(props) => (
                                <Form>
                                    <VStack alignItems={'start'} alignContent={'start'} spacing={10}>
                                        <FormTextarea
                                            name={'message'}
                                            label={'Create new post'}
                                            placeholder={'What would you like to share?'}
                                            isRequired={true}
                                            textareaStyling={{ variant: VARIANT_KEYS.TEXTAREA.PRIMARY, w: '100%', h: '150px' }}
                                            formikProps={props} />
                                        <VStack h='311px' w='100%'>
                                            <Div w='100%' fontSize='14px' textAlign='start'>Choose file: (pdf, image, video)</Div>
                                            <Div w={'100%'}>
                                                <UploadWithPreviewButton
                                                    handleDeclineFileSelect={handleDeclineFile}
                                                    handleFileSelect={handleFileSelect}
                                                    fileType={fileType}
                                                    file={file}
                                                    allowOnlyPicture={false} />
                                            </Div>
                                        </VStack>
                                        <Div w='100%'>
                                            <WrapperButton styling={stylingButton} message={'Update'} type={'submit'} />
                                        </Div>
                                    </VStack>
                                </Form>
                            )}
                        </Formik>)
                    }
                </Fragment>
            }
        </Div>
    )
}

export default EditPostPage;
