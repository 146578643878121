import React, { useEffect, useState } from 'react';
import { HStack, VStack } from '@chakra-ui/react';
import Div from '../html-elements/div.component';
import ExternalIcon from '../icons/external-icon.component';
import WrapperButton from '../buttons/wrapper-button.component';
import WrapperButtonLink from '../buttons/wrapper-button-link.component';
import userService from '../../services/user.service';
import { FONTS, ROUTES, VARIANT_KEYS } from '../../shared/constants';
import { MdEmail, MdClose } from 'react-icons/md';
import { FaShareSquare } from 'react-icons/fa'
import { HiPlus } from 'react-icons/hi';
import toastService from '../../services/toast.service';

function UserContactDetails({ marginStyling, isFollowedByMeValue, userInfoId }) {
    const [isFollowedByMe, setIsFollowedByMe] = useState(false);

    useEffect(() => {
        setIsFollowedByMe(isFollowedByMeValue);
    }, [isFollowedByMeValue])

    const toggleFollow = async () => {
        try {
            if (!isFollowedByMe) {
                await userService.follow(userInfoId);
                setIsFollowedByMe(true);
            } else {
                await userService.unfollow(userInfoId);
                setIsFollowedByMe(false);
            }
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }

    };

    const variant = { variant: VARIANT_KEYS.bgAndColor.bg_transparent_black2C2F2A_color_blue2097F6_blue4F93F1 };
    const variantFollow = isFollowedByMe
        ? { variant: VARIANT_KEYS.bgAndColor.bg_transparent_black2C2F2A_color_gray929292_gray929292 }
        : variant;

    const userContactButtonStyling = {
        paddingLeft: '44px',
        paddingRight: '44px',
        paddingTop: '27px',
        paddingBottom: '27px',
        gap: '2px',
        borderRadius: '8px',
        w: '75px',
    }

    return (
        <Div>
            <HStack w={{ sm: '340px' }} justifyContent={'space-around'} {...marginStyling} >
                <Div w='90px'>
                    <WrapperButtonLink
                        to={`${ROUTES.USER_POSTS}/${userInfoId}`}
                        styling={{ ...userContactButtonStyling, ...variant }}
                        message={
                            <VStack spacing={0}>
                                <Div><ExternalIcon styling={{ width: '18px', height: '18px' }} icon={FaShareSquare} /></Div>
                                <Div letterSpacing='1.1px' fontSize='14px' {...FONTS.normal[400]}>user posts</Div>
                            </VStack>
                        } />
                </Div>
                <Div w='90px'>
                    <WrapperButton
                        styling={{ ...userContactButtonStyling, ...variant }}
                        message={
                            <VStack spacing={0}>
                                <Div><ExternalIcon styling={{ width: '18px', height: '18px' }} icon={MdEmail} /></Div>
                                <Div letterSpacing='1.1px' fontSize='14px' {...FONTS.normal[400]}>mail </Div>
                            </VStack>
                        } />
                </Div>
                <Div w='90px'>
                    <WrapperButton
                        onClickHandle={toggleFollow}
                        styling={{ ...userContactButtonStyling, ...variantFollow }}
                        message={
                            !isFollowedByMe
                                ? <VStack spacing={0}>
                                    <Div><ExternalIcon styling={{ width: '18px', height: '18px' }} icon={HiPlus} /></Div>
                                    <Div letterSpacing='1.1px' fontSize='14px' {...FONTS.normal[400]}>follow</Div>
                                </VStack>
                                : <VStack spacing={0}>
                                    <Div><ExternalIcon styling={{ width: '18px', height: '18px' }} icon={MdClose} /></Div>
                                    <Div letterSpacing='1.1px' fontSize='14px' {...FONTS.normal[400]}>unfollow</Div>
                                </VStack>
                        } />
                </Div>
            </HStack>
        </Div>
    )
}

export default UserContactDetails;
