import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { VStack } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import UploadWithPreviewButton from '../components/buttons/upload-with-preview-button.component';
import FormTextarea from '../components/html-elements/form-textarea.component';
import WrapperButton from '../components/buttons/wrapper-button.component';
import Div from '../components/html-elements/div.component';
import postService from '../services/post.service';
import toastService from '../services/toast.service';
import useAuth from '../shared/hooks/auth.hook';
import useConfigurations from '../shared/hooks/configurations.hook';
import { getFormDataWithFile } from '../shared/utils/common.utils';
import { VARIANT_KEYS, FONTS, ROUTES, POST_FILE_TYPES, MAP_FROM_MIME_TYPE_TO_FILE_TYPE, CONFIGURATION_KEYS } from '../shared/constants';
import Yup from '../shared/yup';

function CreatePostPage() {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [selectedFile, setSelectedFile] = useState(null);
    const [image, setImage] = useState(null);
    const [fileType, setFileType] = useState(POST_FILE_TYPES.imageType);
    const { configurations } = useConfigurations();

    useEffect(() => {
        if (!configurations[CONFIGURATION_KEYS.posting].isActive) {
            toastService.warning(configurations[CONFIGURATION_KEYS.posting].messageUnavailable);
        }
        if ((state?.groupId && !configurations[CONFIGURATION_KEYS.groupsPosting].isActive)) {
            toastService.warning(configurations[CONFIGURATION_KEYS.groupsPosting].messageUnavailable);
        }
    }, [configurations, state?.groupId]);


    const handleFileSelect = (e) => {
        if (e.target.files.length !== 0) {
            const selectedFile = e.target.files[0];

            const type = MAP_FROM_MIME_TYPE_TO_FILE_TYPE[selectedFile?.type]; 
            if (!type) {
                return;
            }

            setFileType(type);
            setSelectedFile(selectedFile);
            setImage(URL.createObjectURL(selectedFile));
        }
    };

    const handleDeclineFile = () => {
        setSelectedFile(null);
        setImage(null);
    }

    const handleSubmit = async (message) => {
        try {
            const form = getFormDataWithFile({ message, fileType }, selectedFile);

            if (state && state.groupId) {
                await postService.createGroupPostAsync(form, state.groupId);
                navigate(`${ROUTES.GROUP_DETAILS}/${state.groupId}`, { replace: true });

                return;
            }

            await postService.createAsync(form);

            navigate(ROUTES.HOME, { replace: true });
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    const schema = Yup.object().shape({
        message: Yup.string().required('Please enter post message:'),
    });

    const stylingButton = {
        variant: VARIANT_KEYS.bgAndColor.bg_oragneFDB71A_oragneFFB81C_color_black141414_black141414,
        fontSize: '22px',
        h: '48px',
        borderRadius: '6px',
        fontWeight: FONTS.normal[700],
        w: '100%',
        marginTop: '116px',
        isDisabled: user.isMuted || !configurations[CONFIGURATION_KEYS.posting].isActive || (state?.groupId && !configurations[CONFIGURATION_KEYS.groupsPosting].isActive),
        _hover: {},
    }

    return (
        <Div w={{ sm: '340px' }}>
            <Formik
                initialValues={{ message: '' }}
                validationSchema={schema}
                onSubmit={async (values, actions) => {
                    await handleSubmit(values.message);
                }}>
                {(props) => (
                    <Form>
                        <VStack alignItems={'start'} alignContent={'start'} spacing={10}>
                            <FormTextarea
                                name={'message'}
                                label={'Create new post'}
                                placeholder={'What would you like to share?'}
                                isRequired={true}
                                textareaStyling={{ variant: VARIANT_KEYS.TEXTAREA.PRIMARY, w: '100%', h: '150px' }}
                                formikProps={props} />
                            <VStack h='311px' w='100%'>
                                <Div w='100%' fontSize='14px' textAlign='start'>Choose file: (pdf, image, video)</Div>
                                <Div w='100%'>
                                    <UploadWithPreviewButton
                                        handleDeclineFileSelect={handleDeclineFile}
                                        handleFileSelect={handleFileSelect}
                                        file={image}
                                        fileType={fileType}
                                        allowOnlyPicture={false} />
                                </Div>
                            </VStack>
                            <Div w='100%'>
                                <WrapperButton styling={stylingButton} message={'Post'} type={'submit'} />
                            </Div>
                        </VStack>
                    </Form>
                )}
            </Formik>
        </Div>
    )
}

export default CreatePostPage;
