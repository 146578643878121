import React, { Fragment } from 'react';
import { useClipboard, HStack, VStack } from '@chakra-ui/react';
import ExternalIcon from '../icons/external-icon.component';
import Div from '../html-elements/div.component';
import { FONTS, VARIANT_KEYS } from '../../shared/constants';

function CopyToClipboard({
    value,
    copiedValue,
    label,
    icon,
    iconVariantColor,
    iconLeft, 
    w = '100%',
    copiedMessageGetHeigth = true }) {
    const { hasCopied, onCopy } = useClipboard(copiedValue);

    return (
        <Fragment>
            <VStack w={w} letterSpacing={'1.1px'} spacing={0}>
                {label &&
                    <Div
                        variant={VARIANT_KEYS.color.color_gray5E5E5E_grayB4B4B4}
                        marginBottom='10px'
                        fontSize='14px' {...FONTS.normal[400]}
                        textAlign='start'
                        w='100%'>
                        {label}
                    </Div>
                }
                <HStack onClick={onCopy} w='100%' justifyContent={'space-between'}>
                    {iconLeft &&
                        <Div display='flex' variant={iconVariantColor}>
                            <ExternalIcon styling={{ h: '20px', w: '18px' }} icon={icon} />
                        </Div>
                    }
                    <Div variant={VARIANT_KEYS.color.color_black141414_whiteFFFFFF} fontSize='16px' {...FONTS.normal[400]} textAlign='start'>{value}</Div>
                    {!iconLeft &&
                        <Div display='flex' variant={iconVariantColor}>
                            <ExternalIcon styling={{ h: '20px', w: '18px' }} icon={icon} />
                        </Div>
                    }
                </HStack>

            </VStack>
            {copiedMessageGetHeigth &&
                <Div w='100%' textAlign='start'>
                    {hasCopied
                        ? (<Div
                            variant={VARIANT_KEYS.color.color_gold906811_orangeFFB81C}
                            fontSize='14px'
                            {...FONTS.normal[400]}
                            textAlign='start'>Copied to clipboard
                        </Div>)
                        : <Div height='21px'></Div>
                    }
                </Div>
            }
            {!copiedMessageGetHeigth &&
                <Div w='100%' textAlign='start'>
                    {hasCopied &&
                        (<Div
                            variant={VARIANT_KEYS.color.color_gold906811_orangeFFB81C}
                            fontSize='14px'
                            {...FONTS.normal[400]}
                            textAlign='start'>Copied to clipboard
                        </Div>)
                    }
                </Div>
            }
        </Fragment>
    )
}

export default CopyToClipboard;
