import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../shared/hooks/auth.hook';
import { ROUTES } from '../../shared/constants';

function ProtectRouter({ children }) {
    const authContext = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!authContext.isLoggedIn) {
            return navigate(ROUTES.AUTHENTICATION, { state: { redirectTo: location.pathname } });
        }
    });

    return children;
}

export default ProtectRouter;
