import React from 'react';
import { Flex, HStack, VStack } from '@chakra-ui/react'
import Div from '../html-elements/div.component';
import Span from '../html-elements/span.component';
import { FONTS } from '../../shared/constants';

function DashboardCard({
    outerContainer,
    fontSizeMessage,
    message,
    fontSizeCountMessage,
    widthCountMessage,
    countMessage,
    isVertical = false
}) {
    const { pl, pb, pr, pt, borderRadius, variant, h, w } = outerContainer;

    return (
        <Div pl={pl} pb={pb} pr={pr} pt={pt} borderRadius={borderRadius} variant={variant} h={h} w={w}>
            {isVertical
                ? (
                    <VStack
                        spacing={0}
                        fontWeight={FONTS.normal[700].fontWeight}
                        h='100%'
                        alignItems={'center'}
                        justifyContent={'space-between'}>
                        <Span fontWeight={FONTS.normal[400].fontWeight} h='50%' textAlign='start' fontSize={fontSizeMessage} display='block'>{message}</Span>
                        <Flex h='50%' alignItems={'baseline'}>
                            <Span w={widthCountMessage} textAlign='start' fontSize={fontSizeCountMessage} display='block'>
                                {countMessage}
                            </Span>
                        </Flex>
                    </VStack>
                )
                : (
                    <HStack fontWeight={FONTS.normal[700].fontWeight} h='100%' alignItems={'center'}>
                        <Span fontWeight={FONTS.normal[400].fontWeight} w={'80%'} textAlign='start' fontSize={fontSizeMessage} display='block'>{message}</Span>
                        <Flex w={'20%'} h='100%' alignItems={'center'}>
                            <Span w={widthCountMessage} textAlign='start' fontSize={fontSizeCountMessage} display='block'>
                                {countMessage}
                            </Span>
                        </Flex>
                    </HStack>
                )
            }
        </Div>
    )
}

export default DashboardCard;
