import React from 'react';
import { useRadio, chakra, Box } from '@chakra-ui/react';
import Div from './div.component';

function ColorfulRadioButton(props) {
    const { color, size, ...radioProps } = props
    const { getInputProps, getCheckboxProps, htmlProps } = useRadio(radioProps)

    return (
        <chakra.label {...htmlProps} cursor='pointer'>
            <input {...getInputProps({})} hidden />
            <Box {...getCheckboxProps()} rounded='full'>
                <Div h={size} w={size} bg={color} rounded='full' />
            </Box>
        </chakra.label>
    )
}

export default ColorfulRadioButton;
