import React, { Fragment, useEffect, useState } from 'react';
import { Avatar, Divider, Flex, HStack, Image, VStack, Textarea, useMediaQuery, useDisclosure, AvatarGroup } from '@chakra-ui/react';
import Div from '../html-elements/div.component';
import VideoViewer from './video-viewer.component';
import PdfViewer from './pdf-viewer.component';
import Link from '../html-elements/link.component';
import ControlMenuDekstop from '../shared/control-menu-dekstop.component';
import TooltipIcon from '../shared/tooltip-icon.component';
import ControlMenuMobile from '../shared/control-menu-mobile.component';
import ControlMenuModal from '../shared/control-menu-modal.component';
import PostUserActionsHeader from '../shared/post-user-actions-header.component';
import ExternalIcon from '../icons/external-icon.component';
import WrapperButton from '../buttons/wrapper-button.component';
import Dialog from '../shared/dialog.component';
import Span from '../html-elements/span.component';
import ActionBarPost from '../posts/action-bar-post.component';
import ReportReason from '../shared/report-reason.component';
import CommentsList from '../comments/comments-list.component';
import useAuth from '../../shared/hooks/auth.hook';
import useConfigurations from '../../shared/hooks/configurations.hook';
import useKnowMeMediaQuery from '../../shared/hooks/media-query.hook';
import commentService from '../../services/comment.service';
import fileService from '../../services/file.service';
import postService from '../../services/post.service';
import toastService from '../../services/toast.service';
import {
    STATIC_URL,
    VARIANT_KEYS,
    FONTS,
    COLOR_NAMES,
    MEDIA,
    ROUTES,
    COMMENT_MODE,
    FONT_FAMILY_NAME,
    GROUP_ROLES,
    POST_FILE_TYPES,
    CONFIGURATION_KEYS,
} from '../../shared/constants';
import { MdClose, MdFlag, MdCloudDownload } from 'react-icons/md';
import { HiPlus } from 'react-icons/hi';
import { RiEditCircleLine, RiDeleteBin5Fill } from 'react-icons/ri';
import { BiDotsHorizontalRounded } from 'react-icons/bi';

function Post({
    data,
    toggleFollow,
    isFollowedByMe,
    includePeopWhoLikesAndCommentIt,
    isControlMenueShown = true,
    useLikeInfo = false,
    repostMode = false,
    pl = '0px',
    pr = '0px',
    onClickReport,
    handleDeletePost,
    addGroupAvatarToPost = false,
    addUserInteractionHeaderToPost = false,
    specificCommentIdToDisplay = null,
}) {
    const [postedUser, setPostedUser] = useState(null);
    const [comments, setComments] = useState([]);
    const [isCommentsShown, setIsCommentsShown] = useState(false);
    const [commentMode, setCommentMode] = useState(COMMENT_MODE.READ);
    const [page, setPage] = useState(1);
    const [hasMoreComments, setHasMoreComments] = useState(false);
    const [isTextareaActive, setIsTextareaActive] = useState(false);
    const [commentMessage, setCommentMessage] = useState('');
    const [commentsCount, setCommentsCount] = useState(data.commentsCount);
    const [isOnPdf, setIsOnPdf] = useState(false);

    const media = useKnowMeMediaQuery();
    const { configurations } = useConfigurations();

    const { user, groupId, currentUserRole } = data;
    const adminRights = currentUserRole === GROUP_ROLES.ADMIN || currentUserRole === GROUP_ROLES.OWNER;
    const authUser = useAuth().user;
    const isMyPost = authUser.id === user.id;
    const shouldModifyGroupPost = addGroupAvatarToPost && data.group;

    const hasUserInteraction = !!(data.liked.length || data.comments.length) && addUserInteractionHeaderToPost
    const createUserInteractionObject = (hasComments) => {
        return hasComments
            ? {
                interactionString: 'commented on',
                interactedUsersArray: data.comments.map(e => e.user),
            }
            : {
                interactionString: 'liked',
                interactedUsersArray: data.liked,
            }
    }
    const userInteractionObject = hasUserInteraction ? createUserInteractionObject(!!data.comments.length) : {}

    const variant = { variant: VARIANT_KEYS.bgAndColor.bg_transparent_black2C2F2A_color_blue2097F6_blue4F93F1 };
    const variantFollow = isFollowedByMe
        ? { variant: VARIANT_KEYS.bgAndColor.bg_transparent_black2C2F2A_color_gray929292_gray929292 }
        : variant;

    const handleDeleteComment = async (commentId, authorId) => {
        try {
            const ownComment = authUser.id === authorId;
            const { data } = adminRights && !ownComment
                ? await commentService.deleteFromGroupAsync(commentId, groupId)
                : await commentService.deleteAsync(commentId)

            // Notify
            if (!data.isSuccess) { return; }

            setComments(prev => {
                const data = [];
                for (const comment of prev) {
                    if (comment.id !== commentId) {
                        data.push({ ...comment });

                        continue;
                    }

                    if (!ownComment) {
                        data.push({ ...comment, isDeleted: true })
                    }
                }

                return data;
            });

            if (ownComment) { setCommentsCount(prev => prev - 1) }

        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    const handleReportComment = async (reason, commentId) => {
        try {
            const response = await commentService.reportAsync(commentId, data.id, reason);

            // Notify
            setComments(prev => {
                const data = [];
                for (const comment of prev) {
                    if (comment.id === commentId) {
                        data.push({ ...comment, reportedByMeOn: Date.now() });

                        continue;
                    }

                    data.push({ ...comment, isUpdateMode: false });
                }
                return data;
            });
            toastService.success(response.data.data);
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    const handleMode = (value, id) => {
        setComments(prev => {
            const data = [];
            for (const comment of prev) {
                if (comment.id === id) {
                    data.push({ ...comment, isUpdateMode: value });

                    continue;
                }

                data.push({ ...comment, isUpdateMode: false });
            }

            return data;
        });
    }

    const handleCommentChange = async ({ message, id }) => {
        try {
            await commentService.editAsync(id, message);
            setComments(prev => {
                const data = [];
                for (const comment of prev) {
                    if (comment.id === id) {
                        data.push({ ...comment, message: message, isUpdateMode: false });

                        continue;
                    }

                    data.push({ ...comment, isUpdateMode: false });
                }

                return data;
            });
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    const loadMoreComments = async () => {
        try {
            const commentsData = (await commentService.commentsByPostId(data.id, page)).data;
            if (!commentsData.isSuccess) {
                // Notify
                return;
            }

            commentsData.data.length < 5
                ? setHasMoreComments(false)
                : setHasMoreComments(true);

            setComments(prev => [...prev, ...commentsData.data]);
            setPage(prev => prev + 1);
        } catch (error) {
            console.log(error);
        }
    }

    const getOneComment = async () => {
        try {
            const { data, isSuccess, errorMessage } = (await commentService.getCommentByIdAsync(specificCommentIdToDisplay)).data;
            if (!isSuccess) {
                return;
            }

            setHasMoreComments(false);
            setCommentsCount(1);
            setComments([data]);
            setIsCommentsShown(true);
        } catch (err) {
            console.log(err);
        }
    }

    const commentModeHandle = async (commentMode) => {
        setCommentMode(commentMode);
        setCommentMessage('');
        setIsCommentsShown(prev => !prev);
        setPage(1);
        setComments([]);

        if (!isCommentsShown) {
            try {
                await loadMoreComments();
            } catch (error) {
                // Notify
            }
        }
    }

    const handleOnFocusTextarea = () => {
        setIsTextareaActive(true);
        if (!configurations[CONFIGURATION_KEYS.comment].isActive) {
            toastService.warning(configurations[CONFIGURATION_KEYS.comment].messageUnavailable);
        }
        if (!configurations[CONFIGURATION_KEYS.groupComment].isActive && groupId) {
            toastService.warning(configurations[CONFIGURATION_KEYS.groupComment].messageUnavailable);
        }
    }

    const handleOnChangeTextarea = (e) => {
        setCommentMessage(e.target.value);
    }

    const addComment = async () => {
        try {
            await postService.addComment({ message: commentMessage }, data.uuid);
            const commentsData = (await commentService.commentsByPostId(data.id, 1)).data.data;
            setCommentsCount(prev => prev + 1);
            setComments(commentsData);
            setPage(2);
            setHasMoreComments(true);
            setIsTextareaActive(false);
            setCommentMessage('');
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    const likesAndComments = () => {
        if (!hasUserInteraction) {
            return;
        }

        const names = [];
        const result = (
            <Fragment>
                <Flex alignItems={'center'} columnGap='25px' w='100%'>
                    <AvatarGroup size='sm' max={2} spacing={'-10px'}>
                        {
                            userInteractionObject.interactedUsersArray.map((u, i) => {
                                if (i <= 2) { names.push(u.name) };
                                return (
                                    <Avatar
                                        key={`userInteractionObject-${u.id}`}
                                        name={u.name}
                                        bg={u.img_url ? 'none' : u.avatar_color}
                                        border={'none'}
                                        src={u.img_url ? `${STATIC_URL}/${u.img_url}` : ''} />
                                )

                            })}
                    </AvatarGroup>
                    <Div position={names.length > 1 ? 'relative' : {}} right='18px' textAlign='start'>
                        <Span fontSize='14px' fontWeight={FONTS.normal[300].fontWeight}>
                            {`${names[0]}`}
                            {userInteractionObject.interactedUsersArray.length === 2 && ` and ${names[1]}`}
                            {userInteractionObject.interactedUsersArray.length > 2 && `, ${names[1]} and ${userInteractionObject.interactedUsersArray.length - 2} more `}
                            {` ${userInteractionObject.interactionString} this`}
                        </Span>
                    </Div>
                </Flex>
                <Divider />
            </Fragment>
        )

        return result;
    }

    const sharePostMessage = () => {
        if (!data.isReposted || !repostMode) {
            return null;
        }

        const u = data.user;
        const result = (
            <Fragment>
                <Flex alignItems={'center'} columnGap='10px' w='100%'>
                    <Div right='18px' key={`${u.id}_image_${data.id}`}>
                        <Avatar name={u.name} bg={u.img_url ? 'none' : u.avatar_color} border={'none'}
                            src={u.img_url ? `${STATIC_URL}/${u.img_url}` : ''} size={'sm'} />
                    </Div>
                    <Div textAlign='start'>
                        <Span fontSize='14px' fontWeight={FONTS.normal[300].fontWeight}>
                            {`${u.name} shared this`}
                        </Span>
                    </Div>

                </Flex>
                {data.repostedMessage ? <Div w='100%' textAlign='start'>
                    <Span fontSize='14px' fontWeight={FONTS.normal[300].fontWeight}>
                        {data.repostedMessage}
                    </Span>
                </Div>
                    : null
                }
                <Divider bg={COLOR_NAMES.grayC4C4C4} />
            </Fragment>
        )

        return result;
    }

    useEffect(() => {
        if (!data) {
            return;
        }

        if (specificCommentIdToDisplay) {
            getOneComment();
        }

        if (data.isReposted) {
            setPostedUser({
                avatar_color: data.parrentPost.user.avatar_color,
                name: data.parrentPost.user.name,
                img_url: data.parrentPost.user.img_url,
            });
        } else {
            setPostedUser({ ...user });
        }
    }, [data]);

    const openPdfInNewWindow = async () => {
        try {
            const response = await fileService.getFileAsBlobFromS3(data.file);
            const file = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
        } catch (error) {
            console.log(error.message);
        }
    }

    const [isLargerThan550] = useMediaQuery(MEDIA.MIN_WIDTH_550_PX);

    const { isOpen: isDrrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
    const { isOpen: isReasonOpen, onOpen: onReasonOpen, onClose: onReasonClose } = useDisclosure();
    const { isOpen: isDeleteopen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();

    const onClickReportHandle = (reason) => {
        onClickReport(reason, data.id);
        onReasonClose();
    }

    const deletePost = () => {
        handleDeletePost(data.uuid);
    }

    const onCloseResonHandleMobile = () => {
        onReasonClose();
        onDrawerClose();
    }

    const onDeleteCloseHandleMobile = () => {
        onDeleteClose();
        onDrawerClose();
    }

    const onClickReportHandleMobile = (reason) => {
        onClickReport(reason, data.id);
        onDrawerClose();
    }

    const deleteContent = <HStack onClick={onDeleteOpen} h='36px'>
        <Flex alignItems={'center'} h='100%'>
            <ExternalIcon styling={{ w: '24px', h: '24px' }} icon={RiDeleteBin5Fill} />
        </Flex>
        <Div fontSize='14px'>Delete post</Div>
        <Dialog
            isOpen={isDeleteopen}
            onClose={isLargerThan550 ? onDeleteClose : onDeleteCloseHandleMobile}
            onAction={deletePost}
            messageBody={`Are you shure you want to delete selected post`}
            messageHeader={`Delete post`} />
    </HStack>

    const mainControlContent = <Flex pl='20px' justifyContent={'space-around'} direction={'column'} h='100%'>
        <Link to={`${ROUTES.EDIT_POST}/${data.uuid}`}>
            <HStack>
                <Flex alignItems={'center'} h='100%'>
                    <ExternalIcon styling={{ w: '24px', h: '24px' }} icon={RiEditCircleLine} />
                </Flex>
                <VStack spacing={0}>
                    <Div textAlign='start' w='100%' fontSize='14px'>Edit</Div>
                </VStack>
            </HStack>
        </Link>
        {deleteContent}
    </Flex>

    const reasonControlContent = <Flex pl='20px' justifyContent={'space-around'} direction={'column'} h='100%'>
        <HStack onClick={onReasonOpen}>
            <Flex alignItems={'center'} h='100%'>
                <ExternalIcon styling={{ w: '24px', h: '24px', opacity: data.reportedByMeOn ? '50%' : '100%' }} icon={MdFlag} />
            </Flex>
            {!data.isReportedByMe
                ? <Fragment>
                    <VStack spacing={0}>
                        <Div textAlign='start' w='100%' fontSize='14px'>Report post</Div>
                    </VStack>
                    <ControlMenuModal isLargerThan550={isLargerThan550} isOpen={isReasonOpen} onClose={onCloseResonHandleMobile}>
                        <ReportReason handleOnClick={isLargerThan550 ? onClickReportHandle : onClickReportHandleMobile} />
                    </ControlMenuModal>
                </Fragment>
                : <Div textAlign='start' w='100%' fontSize='14px' opacity='50%'>{`Reported on: ${new Date(data.reported[0].posts_reports.date_create).toLocaleString()}`}</Div>
            }
        </HStack>
        {adminRights && deleteContent}
    </Flex>

    const desktopControl = isMyPost
        ? <ControlMenuDekstop isLargerThan550={isLargerThan550} h={'140px'}>
            {mainControlContent}
        </ControlMenuDekstop>
        : <ControlMenuDekstop isLargerThan550={isLargerThan550} h={'140px'}>
            {reasonControlContent}
        </ControlMenuDekstop>

    const mobileControl = isMyPost
        ? <ControlMenuMobile
            drawwerButton={<ExternalIcon onClick={onDrawerOpen} styling={{ fontSize: '26px' }} icon={BiDotsHorizontalRounded} />}
            isDrrawerOpen={isDrrawerOpen}
            onDrawerClose={onDrawerClose}
            h={'140px'}>
            {mainControlContent}
        </ControlMenuMobile>
        : <ControlMenuMobile
            drawwerButton={<ExternalIcon onClick={onDrawerOpen} styling={{ fontSize: '26px' }} icon={BiDotsHorizontalRounded} />}
            isDrrawerOpen={isDrrawerOpen}
            onDrawerClose={onDrawerClose}
            h={'140px'}>
            {reasonControlContent}
        </ControlMenuMobile>

    return (
        <Div>
            <VStack pl={pl} pr={pr}>
                {isControlMenueShown &&
                    <Flex position={'relative'} mt='10px' bottom='20px' w={'100%'} justifyContent={'end'}>
                        {isLargerThan550
                            ? desktopControl
                            : mobileControl
                        }
                    </Flex>
                }
                {includePeopWhoLikesAndCommentIt && hasUserInteraction && likesAndComments()}
                {sharePostMessage()}
                <Flex justifyContent={'space-between'} w={'100%'}>
                    {postedUser &&
                        <PostUserActionsHeader isGroupPost={shouldModifyGroupPost} user={postedUser} group={data.group} />
                    }
                    {!isMyPost && !useLikeInfo &&
                        <WrapperButton
                            onClickHandle={() => toggleFollow(user.id)}
                            styling={{ ...variantFollow, bg: 'transperant', gap: '2px', p: '0px' }}
                            message={
                                !isFollowedByMe
                                    ? <Flex alignItems={'center'}>
                                        <ExternalIcon styling={{ width: '18px', height: '18px' }} icon={HiPlus} />
                                        <Div ml='2px' letterSpacing='1.1px' fontSize='14px' {...FONTS.normal[400]}>follow</Div>
                                    </Flex>
                                    : <Flex alignItems={'center'}>
                                        <ExternalIcon styling={{ width: '18px', height: '18px' }} icon={MdClose} />
                                        <Div letterSpacing='1.1px' fontSize='14px' {...FONTS.normal[400]}>unfollow</Div>
                                    </Flex>
                            } />
                    }
                </Flex>
                <HStack w='100%' letterSpacing={'1.1px'} alignItems={'start'} wordBreak='break-word' textAlign={'left'}>
                    <Span variant={VARIANT_KEYS.color.color_gray757575_grayC4C4C4}>{data.message}</Span>
                </HStack>
                {
                    data.file &&
                    data.fileType === POST_FILE_TYPES.pdfType &&
                    <Div cursor={'pointer'} onMouseMove={() => setIsOnPdf(true)} onMouseOut={() => setIsOnPdf(false)} position={'relative'} w={'100%'}>
                        {
                            (isOnPdf || !isLargerThan550) &&
                            <Div zIndex={1} position={'absolute'} top={'1px'} right={'14px'}>
                                <TooltipIcon
                                    icon={
                                        <ExternalIcon
                                            onClick={() => window.location = `${STATIC_URL}/${data.file}`}
                                            styling={{ width: '22px', height: '22px' }}
                                            icon={MdCloudDownload} />
                                    }
                                    message={'Download me'}
                                    color={'white'}
                                    bg={COLOR_NAMES.gray666666} />
                            </Div>
                        }
                        <Flex
                            filter='auto'
                            brightness={isOnPdf ? '60%' : '100%'}
                            onClick={openPdfInNewWindow}
                            bg={COLOR_NAMES.oragneFFB81C}
                            h='316px'
                            justifyContent={'center'}
                            alignItems={'center'}
                            direction={'colum'}
                            w='100%' >
                            <PdfViewer
                                pdf={data.file}
                                heightOfPdfAsNumber={316}
                                showfPageNumbers={false}
                                isLocal={false} />
                        </Flex>
                    </Div>
                }

                {
                    data.file &&
                    data.fileType === POST_FILE_TYPES.imageType &&
                    <Div w='100%'>
                        <Image
                            textAlign={'start'}
                            borderRadius={'5px'}
                            objectFit='cover'
                            h='316px'
                            w='100%'
                            src={`${STATIC_URL}/${data.file}`}
                            alt='No image' />
                    </Div>
                }

                {
                    data.file &&
                    data.fileType === POST_FILE_TYPES.videoType &&
                    <Div h={'316px'} w={'100%'}>
                        <VideoViewer src={`${STATIC_URL}/${data.file}`} />
                    </Div>
                }


                {!useLikeInfo &&
                    <Div w='100%'>
                        <ActionBarPost commentsCount={commentsCount} commentModeHandle={commentModeHandle} data={data} />
                    </Div>
                }
            </VStack>
            {commentMode === COMMENT_MODE.WRITE && isCommentsShown &&
                <VStack
                    alignItems={'start'}
                    spacing={4}
                    mt={'30px'}
                    h='100%'
                    w='100%'
                    pl={media.isLargerThan750 ? '40px' : '16px'}
                    pr={media.isLargerThan750 ? '40px' : '16px'}>
                    <Flex w='100%'>
                        {authUser && <Avatar name={authUser.name} bg={authUser.img_url ? 'none' : authUser.avatar_color} src={authUser.img_url ? `${STATIC_URL}/${authUser.img_url}` : ''} size={'md'} />}
                        <Textarea
                            h={'84px'}
                            ml={'10px'}
                            value={commentMessage}
                            onFocus={handleOnFocusTextarea}
                            onChange={handleOnChangeTextarea}
                            fontFamily={FONT_FAMILY_NAME}
                            fontSize={'14px'}
                            variant={VARIANT_KEYS.TEXTAREA.PRIMARY}
                            placeholder={user.isMutedInGroup ? 'You cannot write comments when you are muted.' : 'Add your comment'}
                            _placeholder={user.isMutedInGroup ? { color: COLOR_NAMES.redDE4946 } : null}
                            disabled={user.isMutedInGroup} />
                    </Flex>
                    {isTextareaActive && commentMessage &&
                        <Div>
                            <WrapperButton
                                styling={{
                                    variant: VARIANT_KEYS.bgAndColor.bg_oragneFDB71A_oragneFFB81C_color_black141414_black141414,
                                    fontSize: '22px',
                                    h: '38px',
                                    w: '100px',
                                    ml: '60px',
                                    fontWeight: FONTS.normal[700].fontWeight,
                                    isDisabled: user.isMuted || !configurations[CONFIGURATION_KEYS.comment].isActive || (groupId && !configurations[CONFIGURATION_KEYS.groupComment].isActive),
                                    _hover: {},
                                }}
                                message={'Post'}
                                onClickHandle={addComment} />
                        </Div>}
                </VStack>
            }
            {isCommentsShown
                ? <Div mt='30px' w='100%'>
                    <CommentsList
                        handleMode={handleMode}
                        handleCommentChange={handleCommentChange}
                        handleDeleteComment={handleDeleteComment}
                        onClickReport={handleReportComment}
                        userGroupRole={currentUserRole}
                        data={comments}
                        userGroupMuteStatus={user.isMutedInGroup} />
                    {hasMoreComments
                        ? <HStack cursor={'pointer'} mt='10px' justifyContent={'center'} onClick={loadMoreComments}>
                            <Div>Load mode</Div>
                        </HStack>
                        : null
                    }
                </Div>
                : null
            }
        </Div>
    )
}

export default Post;
