import axios from 'axios';
import { STATIC_URL } from '../shared/constants';

class FileService {
    async getFileAsBlobFromS3(fileName) {
        return axios.get(`${STATIC_URL}/${fileName}`, { responseType: 'blob' })
    }
}

const groupService = new FileService();

export default groupService;
