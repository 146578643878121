import { mode } from "@chakra-ui/theme-tools";
import { COLOR_NAMES } from '../../shared/constants';

const Divider = {
    baseStyle: (props) => ({
        borderColor: mode(COLOR_NAMES.grayC4C4C4, COLOR_NAMES.grayC4C4C4)(props)
    }),
    defaultProps: {},
}

export default Divider;
