import React from 'react';
import { useRadioGroup, HStack, VStack, Avatar } from '@chakra-ui/react';
import ColorfulRadioButton from '../html-elements/colorful-radio-button.component';
import Div from '../html-elements/div.component';
import { PROFILE_BACKGROUND_COLORS } from '../../shared/constants';

function UserAvatarWithCredentials({ name, stylingAvatar, sizeCredentials, handleColorChange, onClick }) {
    const { getRadioProps } = useRadioGroup({
        onChange: handleColorChange,
    })

    return (
        <VStack>
            <Div onClick={onClick}>
                <Avatar name={name} {...stylingAvatar} />
            </Div>
            <HStack>
                {
                    PROFILE_BACKGROUND_COLORS.map(color => {
                        return (
                            <ColorfulRadioButton
                                key={color}
                                color={color}
                                size={sizeCredentials}
                                {...getRadioProps({ value: color })}
                            />)
                    })
                }
            </HStack>
        </VStack>
    )
}

export default UserAvatarWithCredentials;
