import React, { useEffect, useState, Fragment } from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import { VStack } from '@chakra-ui/react'
import Div from '../components/html-elements/div.component';
import Span from '../components/html-elements/span.component';
import Slider from '../components/shared/slider.component';
import DashboardCard from '../components/cards/dashboard-card.component';
import DefaultContainer from '../components/containers/default-container.component';
import PostsFeed from '../components/posts/posts-feed';
import useKnowMeMediaQuery from '../shared/hooks/media-query.hook';
import useAuth from '../shared/hooks/auth.hook';
import statisticsService from '../services/statistics.service';
import postService from '../services/post.service';
import { MEDIA, VARIANT_KEYS } from '../shared/constants';

function HomePage({ searchText, handleSetSearchText }) {
    const media = useKnowMeMediaQuery();
    const [isLargerThan1250] = useMediaQuery(MEDIA.MIN_WIDTH_1250_PX);
    const { user } = useAuth();
    const [statistics, setStatistics] = useState({});

    const getContainerWidth = () => {
        if (isLargerThan1250) {
            return '70vw'
        } else if (media.isLargerThan750) {
            return '75vw'
        }

        return '100vw';
    }
    const initialData = async () => {
        try {
            const { data } = await statisticsService.getDashboardStatistics();
            if (data.isSuccess) {
                setStatistics(data.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => { initialData() }, [])

    const containerStyling = {
        borderRadius: '4px',
        bg: 'transperant',
        w: getContainerWidth(),
        pr: '10px',
        pl: '10px'
    }

    const outerContainerDashboardCard = {
        pl: isLargerThan1250 ? '24px' : '7px',
        pb: isLargerThan1250 ? '0px' : '10px',
        pr: isLargerThan1250 ? '24px' : '7px',
        pt: isLargerThan1250 ? '0px' : '10px',
        borderRadius: '4px',
        variant: media.bgColorPage,
        h: isLargerThan1250 ? '84px' : '86px',
        w: '100%'
    }

    const items = [
        <DashboardCard
            outerContainer={outerContainerDashboardCard}
            fontSizeMessage={isLargerThan1250 ? '20px' : '12px'}
            message={'👨 Colleagues'}
            heigthCountMessage={'28px'}
            fontSizeCountMessage={isLargerThan1250 ? '45px' : '30px'}
            widthCountMessage={isLargerThan1250 ? '53px' : '32px'}
            countMessage={statistics.registeredUsersCount}
            isVertical={isLargerThan1250 ? false : true} />,
        <DashboardCard
            outerContainer={outerContainerDashboardCard}
            fontSizeMessage={isLargerThan1250 ? '20px' : '12px'}
            message={`😎 ${user.job}`}
            heigthCountMessage={'28px'}
            fontSizeCountMessage={isLargerThan1250 ? '45px' : '30px'}
            widthCountMessage={isLargerThan1250 ? '53px' : '32px'}
            countMessage={statistics.usersWithMyPositionCount}
            isVertical={isLargerThan1250 ? false : true} />,
        <DashboardCard
            outerContainer={outerContainerDashboardCard}
            fontSizeMessage={isLargerThan1250 ? '20px' : '12px'}
            message={'💼 Positions'}
            heigthCountMessage={'28px'}
            fontSizeCountMessage={isLargerThan1250 ? '45px' : '30px'}
            widthCountMessage={isLargerThan1250 ? '53px' : '32px'}
            countMessage={statistics.registedJobPositionsCount}
            isVertical={isLargerThan1250 ? false : true} />,
        // <DashboardCard
        //     outerContainer={outerContainerDashboardCard}
        //     fontSizeMessage={isLargerThan1250 ? '16px' : '12px'}
        //     message={'😎 Junior backend developers'}
        //     heigthCountMessage={'28px'}
        //     fontSizeCountMessage={isLargerThan1250 ? '45px' : '30px'}
        //     widthCountMessage={isLargerThan1250 ? '53px' : '32px'}
        //     countMessage={25}
        //     isVertical={isLargerThan1250 ? false : true} />,
    ];
    return (
        <Fragment>
            {/* <ThemeMode /> */}
            <VStack spacing={media.isLargerThan750 ? 10 : 5}>
                {media.isLargerThan750
                    ? <Div pl='24px' textAlign='start' w='100%' fontSize='20px'>
                        👋 Hello {user && user.name && <Span variant={VARIANT_KEYS.color.color_gray424242_orangeFFB81C}>{user.name}</Span>}
                    </Div>
                    : null
                }
                <Slider
                    containerStyling={containerStyling}
                    spaceBetween={media.isLargerThan750 ? 30 : 5}
                    slidesPerView={3}
                    items={items} />

                <DefaultContainer media={media}>
                    <PostsFeed getPostsFn={postService.getHomePostsAsync} searchText={searchText} handleSetSearchText={handleSetSearchText} />
                </DefaultContainer>
            </VStack>
        </Fragment>
    )
}

export default HomePage;
