import React from 'react';
import {
    Menu,
    MenuItem,
    MenuList,
    MenuButton,
    useColorModeValue,
} from '@chakra-ui/react';
import Div from '../html-elements/div.component';
import ExternalIcon from '../icons/external-icon.component';
import { COLOR_NAMES } from '../../shared/constants';
import { BiDotsHorizontalRounded } from 'react-icons/bi';

function ControlMenuDekstop({
    menuButton = <ExternalIcon styling={{ fontSize: '26px' }} icon={BiDotsHorizontalRounded} />,
    isLargerThan550,
    closeOnSelect,
    h,
    children }) {
    const bgColor = useColorModeValue('white', COLOR_NAMES.black161717);
    return (
        <Div>
            <Menu gutter={-5}>
                <MenuButton
                    as={'div'}
                    p='0px'
                    h='14px'
                    bg={'transparent'}>
                    {menuButton}
                </MenuButton>
                <MenuList
                    h={h}
                    w={isLargerThan550 ? '300px' : '100vw'}
                    _hover={{}}
                    bg={bgColor}
                    position={'absolute'}
                    right={isLargerThan550 ? '-28px' : '-85px'}
                    bottom={isLargerThan550 ? {} : '0'}>
                    <MenuItem
                        h='100%'
                        w={'100%'}
                        _active={{ bg: 'transparent' }}
                        _hover={{ bg: 'transparent' }}
                        _focus={{}}
                        borderRadius='10px'
                        closeOnSelect={closeOnSelect}>
                        {children}
                    </MenuItem>
                </MenuList>
            </Menu>
        </Div>
    )
}

export default ControlMenuDekstop;
