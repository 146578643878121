import React, { Fragment } from 'react';
import { Field } from 'formik';
import { FormControl, FormLabel } from '@chakra-ui/react';
import Div from './div.component';
import { FONT_FAMILY_NAME } from '../../shared/constants';

function Input(
    {
        element,
        name,
        type = 'text',
        label,
        placeholder,
        isRequired,
        isDisabled,
        isReadOnly,
        inputStyling,
        errorStyling,
        labelStyling,
        formikProps
    }) {
    return (
        <Fragment>
            <FormControl
                isReadOnly={isReadOnly}
                isDisabled={isDisabled}
                isInvalid={formikProps.errors[name] && formikProps.touched[name]}
                isRequired={isRequired}>
                {label && <FormLabel fontFamily={FONT_FAMILY_NAME} fontSize={'14px'} {...labelStyling} htmlFor={name}>{label}</FormLabel>}
                <Field
                    id={name}
                    as={element}
                    placeholder={placeholder}
                    type={type}
                    name={name}
                    fontFamily={FONT_FAMILY_NAME}
                    {...inputStyling}
                    sx={formikProps.errors[name] && formikProps.touched[name] && { border: '2px solid #FC8181' }}
                />
                {!formikProps.isValid[name] &&
                    formikProps.touched[name] &&
                    formikProps.errors[name] && (
                        <Div
                            fontFamily={FONT_FAMILY_NAME}
                            marginTop='1px'
                            fontSize='12px'
                            textAlign='start'
                            color='red.300' {...errorStyling} >
                            {formikProps.errors[name]}
                        </Div>
                    )}
            </FormControl>
        </Fragment>
    )
}

export default Input;
