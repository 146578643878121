import React, { useState, useEffect } from 'react';
import ConfigurationsContext from './configurations.context';
import toastService from '../../services/toast.service';
import socketHelper from '../utils/socket.helper';
import { CONFIGURATIONS, CONFIGURATION_KEYS, SOCKET_EVENTS } from '../constants';

const ConfigurationsProvider = ({ children }) => {
    const [configurations, setConfigurations] = useState({ ...CONFIGURATIONS });
    useEffect(() => {
        socketHelper.connect();
        socketHelper.addEventToQueue(
            SOCKET_EVENTS.INITIAL_CONFIGURATIONS,
            ({ initialConfigurations }) => { setConfigurations({ ...initialConfigurations }); },
        );

        socketHelper.addEventToQueue(SOCKET_EVENTS.CONFIGURATION_CHANGE, ({ key, isActive }) => {
            setConfigurations(prev => { return { ...prev, [CONFIGURATION_KEYS[key]]: { ...prev[CONFIGURATION_KEYS[key]], isActive: isActive } } });
            if (!isActive) { toastService.warning(`${configurations[CONFIGURATION_KEYS[key]].messageUnavailable}`) }
        });
        socketHelper.registerAllEvents();
        const timeout = setTimeout(() => { socketHelper.emit(SOCKET_EVENTS.INITIAL_CONFIGURATIONS) }, 2000);
        
        return () => {
            clearTimeout(timeout);
        }
    }, []);

    const value = {
        configurations: { ...configurations }
    };

    return (
        <ConfigurationsContext.Provider value={value}>
            {children}
        </ConfigurationsContext.Provider>
    )
}

export default ConfigurationsProvider;
