import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Image, HStack, VStack, Checkbox } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import Yup from '../shared/yup';
import Div from '../components/html-elements/div.component';
import WrapperButton from '../components/buttons/wrapper-button.component';
import FormDropDown from '../components/html-elements/form-drop-down.component';
import FormInput from '../components/html-elements/form-input.component';
import FormPhoneInput from '../components/inputs/form-phone-input.component';
import useAuth from '../shared/hooks/auth.hook';
import useConfigurations from '../shared/hooks/configurations.hook';
import authService from '../services/auth.service';
import statisticsService from '../services/statistics.service';
import toastService from '../services/toast.service';
import {
  PROFILE_BACKGROUND_COLORS,
  COLOR_NAMES,
  ROUTES,
  FONTS,
  VARIANT_KEYS,
  JOB_POSITIONS,
  CONFIGURATION_KEYS,
  GDPR_LINK,
} from '../shared/constants';
import { getRandomElementFromArr } from '../shared/utils/validators.utils';
import imageRegister from '../static/images/imageRegister.svg';
import personImg from '../static/images/person.svg';

const SignupPage = () => {
  const { configurations } = useConfigurations();
  const history = useNavigate();
  const { updateEployeeData } = useAuth();
  const [checkboxValue, setCheckboxValue] = useState(false);

  useEffect(() => {
    if (!configurations[CONFIGURATION_KEYS.registration].isActive) {
      toastService.warning(configurations[CONFIGURATION_KEYS.registration].messageUnavailable);
    }
  }, [configurations]);

  const SignupSchema = Yup.object().shape({
    phone: Yup
      .string()
      .isPhoneValid('Enter a valid phone number')
      .max(11, 'Phone number should be a maximum of 11 digits')
      .required('Phone number is required'),
    country: Yup.string().isCountryValid('0 or 2+ symbols, only letters, 1st uppercase'),
    city: Yup.string().isCityValid('0 or 2+ symbols, only letters, 1st uppercase'),
  });

  const register = async (params) => {
    try {
      const { data } = await authService.register(params);
      if (!data.isSuccess) {
        history(ROUTES.SIGNUP);
      }

      updateEployeeData(data.data);
      history(ROUTES.HOME);

      await statisticsService.setUserActive();
    } catch (error) {
      toastService.error(error.response.data.errorMessage);
    }
  };

  const handleRegisterSubmit = async (values) => {
    if (!checkboxValue) {
      toastService.warning('Please, read the company GDPR policies before registering.', 3000);
      return;
    }

    await register({
      ...values,
      policies: checkboxValue,
      avatar_color: getRandomElementFromArr(PROFILE_BACKGROUND_COLORS),
    });
  };

  const readGDPR = () => {
    if (!checkboxValue) {
      window.open(GDPR_LINK, '_blank');
    }
  }

  return (
    <Div h={'734px'} sm={'340px'}>
      <Div position='relative' bottom='50px'>
        <Div height={'100px'}>
          <Image w='100%' src={imageRegister} />
        </Div>
        <VStack w={{ sm: '340px' }} alignItems={'flex-start'}>
          <Image w='58px' h='58px' src={personImg} alt='fingerprint' />
        </VStack>
      </Div>
      <Formik
        initialValues={{
          name: '',
          job: 'Backend Developer',
          city: '',
          country: '',
          countryCode: '+359',
          phone: '',
        }}
        validationSchema={SignupSchema}
        onSubmit={handleRegisterSubmit}>
        {(props) => (
          <Form style={{ height: '70%' }}>
            <Flex
              justifyContent='space-between'
              direction='column'
              gap='10px'
              w={{ sm: '340px' }}
              h='100%'>
              <FormPhoneInput
                formikFieldNames={{ number: 'phone', code: 'countryCode' }}
                formikProps={props}
                isRequired={true} />
              <FormDropDown
                styling={{ variant: VARIANT_KEYS.SELECT.PRIMARY }}
                label={'Job position'}
                name={'job'}
                kvp={JOB_POSITIONS}
                formikProps={props} />
              <Div
                color={COLOR_NAMES.grayAFAFAF}
                h='28px'
                fontWeight={FONTS.normal[400]}
                fontSize='10px'
                lineHeight='28px'
                alignSelf='start'>
                Optional
              </Div>
              <HStack>
                <FormInput
                  name={'country'}
                  label={'Country'}
                  placeholder={'Country of residence'}
                  isRequired={false}
                  inputStyling={{ variant: VARIANT_KEYS.INPUT.PRIMARY }}
                  formikProps={props} />
                <FormInput
                  name={'city'}
                  label={'City/State'}
                  placeholder={'City/State'}
                  isRequired={false}
                  inputStyling={{ variant: VARIANT_KEYS.INPUT.PRIMARY }}
                  formikProps={props} />
              </HStack>
              <Flex w={'100%'} mt={'40px'} justifyContent={'space-between'} h={'40px'} alignItems={'center'} >
                <Div onClick={readGDPR}>
                  <Checkbox
                    name={'gdpr'}
                    size={'lg'}
                    colorScheme='orange'
                    isInvalid
                    isChecked={checkboxValue}
                    onChange={(e) => setCheckboxValue(e.target.checked)}>
                    <Div fontSize={'14px'} mt={'12px'} textAlign={'left'} color={COLOR_NAMES.blue2097F6}>By registering you agree with the company GDPR policies</Div>
                  </Checkbox>
                </Div>
              </Flex>
              <WrapperButton
                styling={{
                  variant: VARIANT_KEYS.bgAndColor.bg_oragneFDB71A_oragneFFB81C_color_black141414_black141414,
                  fontSize: '22px',
                  h: '48px',
                  fontWeight: FONTS.normal[600],
                  w: '100%',
                  mt: 'auto',
                  disabled: !configurations[CONFIGURATION_KEYS.registration].isActive,
                }}
                message={'Register'}
                type={'submit'} />
            </Flex>
          </Form>
        )}
      </Formik>
    </Div>
  );
};

export default SignupPage;
