import { InputGroup } from "@chakra-ui/react";
import FormInput from "../html-elements/form-input.component";
import FormPhoneCodeSelect from "./form-phone-code-select.component";
import { VARIANT_KEYS } from "../../shared/constants";

const FormPhoneInput = ({ formikFieldNames, formikProps, isRequired }) => {
  return (
    <InputGroup>
      <FormPhoneCodeSelect formikProps={formikProps} name={formikFieldNames.code} />
      <FormInput
        inputStyling={{ pl: '100px', variant: VARIANT_KEYS.INPUT.PRIMARY }}
        type='tel'
        placeholder='Phone number'
        value={formikProps.values[formikFieldNames.number]}
        name={formikFieldNames.number}
        formikProps={formikProps}
        label={'Phone'}
        isRequired={isRequired} />
    </InputGroup>
  );
};
export default FormPhoneInput;
