import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Post from '../components/posts/post.component';
import Div from '../components/html-elements/div.component';
import postService from '../services/post.service';
import userService from '../services/user.service';
import { ROUTES } from '../shared/constants';
import toastService from '../services/toast.service';

function CommentPostPage() {
    const params = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();

    const [post, setPost] = useState(null);
    const [isFollowedByMe, setIsFollowedByMe] = useState(false);

    const toggleFollow = async (userId) => {
        try {
            if (!isFollowedByMe) {
                await userService.follow(userId);
                setIsFollowedByMe(true);
            } else {
                await userService.unfollow(userId);
                setIsFollowedByMe(false);
            }
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }  
    };

    const initialData = async () => {
        try {
            const postResponse = await postService.getByUuid(params.uuid);
            const postData = postResponse.data.data;
            if (!postData) {
                navigate(ROUTES.HOME);
                return;
            }

            const ifUserFollowUserResponse = await userService.isFollowByMe(postData.author);
            setIsFollowedByMe(ifUserFollowUserResponse.data.data);

            setPost(postData);
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleDeletePost = async (uuid) => {
        try {
            await postService.deleteAsync(uuid);

            navigate(ROUTES.HOME);
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    const handleReporPost = async (reason, postId) => {
        try {
            await postService.reportAsync(postId, reason);

            navigate(ROUTES.HOME);
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    useEffect(() => {
        if (!params || !params.uuid) {
            navigate(ROUTES.HOME);
        }

        initialData();
    }, [])

    return (
        <Div minH='725px' w={{ sm: '370px', md: '470px' }}>
            {post &&
                <Post
                    data={post}
                    toggleFollow={toggleFollow}
                    isFollowedByMe={isFollowedByMe}
                    onClickReport={handleReporPost}
                    handleDeletePost={handleDeletePost}
                    repostMode={true}
                    specificCommentIdToDisplay={state?.commentId || null} />
            }
        </Div>
    )
}

export default CommentPostPage;
