import axios from './axios.service';

const DEFAULT = '/notification';
const MARK_SEEN = `${DEFAULT}/mark-seen`;
const MARK_CLICKED = `${DEFAULT}/mark-clicked`;
const GET_SENDER_DATA = `${DEFAULT}/senders-data`;
const MUTE_NOTIFICATIONS_FROM_USER = `${DEFAULT}/mute-notifications-from-user`;
const GET_SETTINGS = `${DEFAULT}/settings`;
const EDIT_SETTINGS = `${DEFAULT}/settings-edit`;

class NotificationService {
    async getAllNotifications(page = 1, limit = 10, options) {
        return axios.get(DEFAULT, { ...options, params: { page, limit } });
    }

    async seenNotifications() {
        return axios.post(MARK_SEEN, {});
    }

    async getSenderData(senderId) {
        return axios.get(GET_SENDER_DATA, { params: { senderId } });
    }

    async deleteNotification(notificationId) {
        return axios.delete(`${DEFAULT}/${notificationId}`)
    }

    async muteNotificationsFromUser(userId) {
        return axios.post(`${MUTE_NOTIFICATIONS_FROM_USER}`, { userId });
    }

    async clickedNotification(idsToMark) {
        return axios.post(`${MARK_CLICKED}`, { ids: idsToMark });
    }

    async getSettings() {
        return axios.get(`${GET_SETTINGS}`);
    }

    async editSetting(payload) {
        return axios.post(`${EDIT_SETTINGS}`,payload);
    }
}

const notificationService = new NotificationService();

export default notificationService;
