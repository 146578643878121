import React from 'react';
import WrapperButton from './wrapper-button.component';
import { Link as ReactLink } from "react-router-dom";
import { Link, useColorModeValue } from '@chakra-ui/react';
import { COLOR_NAMES } from '../../shared/constants';

function WrapperButtonNavLink({ to, isActive, message, styling }) {
    const { variant } = styling;
    const color = useColorModeValue(COLOR_NAMES.gold906811, COLOR_NAMES.oragneFFB81C);

    return (
        <Link
            as={ReactLink}
            to={to}
            variant={variant}
            color={isActive ? color : null}>
            <WrapperButton message={message} styling={styling} />
        </ Link>
    )
}

export default WrapperButtonNavLink;
