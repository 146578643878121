import { mode } from "@chakra-ui/theme-tools";
import { COLOR_NAMES } from '../../shared/constants';

export const Select = {
  baseStyle: {
    field: {},
  },
  sizes: {},
  variants: {
    primary: (props) => ({
      field: {
        border: mode('2px solid #666666', '2px solid #666666')(props),
        bg: mode('white', COLOR_NAMES.gray666666)(props),
        color: mode(COLOR_NAMES.gray757575, 'white')(props),
        _placeholder: {
          color: mode('black', 'white')(props),
          fontSize: '13px',
          lineHeight: '28px',
        },
        borderRadius: '6px',
        ':hover': { bg: mode('rgba(255, 255, 255, 0.09)', 'rgba(255, 255, 255, 0.09)')(props) },
        ':focus': {},
        ':focus-visible': { bg: 'transparent' },
      }
    }),
  },
  defaultProps: {},
}

export default Select;
