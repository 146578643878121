import * as Yup from 'yup';
import {
    isNameValid,
    isPhoneValid,
    containsLettersAndUnderscoreOnly,
    containsLettersOnly,
    isEmailValid,
    hasSpecialCharactersInPassword,
} from './utils/validators.utils';

Yup.addMethod(Yup.string, 'isNameValid', function (message) {
    return this.test('isNameValid', message, (val) => isNameValid(val));
});

Yup.addMethod(Yup.string, 'isPhoneValid', function (message) {
    return this.test('isPhoneValid', message, (val) => isPhoneValid(val));
});

Yup.addMethod(Yup.string, 'isJobValid', function (message) {
    return this.test('isJobValid', message, (val) => containsLettersAndUnderscoreOnly(val));
});

Yup.addMethod(Yup.string, 'isCountryValid', function (message) {
    return this.test('isCountryValid', message, (val) => {
        if (!!val) {
            return containsLettersOnly(val);
        }

        return true;
    });
});

Yup.addMethod(Yup.string, 'isCityValid', function (message) {
    return this.test('isCityValid', message, (val) => {
        if (!!val) {
            return containsLettersOnly(val);
        }

        return true;
    });
});

Yup.addMethod(Yup.string, 'isEmailValid', function (message) {
    return this.test('isEmailValid', message, (val) => isEmailValid(val));
});

Yup.addMethod(Yup.string, 'isValidPassword', function (message) {
    return this.test('isValidPassword', message, (val) => hasSpecialCharactersInPassword(val));
});

export default Yup;
