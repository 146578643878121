class ImageService {
    async convertFromBase64ToFileAsync(base64, fileName) {
        const data = await fetch(base64);
        return new File([await data.blob()], fileName, { type: 'image/png' });
    }
}

const imageService = new ImageService();

export default imageService;
