import React from 'react';
import { Tooltip } from '@chakra-ui/react';
import { FONT_FAMILY_NAME } from '../../shared/constants';

function TooltipIcon({ icon, message, color, bg }) {
    return (
        <Tooltip fontFamily={FONT_FAMILY_NAME} bg={bg} color={color} label={message}>
            <span>
                {icon}
            </span>
        </Tooltip>
    )
}

export default TooltipIcon;
