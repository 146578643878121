import axios from './axios.service';

const DEFAULT = '/comments';
const REPORT = `${DEFAULT}/report`;
const COMMENTS_BY_POST = `${DEFAULT}/get-by-post`;
const DELETE_FROM_GROUP = `${DEFAULT}/delete-from-group`;
const REPLIES_BY_COMMENT = `${DEFAULT}/replies-by-comment`;
const USERS_WHO_LIKED_COMMENT = `${DEFAULT}/user-likes-comment`;

class CommentService {
    async commentsByPostId(postId, page, limit = 5) {
        return axios.get(
            `${COMMENTS_BY_POST}/${postId}`,
            {
                params: {
                    page: page,
                    limit: limit,
                },
            });
    }

    async reportAsync(id, postId, reason) {
        return axios.post(`${REPORT}/${id}`, { reason: reason, postId: postId });
    }

    async editAsync(id, message) {
        return axios.put(`${DEFAULT}/${id}`, { message: message });
    }

    async deleteAsync(id) {
        return axios.delete(`${DEFAULT}/${id}`);
    }

    async deleteFromGroupAsync(commentId, groupId) {
        return axios.delete(`${DELETE_FROM_GROUP}`, { data: { id: commentId, groupId: groupId } });
    }

    async getCommentByIdAsync(commentId) {
        return axios.get(`${DEFAULT}/${commentId}`);
    }

    async getRepliesByCommentId(commentId, page, limit = 5) {
        return axios.get(`${REPLIES_BY_COMMENT}/${commentId}`,
            {
                params: {
                    page: page,
                    limit: limit,
                },
            });
    }

    async changeCommentLikes(payload, id) {
        return axios.post(`${DEFAULT}/${id}/likes`, payload);
    }

    async getUsersWhoLikesCommentAsync(id, page, limit, options) {
        return axios.get(
            `${USERS_WHO_LIKED_COMMENT}/${id}`,
            {
                ...options,
                params: {
                    page: page,
                    limit: limit,
                },
            });
    }
}

const commentService = new CommentService();

export default commentService;
