import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import authService from '../services/auth.service';
import statisticsService from '../services/statistics.service';
import useQuery from '../shared/hooks/use-query.hook';
import useAuth from '../shared/hooks/auth.hook';
import { ROUTES } from '../shared/constants';

function AuthenticationPage() {
    const query = useQuery();
    const navigate = useNavigate();
    const { login, logout } = useAuth();
    const history = useNavigate();
    const location = useLocation();

    const getTokensFromServer = async() => {
        const code = query.get('code');
        if (code) {
            const redirectUrl = `${window.location.origin}${ROUTES.AUTHENTICATION}`;
            const tokens = (await authService.getTokensByCodeAsync(code, redirectUrl)).data.data;
            const user = (await authService.getOrCreateUserByAccessTokenAsync(tokens.token)).data.data;
            login(user, tokens.token, tokens.refreshToken);

            if (user.isRegistrationFinished) {
                navigate(ROUTES.HOME);

                return;
            }

            navigate(ROUTES.SIGNUP);
        } else {
            await logout();

            navigate(ROUTES.DEFAULT);
        }
    }

    const loginUser = async () => {
        try {
            const refreshToken = authService.getRefreshToken();
            const token = authService.getToken();

            if (token && refreshToken) {
                try {
                    const data = await authService.validate({});
                    const user = data.data.employee;
                    if (!user || !user.isRegistrationFinished) {
                        login(data.data.employee, token, refreshToken);
                        history(ROUTES.SIGNUP);

                        return;
                    }

                    login(data.data.employee, token, refreshToken);
                    history(
                        location.state?.redirectTo
                            ? location.state.redirectTo === ROUTES.SIGNUP
                                ? ROUTES.HOME
                                : location.state.redirectTo
                            : ROUTES.HOME,
                        { replace: true }
                    );
                    await statisticsService.setUserActive();
                } catch (error) {
                    await getTokensFromServer();
                }

                return;
            }

            await getTokensFromServer();
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        loginUser();
    }, [])

    return null;
}

export default AuthenticationPage;
