import React from 'react';
import { Avatar, HStack, VStack } from '@chakra-ui/react';
import Span from '../html-elements/span.component';
import Div from '../html-elements/div.component';
import { FONTS, STATIC_URL } from '../../shared/constants';

function UserSearchItem({ user }) {
    const { img_url, name, job, country, city, avatar_color } = user;

    return (
        <HStack>
            <Avatar name={name} bg={img_url ? 'none' : avatar_color}
                src={img_url ? `${STATIC_URL}/${img_url}` : ''} size={'lg'} />
            <Div>
                <VStack spacing={2}>
                    <VStack w='100%' textAlign={'start'} alignItems={'start'} spacing={0}>
                        <Span height='19px' lineHeight='19px' {...FONTS.normal[400]} fontSize='16px' color='white'>{name}</Span>
                        <Span height='14px' lineHeight='14px' {...FONTS.normal[300]} color='#C4C4C4' fontSize='12px'>{job}</Span>
                    </VStack>
                    <VStack w='100%' textAlign={'start'} alignItems={'start'} spacing={0}>
                        <Span height='14px' lineHeight='14px' {...FONTS.normal[300]} fontSize='12px' color='#FDB71A'>@{name}</Span>
                        <Span height='14px' lineHeight='14px' {...FONTS.normal[300]} fontSize='12px' color='#FDB71A'>{country}, {city}</Span>
                    </VStack>
                </VStack>
            </Div>
        </HStack>
    )
}

export default UserSearchItem;
