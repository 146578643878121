import React, { useEffect, useState } from 'react';
import AuthContext from './auth.context';
import authService from '../../services/auth.service';

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [timeoutValue, setTimeoutValue] = useState(0);

    const updateEployeeData = (user) => {
        setUser(user);
        setIsLoggedIn(authService.isLoggedIn());
    }

    const login = (user, token, refreshToken) => {
        setUser(user);
        authService.setToken(token);
        authService.setRefreshToken(refreshToken);
        setIsLoggedIn(authService.isLoggedIn());
    }

    const logout = async () => {
        try {
            await authService.logout();
            setUser({});
            setIsLoggedIn(false);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const token = authService.getToken();
        if (!token) {
            return;
        }

        if (!user || !user.email) {
            return;
        }

        const timeout = authService.getJwtExpirationTimeOneMinuteBeforeExpire();
        const getRefreshToken = setTimeout(async () => {
            try {
                const response = await authService.refreshTokenAsync();
                const accessToken = response.data.data;
                authService.setToken(accessToken);
                setTimeoutValue(authService.getJwtExpirationTimeOneMinuteBeforeExpire());
            } catch (error) { }
        }, timeout);

        return () => clearTimeout(getRefreshToken);
    }, [user, timeoutValue])

    const value = {
        user: { ...user },
        isLoggedIn: isLoggedIn,
        updateEployeeData: updateEployeeData,
        logout: logout,
        login: login,
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;
