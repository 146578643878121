import React, { Fragment } from 'react';
import Div from '../html-elements/div.component';
import Comment from './comment.component';
import useKnowMeMediaQuery from '../../shared/hooks/media-query.hook';

function CommentsList({ data, onClickReport, handleDeleteComment, handleCommentChange, handleMode, userGroupRole, userGroupMuteStatus }) {
    const media = useKnowMeMediaQuery();

    return (
        <Div pl={media.isLargerThan750 ? '40px' : '16px'} pr={media.isLargerThan750 ? '40px' : '16px'} w='100%'>
            {data.length
                ? <Fragment>
                    {data.map((d, i) => {
                        return (
                            i === 0
                                ? <Div key={`${d.id}_comment`}>
                                    <Comment
                                        handleMode={handleMode}
                                        handleCommentChange={handleCommentChange}
                                        handleDeleteComment={handleDeleteComment}
                                        onClickReport={onClickReport}
                                        userGroupRole={userGroupRole}
                                        data={d}
                                        userGroupMuteStatus={userGroupMuteStatus} />
                                </Div>
                                : <Div mt='35px' key={`${d.id}_comment`}>
                                    <Comment
                                        handleMode={handleMode}
                                        handleCommentChange={handleCommentChange}
                                        handleDeleteComment={handleDeleteComment}
                                        onClickReport={onClickReport}
                                        userGroupRole={userGroupRole}
                                        data={d}
                                        userGroupMuteStatus={userGroupMuteStatus} />
                                </Div>
                        )
                    })}
                </Fragment>
                : <Div fontSize='14px' letterSpacing='1.1px' mb='8px' textAlign='start' w='100%'>No comments available</Div>
            }
        </Div>
    )
}

export default CommentsList;