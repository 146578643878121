import React, { Fragment, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Flex, Center, VStack, useColorMode } from '@chakra-ui/react';
import Routing from './components/router/main-router.component';
import Div from './components/html-elements/div.component';
import TopNavigation from './components/navigation/top-navigation.component';
import TopMobileNavigation from './components/navigation/top-mobile-navigation.component';
import LeftNavigation from './components/navigation/left-navigation.component';
import BottomMobileNavigation from './components/navigation/bottom-mobile-navigation.component';
import DefaultContainer from './components/containers/default-container.component';
import LargeContainerComponent from './components/containers/large-container.component';
import useKnowMeMediaQuery from './shared/hooks/media-query.hook';
import useAuth from './shared/hooks/auth.hook';
import useRequestInterceptor from './shared/hooks/request-interceptors.hook';
import useResponseInterceptor from './shared/hooks/response-interceptor.hook';
import { VARIANT_KEYS, ROUTES } from './shared/constants';
import { debounce } from 'lodash';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  const media = useKnowMeMediaQuery();
  const location = useLocation();
  const path = location.pathname;
  const auth = useAuth();
  const { colorMode } = useColorMode();

  useRequestInterceptor();
  useResponseInterceptor();

  const [searchText, setSearchText] = useState('');

  const handleSetSearchText = (text) => {
    setSearchText(text)
  }

  const debounceSetSearchText = debounce(handleSetSearchText, 500);

  function isTopMobileNavigationShown() {
    const isShown = {
      [ROUTES.DEFAULT]: ROUTES.DEFAULT,
      [ROUTES.LOGIN]: ROUTES.LOGIN,
      [ROUTES.SIGNUP]: ROUTES.SIGNUP,
      [ROUTES.AUTHENTICATION]: ROUTES.AUTHENTICATION,
    }

    return !(!!isShown[path]);
  }

  function isLeftNavigationShown() {
    const isShown = {
      [ROUTES.DEFAULT]: ROUTES.DEFAULT,
      [ROUTES.LOGIN]: ROUTES.LOGIN,
      [ROUTES.SIGNUP]: ROUTES.SIGNUP,
      [ROUTES.AUTHENTICATION]: ROUTES.AUTHENTICATION,
    }

    return !(!!isShown[path]);
  }

  function isBottomNavigationShown() {
    const isShown = {
      [ROUTES.DEFAULT]: ROUTES.DEFAULT,
      [ROUTES.LOGIN]: ROUTES.LOGIN,
      [ROUTES.SIGNUP]: ROUTES.SIGNUP,
      [ROUTES.MY_PROFILE]: ROUTES.MY_PROFILE,
      [ROUTES.EDIT_PROFILE]: ROUTES.EDIT_PROFILE,
      [ROUTES.CREATE_POST]: ROUTES.CREATE_POST,
      [ROUTES.CONTACTS]: ROUTES.CONTACTS,
      [ROUTES.CREATE_GROUP]: ROUTES.CREATE_GROUP,
      [ROUTES.AUTHENTICATION]: ROUTES.AUTHENTICATION,
    }

    return !(!!isShown[path] ||
      path.startsWith(ROUTES.USER_PROFILE) ||
      path.startsWith(ROUTES.INVITE_MEMBERS) ||
      path.startsWith(ROUTES.EDIT_GROUP) ||
      path.startsWith(ROUTES.COMMENT_POST) ||
      path.startsWith(ROUTES.MEMBERS));
  }

  function isTopNavigationShown() {
    const isShown = {
      [ROUTES.DEFAULT]: ROUTES.DEFAULT,
      [ROUTES.LOGIN]: ROUTES.LOGIN,
      [ROUTES.SIGNUP]: ROUTES.SIGNUP,
      [ROUTES.AUTHENTICATION]: ROUTES.AUTHENTICATION,
    }

    return !(!!isShown[path]);
  }

  function getContainer() {
    if (path.startsWith(ROUTES.AUTHENTICATION)) {
      return <VStack h={'100%'} w='100%' pt='0px'><Div><Routing /></Div></VStack>
    }

    if (path === ROUTES.HOME) {
      return (
        <LargeContainerComponent media={media}>
          {!media.isLargerThan750 && isTopNavigationShown() &&
            <TopMobileNavigation media={media} isLargeContainer={true} handleSetSearchText={debounceSetSearchText} />}
          <Routing searchText={searchText} handleSetSearchText={debounceSetSearchText} />
          {!media.isLargerThan750 && isBottomNavigationShown() && <Div h='100px'></Div>}
          {!media.isLargerThan750 && isBottomNavigationShown() && <BottomMobileNavigation />}
        </LargeContainerComponent>
      )
    } else if (path === ROUTES.DEFAULT) {
      return <Center><Routing /></Center>;
    }

    return (
      <VStack h={'100%'} w='100%' pt='0px'>
        <DefaultContainer media={media}>
          {!media.isLargerThan750 && isTopMobileNavigationShown() && <TopMobileNavigation handleSetSearchText={debounceSetSearchText} />}
          <Routing />
          {!media.isLargerThan750 && isBottomNavigationShown() && <Div h='100px'></Div>}
          {!media.isLargerThan750 && isBottomNavigationShown() && <BottomMobileNavigation />}
        </DefaultContainer>
      </VStack>
    )
  }

  return (
    <Fragment>
      <ToastContainer
        position="top-center"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
        theme={colorMode} />
      <Container h='100vh' m='0px' p='0px' maxWidth='100%' maxW='container.xl'>
        <Flex h={auth.isLoggedIn ? {} : '100%'}>
          {
            media.isLargerThan750 &&
            isLeftNavigationShown() &&
            <Div variant={VARIANT_KEYS.bg.bg_grayE2E2E2_gray333333} position='sticky' top='0' justifyContent='start' w='200px' h='100%'><LeftNavigation /></Div>
          }
          <VStack spacing={10} w='100%' minH={'100vh'} >
            {
              media.isLargerThan750 &&
              isTopNavigationShown() &&
              <Div w='100%' variant={VARIANT_KEYS.bg.bg_grayE2E2E2_gray333333}>
                <Flex h='60px' alignItems={'center'} left={'200px'} justifyContent={'space-between'}>
                  <TopNavigation handleSetSearchText={debounceSetSearchText} />
                </Flex>
              </Div>
            }
            {getContainer()}
          </VStack>
        </Flex>
      </Container>
    </Fragment>
  );
}

export default App;
