import React from 'react';
import { Badge } from '@chakra-ui/react'

function NotificationBadge({ style, notificationsCount }) {
    const notificationNumber = Number(notificationsCount) > 99 ? '99+' : notificationsCount;

    return (
        <Badge
            variant='subtle'
            borderRadius='50%'
            display='flex'
            fontSize='10px'
            justifyContent='center'
            alignItems='center'
            position='absolute'
            zIndex='10'
            pointerevent='none'
            bgColor='red'
            w='20px'
            h='20px'
            {...style} >
            {notificationNumber}
        </Badge>
    )
}

export default NotificationBadge;