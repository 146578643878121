
import React, { Fragment } from 'react';
import { HStack, VStack, Flex, useMediaQuery, useDisclosure, Avatar } from '@chakra-ui/react';
import ControlMenuDekstop from '../shared/control-menu-dekstop.component';
import ControlMenuMobile from '../shared/control-menu-mobile.component';
import Div from '../html-elements/div.component';
import ExternalIcon from '../icons/external-icon.component';
import Dialog from '../shared/dialog.component';
import TooltipIcon from '../shared/tooltip-icon.component';
import WrapperButton from '../buttons/wrapper-button.component';
import Span from '../html-elements/span.component';
import { VARIANT_KEYS, FONTS, COLOR_NAMES, GROUP_ROLES, MEDIA, GROP_MEMBER_ACTIONS, STATIC_URL } from '../../shared/constants';
import { MdOutlineKeyboardArrowDown, MdNotInterested } from 'react-icons/md';
import { BsFillPersonPlusFill, BsFillPersonXFill } from 'react-icons/bs';
import { GoMute, GoUnmute } from 'react-icons/go';
import { AiOutlineCheck } from 'react-icons/ai';

function GroupMember({
    user,
    removeInvitation,
    removeMember,
    inviteAsAdmin,
    changeRoleFromAdminToMember,
    acceptMemberToGroup,
    rejectMemberFromGroup,
    userRole,
    toggleMuteMemberInGroup }) {
    const [isLargerThan550] = useMediaQuery(MEDIA.MIN_WIDTH_550_PX);
    const { isOpen: isDrrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
    const { isOpen: isRemoveOpen, onOpen: onRemoveOpen, onClose: onRemoveClose } = useDisclosure();
    const { isOpen: isRemoveInvitationOpen, onOpen: onRemoveInvitationOpen, onClose: onRemoveInvitationClose } = useDisclosure();

    const isInvitedAsAdmin = user.role === GROUP_ROLES.MEMBER && user.action === GROP_MEMBER_ACTIONS.INVITE_AS_ADMIN;
    const isInvitedAsMember = user.role === GROUP_ROLES.UNKNOWN && user.action === GROP_MEMBER_ACTIONS.INVITE_AS_MEMBER;
    const isAppliedToGroup = user.role === GROUP_ROLES.UNKNOWN && user.action === GROP_MEMBER_ACTIONS.APPLY;
    const isAdmin = user.role === GROUP_ROLES.ADMIN;
    const isOwner = user.role === GROUP_ROLES.OWNER;

    const getButtonMessage = () => {
        if (isInvitedAsMember) {
            return 'Invited';
        } else if (isAppliedToGroup) {
            return 'Applied';
        } else if (isAdmin) {
            return 'Admin';
        } else if (isOwner) {
            return 'Owner';
        }

        return 'Member';
    }

    const onRemoveCloseHandleMobile = () => {
        onRemoveClose();
        onDrawerClose();
    }

    const onRemoveInvitationCloseHandleMobile = () => {
        onRemoveInvitationClose();
        onDrawerClose();
    }

    const inviteAsAdminMobile = () => {
        inviteAsAdmin(user.id)
        onDrawerClose();
    }

    const getManinControlContent = () => {
        const muteMemberContent = <HStack onClick={() => { toggleMuteMemberInGroup(user.id, user.isMutedInGroup) }}>
            {
                user.isMutedInGroup
                    ? <Fragment>
                        <Div>
                            <ExternalIcon styling={{ w: '24px', h: '24px', mr: '6px' }} icon={GoUnmute} />
                        </Div>
                        <Div fontSize='14px'> Unmute</Div>
                    </Fragment>
                    : <Fragment>
                        <Div>
                            <ExternalIcon styling={{ w: '24px', h: '24px', mr: '6px' }} icon={GoMute} />
                        </Div>
                        <Div fontSize='14px'> Mute</Div>
                    </Fragment>
            }
        </HStack>

        const removeMemberContent = <HStack onClick={onRemoveOpen}>
            <Div> <ExternalIcon styling={{ w: '24px', h: '24px', mr: '6px' }} icon={BsFillPersonXFill} /></Div>
            <Div fontSize='14px'>Remove from group</Div>
            <Dialog
                isOpen={isRemoveOpen}
                onClose={isLargerThan550 ? onRemoveClose : onRemoveCloseHandleMobile}
                onAction={() => removeMember(user.id)}
                messageBody={`Are you shure you want to remove selected user`}
                messageHeader={`Remove user`} />
        </HStack>

        if (isInvitedAsMember) {
            return <Flex pl='20px' justifyContent={'space-around'} direction={'column'} h='100%'>
                <HStack onClick={onRemoveInvitationOpen}>
                    <Div fontSize='14px'>Remove invitation</Div>
                    <Dialog
                        isOpen={isRemoveInvitationOpen}
                        onClose={isLargerThan550 ? onRemoveInvitationClose : onRemoveInvitationCloseHandleMobile}
                        onAction={() => removeInvitation(user.id, GROP_MEMBER_ACTIONS.INVITE_AS_MEMBER)}
                        messageBody={`Are you shure you want to remove selected invitation`}
                        messageHeader={`Remove invitation`} />
                </HStack>
            </Flex>
        } else if (isAppliedToGroup) {
            return <Flex pl='20px' justifyContent={'space-around'} direction={'column'} h='100%'>
                <HStack onClick={() => acceptMemberToGroup(user.id)}>
                    <Div> <ExternalIcon styling={{ fontSize: '20px', mr: '6px', mt: '4px' }} icon={AiOutlineCheck} /></Div>
                    <Div>Accept member</Div>
                </HStack>
                <HStack onClick={() => rejectMemberFromGroup(user.id)} >
                    <Div> <ExternalIcon styling={{ fontSize: '20px', mr: '6px', mt: '4px' }} icon={MdNotInterested} /></Div>
                    <Div>Reject member</Div>
                </HStack>
            </Flex>
        } else if (isOwner) {
            return <Flex pl='20px' justifyContent={'space-around'} direction={'column'} h='100%'>
                <Div>You don`t have permissions</Div>
            </Flex>
        } else if (isAdmin) {
            return (userRole === GROUP_ROLES.OWNER
                ? <Flex pl='20px' justifyContent={'space-around'} direction={'column'} h='100%'>
                    {userRole === GROUP_ROLES.OWNER && <Div onClick={() => changeRoleFromAdminToMember(user.id)}>Make a member</Div>}
                    {removeMemberContent}
                </Flex>
                : <Flex pl='20px' justifyContent={'space-around'} direction={'column'} h='100%'>
                    <Div>You don`t have permissions</Div>
                </Flex>);
        } else if (isInvitedAsAdmin) {
            return (
                userRole === GROUP_ROLES.OWNER
                    ? (<Flex pl='20px' justifyContent={'space-around'} direction={'column'} h='100%'>
                        <HStack onClick={onRemoveInvitationOpen}>
                            <Div fontSize='14px'>Remove invitation for admin</Div>
                            <Dialog
                                isOpen={isRemoveInvitationOpen}
                                onClose={isLargerThan550 ? onRemoveInvitationClose : onRemoveInvitationCloseHandleMobile}
                                onAction={() => removeInvitation(user.id, GROP_MEMBER_ACTIONS.INVITE_AS_ADMIN)}
                                messageBody={`Are you shure you want to remove selected invitation`}
                                messageHeader={`Remove invitation`} />
                        </HStack>
                        {removeMemberContent}
                    </Flex>)
                    : <Flex pl='20px' justifyContent={'space-around'} direction={'column'} h='100%'>
                        <Div>You don`t have permissions</Div>
                    </Flex>
            )
        }

        return <Flex pl='20px' justifyContent={'space-around'} direction={'column'} h='100%'>
            {userRole === GROUP_ROLES.OWNER &&
                <HStack onClick={() => isLargerThan550 ? inviteAsAdmin(user.id) : inviteAsAdminMobile()}>
                    <Div> <ExternalIcon styling={{ w: '24px', h: '24px', mr: '6px' }} icon={BsFillPersonPlusFill} /></Div>
                    <Div fontSize='14px'> Invite as admin</Div>
                </HStack>}
            {(userRole === GROUP_ROLES.OWNER || userRole === GROUP_ROLES.ADMIN) && muteMemberContent}
            {removeMemberContent}
        </Flex>;
    }

    const logoutButtonStyling = {
        fontWeight: FONTS.normal[300],
        _active: { bg: 'transparent' },
        _hover: { bg: 'transparent' },
        _focusvisible: { border: 'none' },
        bg: 'transparent',
        p: '0px',
        fontSize: '12px',
        lineheight: '28px',
        letterSpacing: 1.1,
        iconSpacing: '2px',
        rightIcon: <ExternalIcon styling={{ fontSize: '16px' }} icon={MdOutlineKeyboardArrowDown} />
    }

    return (
        <Flex
            borderBottom={`1px solid ${COLOR_NAMES.gray686868}`}
            pb={'10px'}
            pt={'10px'}
            w='100%'
            alignItems={'center'}
            justifyContent='space-between'>
            <Flex alignItems={'center'}>
                <Avatar name={user.name} bg={user.img_url ? 'none' : user.avatar_color}
                    src={user.img_url ? `${STATIC_URL}/${user.img_url}` : ''} size={'sm'} />
                <VStack h='42px' ml='10px' spacing={0}>
                    <Div fontSize='14px' w='100%' textAlign='start'>{user.name}</Div>
                    <Div fontSize='12px' fontWeight={FONTS.normal[300].fontWeight} w='100%' textAlign='start'>
                        <Span variant={VARIANT_KEYS.color.color_gray757575_grayC4C4C4}>{user.email}</Span>
                    </Div>
                </VStack>
            </Flex>
            <Flex h={'100%'}>
                {isLargerThan550
                    ? <HStack>
                        {isInvitedAsAdmin &&
                            <TooltipIcon
                                icon={<ExternalIcon styling={{ fontSize: '14px', mb: '4px' }} />}
                                message={'user has been invited as admin'}
                                color={'white'}
                                bg={COLOR_NAMES.gray666666} />
                        }
                        {user.isMutedInGroup &&
                            <TooltipIcon
                                icon={<ExternalIcon styling={{ fontSize: '14px', mt: '6px', opacity: '0.7' }} icon={GoMute} />}
                                message={'This user is muted'}
                                color={'white'}
                                bg={COLOR_NAMES.gray666666} />
                        }
                        <ControlMenuDekstop
                            menuButton={<WrapperButton styling={{ ...logoutButtonStyling }} message={getButtonMessage()} />}
                            isLargerThan550={isLargerThan550}
                            h={'140px'}>
                            {getManinControlContent()}
                        </ControlMenuDekstop>
                    </HStack>
                    : <HStack>
                        {isInvitedAsAdmin &&
                            <TooltipIcon
                                icon={<ExternalIcon styling={{ fontSize: '14px', mb: '4px' }} />}
                                message={'user has been invited as admin'}
                                color={'white'}
                                bg={COLOR_NAMES.gray666666} />
                        }
                        {user.isMutedInGroup &&
                            <TooltipIcon
                                icon={<ExternalIcon styling={{ fontSize: '14px', mt: '6px', opacity: '0.7' }} icon={GoMute} />}
                                message={'This user is muted'}
                                color={'white'}
                                bg={COLOR_NAMES.gray666666} />
                        }
                        <ControlMenuMobile
                            drawwerButton={<WrapperButton
                                styling={{ ...logoutButtonStyling }}
                                onClickHandle={onDrawerOpen}
                                message={getButtonMessage()} />}
                            isDrrawerOpen={isDrrawerOpen}
                            onDrawerClose={onDrawerClose}
                            h={'140px'} >
                            {getManinControlContent()}
                        </ControlMenuMobile>
                    </HStack>
                }
            </Flex>
        </Flex>
    )
}

export default GroupMember;