import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { Avatar, InputLeftElement } from '@chakra-ui/react';
import Div from '../html-elements/div.component';
import WrapperButtonLink from '../buttons/wrapper-button-link.component';
import IdleTimerComponent from '../shared/IdleTimerComponent';
import WrapperButton from '../buttons/wrapper-button.component';
import Search from '../inputs/search-component';
import ExternalIcon from '../icons/external-icon.component';
import useAuth from '../../shared/hooks/auth.hook';
import { buildTitleBaseOnPathname } from '../../shared/utils/common.utils';
import { VARIANT_KEYS, FONTS, ROUTES, COLOR_NAMES, STATIC_URL } from '../../shared/constants';
import { MdSearch } from 'react-icons/md';

function TopNavigation({ handleSetSearchText }) {
    const location = useLocation();
    const title = buildTitleBaseOnPathname(location.pathname, ROUTES);
    const { user } = useAuth();

    const handleSearchField = (e) => {
        const text = e.target.value.trim();
        handleSetSearchText(text);
    }

    const stylingPlaceholder = {
        fontSize: '13px',
        color: COLOR_NAMES.grayAFAFAF,
        fontWeight: FONTS.normal[300],
    }

    const stylingInputSearch = {
        variant: VARIANT_KEYS.INPUT.PRIMARY,
        fontSize: '13px',
        height: '28px',
        width: '280px',
        letterSpacing: '1.1px',
        fontWeight: FONTS.normal[400],
        pl: '40px',
        pr: '8px',
        placeholder: 'Search for posts',
    }

    const inputElemetLeft = <InputLeftElement h='100%'>
        <WrapperButton
            message={<ExternalIcon styling={{ w: '15px', h: '15px', color: COLOR_NAMES.grayAFAFAF, mt: '6px' }} icon={MdSearch} />}
            styling={{ variant: VARIANT_KEYS.bgAndColor.bg_transparent_color_black141414_whiteFFFFFF }}>
        </WrapperButton>
    </InputLeftElement>

    return (
        <Fragment>
            <IdleTimerComponent />
            <Div fontWeight={FONTS.normal[700].fontWeight} pl='20px' fontSize='20px'>{title}</Div>
            {
                title === 'Home' &&
                <Div>
                    <Search
                        inputLeftElement={inputElemetLeft}
                        placeholderStyling={stylingPlaceholder}
                        styling={stylingInputSearch}
                        handleChange={handleSearchField} />
                </Div>
            }
            <WrapperButtonLink
                styling={{ variant: VARIANT_KEYS.navLink.white }}
                to={ROUTES.MY_PROFILE}
                message={
                    <Avatar
                        name={user.name}
                        bg={user.img_url ? 'none' : user.avatar_color}
                        src={user.img_url ? `${STATIC_URL}/${user.img_url}` : ''}
                        size={'sm'} />
                } />
        </Fragment>
    )
}

export default TopNavigation;
