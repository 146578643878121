import React, { useState, useEffect, useRef } from 'react';
import { Image, useMediaQuery } from '@chakra-ui/react';
import Div from '../html-elements/div.component';
import ExternalIcon from '../icons/external-icon.component';
import UserAvatarWithCredentials from '../user/user-avatar-credentials.component';
import WebcamCapture from '../shared/webcam-capture.component';
import PhotoModal from '../shared/photo-modal.component';
import imageService from '../../services/image.service';
import { AVATAR_MODE, MEDIA, COLOR_NAMES, STATIC_URL } from '../../shared/constants';
import { TbLetterX } from 'react-icons/tb';

function ImageUploader({ user, handleSelectPicture, removeSelectedPicture, updateAvatarColor }) {
    const fileInputRef = useRef(null);
    const [image, setImage] = useState(null);
    const [mode, setMode] = useState(AVATAR_MODE.WITHOUT_PICTURE);
    const [isLargerThan750] = useMediaQuery(MEDIA.MIN_WIDTH_750_PX);

    const mediaSizeStyle = isLargerThan750
        ? { lineHeight: '125px', h: '128px', w: '128px', boxSize: '128px' }
        : { lineHeight: '112px', h: '112px', w: '112px', boxSize: '112px' };

    useEffect(() => {
        if (user.img_url) {
            setImage(`${STATIC_URL}/${user.img_url}`);
            setMode(AVATAR_MODE.SELECT_PICTURE);
        }
    }, [])

    const handleFileSelect = (e) => {
        if (e.target.files.length !== 0) {
            const selectedFile = e.target.files[0];

            handleSelectPicture(selectedFile);
            setImage(URL.createObjectURL(selectedFile));
            setMode(AVATAR_MODE.SELECT_PICTURE);
        }
    };

    const removeImage = () => {
        setMode(AVATAR_MODE.WITHOUT_PICTURE);
        handleSelectPicture(null);
        setImage(null);
        removeSelectedPicture();
    }

    const handleColorChange = (value) => {
        updateAvatarColor(value);
    }

    const handleImageScreenshot = async (base64) => {
        if (!base64) {
            return;
        }

        const file = await imageService.convertFromBase64ToFileAsync(base64, 'myProfilePicture');

        handleSelectPicture(file);
        setImage(URL.createObjectURL(file));
        setMode(AVATAR_MODE.SELECT_PICTURE);
    }

    const stylingOuterDiv = {
        position: 'relative',
        textAlign: 'center',
        bg: '#4e4e4e',
        w: '22px',
        h: '22px',
        borderRadius: 'full',
        bottom: '54px',
        left: '48px',
    }

    const stylingInnerIcon = {
        width: '12px',
        height: '12px',
        borderRadius: 'full',
        color: COLOR_NAMES.oragneFFB81C,
    }

    const GetContent = () => {
        if (mode === AVATAR_MODE.SELECT_PICTURE) {
            return (
                <Div>
                    <Image
                        src={image}
                        boxSize={mediaSizeStyle.boxSize}
                        borderRadius='full'
                        alt=''
                        onClick={() => fileInputRef?.current?.click()} />
                    <Div position={'relative'} w='30px' bottom={'128px'} left={'48px'} onClick={removeImage}>
                        <ExternalIcon styling={{ width: '12px', height: '12px', color: '#DE4946' }} icon={TbLetterX} />
                    </Div>
                    <PhotoModal
                        title={'Take a selfie'}
                        stylingOuterDiv={stylingOuterDiv}
                        stylingInnerIcon={stylingInnerIcon}>
                        <WebcamCapture handleImageScreenshot={handleImageScreenshot} />
                    </PhotoModal>
                </Div>
            )
        } else if (mode === AVATAR_MODE.WITHOUT_PICTURE) {
            return (
                <Div>
                    <UserAvatarWithCredentials
                        name={user.name}
                        stylingAvatar={
                            {
                                bg: user.avatar_color,
                                w: mediaSizeStyle.w,
                                h: mediaSizeStyle.h,
                                size: '2xl',
                            }
                        }
                        sizeCredentials={25}
                        handleColorChange={handleColorChange}
                        onClick={() => fileInputRef?.current?.click()} />
                    <PhotoModal
                        title={'Take a selfie'}
                        stylingOuterDiv={{ ...stylingOuterDiv, bottom: '63px' }}
                        stylingInnerIcon={stylingInnerIcon}>
                        <WebcamCapture handleImageScreenshot={handleImageScreenshot} />
                    </PhotoModal>
                </Div>
            )
        }

        return null;
    }

    return (
        <Div position='relative'>
            <input
                type='file'
                style={{ display: 'none' }}
                onChange={(e) => handleFileSelect(e)}
                accept='image/*'
                ref={fileInputRef} />
            {GetContent()}
        </Div>
    )
}

export default ImageUploader;
