import { mode } from "@chakra-ui/theme-tools";
import { COLOR_NAMES } from '../../shared/constants';
// variant naming: background-light-theme/dark-theme -> color-light-theme/dark-theme
const Button = {
    baseStyle: {},
    sizes: {},
    variants: {
        bg_oragneFDB71A_oragneFFB81C_color_black141414_black141414: (props) => ({
            bg: mode(COLOR_NAMES.oragneFDB71A, COLOR_NAMES.oragneFFB81C)(props),
            color: mode(COLOR_NAMES.black141414, COLOR_NAMES.black141414)(props),
            _hover: {},
        }),
        bg_greenA9E5B6_green54A565_color_black141414_whiteFFFFFF: (props) => ({
            bg: mode(COLOR_NAMES.greenA9E5B6, COLOR_NAMES.green54A565)(props),
            color: mode(COLOR_NAMES.black141414, '#FFFFFF')(props),
            _hover: {},
        }),
        bg_blue9ED0F3_blue5483A5_color_black141414_whiteFFFFFF: (props) => ({
            bg: mode(COLOR_NAMES.blue9ED0F3, COLOR_NAMES.blue5483A5)(props),
            color: mode(COLOR_NAMES.black141414, '#FFFFFF')(props),
            _hover: {},
        }),
        bg_transparent_color_blue19558D_whiteFFFFFF: (props) => ({
            bg: mode('transparent', 'transparent')(props),
            color: mode(COLOR_NAMES.blue19558D, '#FFFFFF')(props),
            _hover: {},
        }),
        bg_transparednt_gray666666_color_black141414_whiteFFFFFF: (props) => ({
            bg: mode('transparent', COLOR_NAMES.gray666666)(props),
            color: mode(COLOR_NAMES.black141414, '#FFFFFF')(props),
            _hover: {},
        }),
        bg_transparent_color_black141414_whiteFFFFFF: (props) => ({
            bg: mode('transparent', 'transparent')(props),
            color: mode(COLOR_NAMES.black141414, '#FFFFFF')(props),
            _hover: {},
        }),
        bg_transparent_black2C2F2A_color_blue2097F6_blue4F93F1: (props) => ({
            bg: mode('transparent', COLOR_NAMES.black2C2F2A)(props),
            color: mode(COLOR_NAMES.blue2097F6, COLOR_NAMES.blue4F93F1)(props),
        }),
        bg_transparent_black2C2F2A_color_gray929292_gray929292: (props) => ({
            bg: mode('transparent', COLOR_NAMES.black2C2F2A)(props),
            color: mode(COLOR_NAMES.gray929292, COLOR_NAMES.gray929292)(props),
        }),
        bg_transparent_color_black141414_whiteFFFFFF_border_1px_FFB81C: (props) => ({
            bg: mode('transparent', 'transparent')(props),
            color: mode(COLOR_NAMES.black141414, 'white')(props),
            border: mode('1px solid #FFB81C', '1px solid #FFB81C')(props),
        }),
        bg_transparent_color_black141414_grayC4C4C4_border_1px_FFB81C: (props) => ({
            bg: mode('transparent', 'transparent')(props),
            color: mode(COLOR_NAMES.black141414, COLOR_NAMES.grayC4C4C4)(props),
            border: mode('1px solid #FFB81C', '1px solid #C4C4C4')(props),
        }),
        bg_transparent_color_blue4F93F1: (props) => ({
            color: mode(COLOR_NAMES.blue4F93F1, COLOR_NAMES.blue4F93F1)(props),
            bg: mode('transparent', 'transparent')(props),
            _hover: {},
        }),
        bg_transparent_color_gray757575_orangeFFB81C: (props) => ({
            color: mode(COLOR_NAMES.gray757575, COLOR_NAMES.oragneFFB81C)(props),
            bg: mode('transparent', 'transparent')(props),
            _hover: {},
        }),
        bg_blue2097F6_color_black141414_whiteFFFFFF: (props) => ({
            color: mode(COLOR_NAMES.black141414, 'white')(props),
            bg: mode(COLOR_NAMES.blue2097F6, COLOR_NAMES.blue2097F6)(props),
            _hover: {},
        }),
        bg_blue2097F6_gray757575_color_black141414_whiteFFFFFF: (props) => ({
            color: mode(COLOR_NAMES.black141414, 'white')(props),
            bg: mode(COLOR_NAMES.blue2097F6, COLOR_NAMES.gray757575)(props),
            _hover: {},
        }),
        bg_transparent_color_black141414_whiteFFFFFF_border_1px_FFB81C_whiteFFFFFF: (props) => ({
            color: mode(COLOR_NAMES.black141414, 'white')(props),
            bg: mode('transparent', 'transparent')(props),
            border: mode('1px solid #FFB81C', '1px solid white')(props),
            _hover: {},
        }),
        bg_transparent_color_gray686868_border_1px_686868: (props) => ({
            color: mode(COLOR_NAMES.gray686868, COLOR_NAMES.gray686868)(props),
            bg: mode('transparent', 'transparent')(props),
            border: mode('1px solid #686868', '1px solid #686868')(props),
            _hover: {},
        }),
        bg_redDE4946_color_gray3D403D_whiteFFFFFF: (props) => ({
            color: mode(COLOR_NAMES.gray3D403D, 'white')(props),
            bg: mode(COLOR_NAMES.redDE4946, COLOR_NAMES.redDE4946)(props),
        }),
    },
    defaultProps: {},
}

export default Button;
