import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { VStack, Flex, FormControl, FormLabel } from '@chakra-ui/react';
import FormDropDown from '../components/html-elements/form-drop-down.component';
import FormRadioGroup from '../components/html-elements/form-radio-group.component';
import UploadWithPreviewButton from '../components/buttons/upload-with-preview-button.component';
import FormInput from '../components/html-elements/form-input.component';
import FormTextarea from '../components/html-elements/form-textarea.component';
import Div from '../components/html-elements/div.component';
import WrapperButton from '../components/buttons/wrapper-button.component';
import groupService from '../services/group.service';
import toastService from '../services/toast.service';
import useAuth from '../shared/hooks/auth.hook';
import useConfigurations from '../shared/hooks/configurations.hook';
import { ROUTES, FONT_FAMILY_NAME, VARIANT_KEYS, FONTS, GROUPS_CATEGORY, STATIC_URL, CONFIGURATION_KEYS } from '../shared/constants';
import Yup from '../shared/yup';

function GroupActionPage({ dataPreview, isCreateMode }) {
    const { user } = useAuth();
    const { configurations } = useConfigurations();
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);
    const [image, setImage] = useState(null);
    const [imageToForm, setImageToForm] = useState(null); // Think about better solution in future    

    const handleFileSelect = (e) => {
        if (e.target.files.length !== 0) {
            const selectedFile = e.target.files[0];

            setSelectedFile(selectedFile);
            setImage(URL.createObjectURL(selectedFile));
        }
    };

    const handleDeclineFile = () => {
        setSelectedFile(null);
        setImage(null);
        setImageToForm(null);
    }

    const handleSubmit = async (values) => {
        const formData = new FormData();
        const formFields = {
            name: values.name,
            description: values.description,
            type: values.type,
            category: values.category,
            img_url: imageToForm,
        };

        Object.keys(formFields).forEach((key) => {
            formData.append(key, formFields[key]);
        });

        if (selectedFile) {
            formData.append('file', selectedFile);
        }

        try {
            const response = !dataPreview
                ? await groupService.createAsync(formData)
                : await groupService.editAsync(formData, dataPreview.id);

            const data = response.data;
            navigate(`${ROUTES.GROUP_DETAILS}/${data.data}`);
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    useEffect(() => {
        if (dataPreview && dataPreview.img_url) {
            setImageToForm(dataPreview.img_url);
            setImage(`${STATIC_URL}/${dataPreview.img_url}`);
        }
    }, [dataPreview])

    useEffect(() => {
        if (!configurations[CONFIGURATION_KEYS.groupCreation].isActive && isCreateMode) {
            toastService.warning(configurations[CONFIGURATION_KEYS.groupCreation].messageUnavailable);
        }
    }, [configurations, isCreateMode])

    const stylingButton = {
        variant: VARIANT_KEYS.bgAndColor.bg_oragneFDB71A_oragneFFB81C_color_black141414_black141414,
        fontSize: '22px',
        h: '48px',
        fontWeight: FONTS.normal[700],
        w: '100%',
        mt: 'auto',
        isDisabled: user.isMuted || (!configurations[CONFIGURATION_KEYS.groupCreation].isActive && isCreateMode),
        _hover: {},
    }

    return (
        <Div h={'734px'} w={{ sm: '340px' }}>
            {(isCreateMode || (dataPreview && dataPreview.name)) && // Need to prevent warning
                <Formik
                    initialValues={{
                        name: dataPreview ? dataPreview.name : '',
                        description: dataPreview ? dataPreview.description || '' : '',
                        type: dataPreview ? dataPreview.type : 'public',
                        category: dataPreview ? dataPreview.category : 'FUNNY',
                    }}
                    validationSchema={Yup.object().shape({ name: Yup.string().required('Group name is required') })}
                    onSubmit={async (values, actions) => {
                        handleSubmit(values);
                    }}>
                    {(props) => (
                        <Form style={{ height: '100%' }}>
                            <Flex justifyContent={'space-between'} direction={'column'} w={'100%'} h={'100%'}>
                                <VStack alignItems={'start'} alignContent={'start'} spacing={4}>
                                    <FormInput
                                        name={'name'}
                                        label={'Group name'}
                                        placeholder={'Group name'}
                                        isRequired={true}
                                        inputStyling={{ variant: VARIANT_KEYS.INPUT.PRIMARY }}
                                        formikProps={props} />
                                    <FormTextarea
                                        name={'description'}
                                        label={'Description'}
                                        placeholder={'Description'}
                                        textareaStyling={{ variant: VARIANT_KEYS.TEXTAREA.PRIMARY, w: '100%', h: '150px' }}
                                        formikProps={props} />
                                    <FormRadioGroup
                                        name={'type'}
                                        label={'Group privacy'}
                                        radioValues={['public', 'private']}
                                        formikProps={props} />
                                    <Div w='100%'>
                                        <FormDropDown
                                            styling={{ variant: VARIANT_KEYS.SELECT.PRIMARY }}
                                            label={'Category'}
                                            name={'category'}
                                            kvp={{ ...GROUPS_CATEGORY }}
                                            formikProps={props} />
                                    </Div>
                                    <Div w='100%'>
                                        <FormControl>
                                            <FormLabel fontFamily={FONT_FAMILY_NAME} fontSize={'14px'}>Images</FormLabel>
                                            <UploadWithPreviewButton
                                                handleDeclineFileSelect={handleDeclineFile}
                                                handleFileSelect={handleFileSelect}
                                                file={image} />
                                        </FormControl>
                                    </Div>
                                </VStack>
                                <Div w='100%'>
                                    <WrapperButton styling={stylingButton} message={dataPreview ? 'Update' : 'Create'} type={'submit'} />
                                </Div>
                            </Flex>
                        </Form>
                    )}
                </Formik>
            }
        </Div>
    )
}

export default GroupActionPage;
