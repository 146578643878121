import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, VStack, HStack, useMediaQuery, useDisclosure, Flex, useStyleConfig } from '@chakra-ui/react';
import Span from '../html-elements/span.component';
import Div from '../html-elements/div.component';
import WrapperButton from '../buttons/wrapper-button.component';
import ControlMenuDekstop from '../shared/control-menu-dekstop.component';
import ControlMenuMobile from '../shared/control-menu-mobile.component';
import ExternalIcon from '../icons/external-icon.component';
import Dialog from '../shared/dialog.component';
import { COLOR_NAMES, STATIC_URL, VARIANT_KEYS, NOTIFICATION_ACTIONS, FONTS, BO_ADMIN_USER, MEDIA } from '../../shared/constants';
import { getTimeElapsedObject, parseMarkdown } from '../../shared/utils/common.utils';
import { RiDeleteBin5Fill, RiVolumeMuteFill } from 'react-icons/ri';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import { BiDotsHorizontalRounded } from 'react-icons/bi';

function Notification({
    sentByUser,
    notification,
    onAccept,
    onDeny,
    onDelete,
    onMuteNotificationsByUser,
    onNotificationClick,
    isCommentNotification = false,
    isBoNotification = false,
    isInviteNotification = false,
    notificationRedirectLink = null,
    renderActionButtons = false
}) {
    const [isLargerThan550] = useMediaQuery(MEDIA.MIN_WIDTH_550_PX);
    const { isOpen: isDeleteopen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
    const { isOpen: isDrrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
    const { isOpen: isMuteOpen, onOpen: onMuteOpen, onClose: onMuteClose } = useDisclosure();
    const navigate = useNavigate();
    const notificationStyles = useStyleConfig('Notification', { clicked: notification.isClicked });

    const timeElaplsedObject = getTimeElapsedObject(notification.date_create);
    const isNotificationDeletable = !(isInviteNotification && notification.actionTaken === NOTIFICATION_ACTIONS.NO_ACTION);

    const handleAccept = (e) => {
        e.stopPropagation();
        onAccept(notification.type, notification.actionId, notification.id);
    }

    const handleDeny = (e) => {
        e.stopPropagation();
        onDeny(notification.type, notification.actionId, notification.id)
    }

    const onRedirect = () => {
        if (!notification.isClicked) {
            onNotificationClick(notification.id);
        }
        if (notificationRedirectLink) {
            navigate(`${notificationRedirectLink}`, { state: isCommentNotification ? { commentId: notification.actionId } : {} })
        }
    }

    const deleteNotification = () => {
        onDelete(notification.id);
    }

    const muteNotificationsByUser = () => {
        onMuteNotificationsByUser(sentByUser.id);
    }

    const onDeleteCloseHandleMobile = () => {
        onDeleteClose();
        onDrawerClose();
    }

    const onMuteCloseHandleMobile = () => {
        onMuteClose();
        onDrawerClose();
    }

    const mainControlContent = <Flex pl='20px' justifyContent={'space-around'} direction={'column'} h='100%'>
        <HStack onClick={onDeleteOpen} h='36px'>
            <Flex alignItems={'center'} h='100%'>
                <ExternalIcon styling={{ w: '24px', h: '24px' }} icon={RiDeleteBin5Fill} />
            </Flex>
            <Div fontSize='14px'>Delete notification</Div>
            <Dialog
                isOpen={isDeleteopen}
                onClose={isLargerThan550 ? onDeleteClose : onDeleteCloseHandleMobile}
                onAction={isNotificationDeletable ? deleteNotification : () => { }}
                messageBody={isNotificationDeletable ? `Are you shure you want to delete selected notification?` : `You are not allowed to delete unanswered invite notifications.`}
                messageHeader={`Delete notification`}
                messageAction={isNotificationDeletable ? `Delete` : `OK`}
            />
        </HStack>
        <HStack onClick={onMuteOpen} h='36px'>
            <Flex alignItems={'center'} h='100%'>
                <ExternalIcon styling={{ w: '24px', h: '24px' }} icon={RiVolumeMuteFill} />
            </Flex>
            <Div
                fontSize='14px'
                opacity={sentByUser.isUserNotificationMuted ? '50%' : '100%'}>
                {sentByUser.isUserNotificationMuted
                    ? `Notifications from ${sentByUser.name} already muted.`
                    : `Mute notifications from ${sentByUser.name}.`}
            </Div>
            {!sentByUser.isUserNotificationMuted && <Dialog
                isOpen={isMuteOpen}
                onClose={isLargerThan550 ? onMuteClose : onMuteCloseHandleMobile}
                onAction={muteNotificationsByUser}
                messageAction={`Mute`}
                messageBody={`You won't recieve any more notifications from ${sentByUser.name}. Are you sure?`}
                messageHeader={`Mute notifications`} />}
        </HStack>
    </Flex>

    const dekstopControl = <ControlMenuDekstop isLargerThan550={isLargerThan550} h={'140px'}>
        {mainControlContent}
    </ControlMenuDekstop>

    const mobileControl = <ControlMenuMobile
        drawwerButton={<ExternalIcon onClick={onDrawerOpen} styling={{ fontSize: '26px' }} icon={BiDotsHorizontalRounded} />}
        isDrrawerOpen={isDrrawerOpen}
        onDrawerClose={onDrawerClose}
        h={'140px'}>
        {mainControlContent}
    </ControlMenuMobile>

    const actionButtons = <Fragment>
        <WrapperButton
            message={'Accept'}
            styling={{
                variant: VARIANT_KEYS.bgAndColor.bg_greenA9E5B6_green54A565_color_black141414_whiteFFFFFF,
                leftIcon: <CheckIcon />,
                fontSize: '10px',
                h: '20px',
                fontWeight: FONTS.normal[400],
                w: '100%',
            }}
            onClickHandle={(event) => handleAccept(event)} />
        <WrapperButton
            message={'Decline'}
            styling={{
                variant: VARIANT_KEYS.bgAndColor.bg_redDE4946_color_gray3D403D_whiteFFFFFF,
                leftIcon: <CloseIcon />,
                fontSize: '10px',
                h: '20px',
                fontWeight: FONTS.normal[400],
                w: '100%',
            }}
            onClickHandle={(event) => handleDeny(event)} />
    </Fragment>

    return (
        <HStack w={'100%'} pl={'10px'} pr={'10px'} __css={notificationStyles}>
            <HStack w={'100%'} marginRight={'auto'} onClick={onRedirect} _hover={{ cursor: 'pointer' }}>
                <Avatar borderRadius='5px' boxSize='48px' mr='20px'
                    name={!isBoNotification ? sentByUser.name : BO_ADMIN_USER.name}
                    bg={!isBoNotification ? (sentByUser.img_url ? 'none' : sentByUser.avatar_color) : BO_ADMIN_USER.avatar_color}
                    src={(!isBoNotification && sentByUser.img_url) ? `${STATIC_URL}/${sentByUser.img_url}` : ''} />
                <VStack w='100%' alignItems={'start'} textAlign={'left'}>
                    <Span variant={VARIANT_KEYS.color.color_gray757575_grayC4C4C4} fontWeight={'bold'}>{!isBoNotification ? sentByUser.name : BO_ADMIN_USER.name}</Span>
                    <Span variant={VARIANT_KEYS.color.color_gray757575_grayC4C4C4}>
                        {parseMarkdown(notification.message)}
                        {notification.actionTaken === NOTIFICATION_ACTIONS.ACCEPTED && ` (accepted)`}
                        {notification.actionTaken === NOTIFICATION_ACTIONS.DECLINED && ` (declined)`}
                    </Span>
                </VStack>
                {renderActionButtons &&
                    (isLargerThan550
                        ? <HStack alignSelf={'end'}>{actionButtons}</HStack>
                        : <VStack>{actionButtons}</VStack>)
                }
            </HStack>
            <VStack>
                {isLargerThan550
                    ? dekstopControl
                    : mobileControl
                }
                <Span fontSize={'12px'} variant={VARIANT_KEYS.color.color_gray757575_grayAFAFAF}>
                    {`${timeElaplsedObject.period}${timeElaplsedObject.periodAbbreviation}`}
                </Span>
            </VStack>
        </HStack>
    )
}

export default Notification;