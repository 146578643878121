import axios from './axios.service';
import jwt_decode from 'jwt-decode';
import authHeader from '../shared/utils/axios.utils';
import { AUTH_KEYS } from '../shared/constants';

const LOGIN_URL = '/login';
const SIGNUP_URL = '/signup';
const VALIDATE_URL = '/validate';
const REGISTER_URL = '/signup';
const REFRESH_TOKEN_URL = '/refresh-token';
const REVOKE_TOKEN_URL = '/revoke-token';
const TOKENS_URL = '/tokens';
const GET_USER_BY_ACCESS_TOKEN_URL = '/get-user-by-access-token';

class AuthService {
  async login(payload) {
    return axios.post(LOGIN_URL, payload);
  }

  async signup(payload) {
    return await axios.post(SIGNUP_URL, payload);
  }

  async register(payload) {
    return axios.patch(REGISTER_URL, payload);
  }

  async refreshTokenAsync() {
    return axios.post(REFRESH_TOKEN_URL, { refreshToken: this.getRefreshToken() });
  }

  async validate(payload) {
    return axios.post(VALIDATE_URL, JSON.stringify(payload), { headers: authHeader() });
  }

  async logout() {
    await axios.post(REVOKE_TOKEN_URL, { refreshToken: this.getRefreshToken() });
    localStorage.removeItem(AUTH_KEYS.TOKEN);
    localStorage.removeItem(AUTH_KEYS.REFRESH_TOKEN);
  }

  setToken(token) {
    localStorage.setItem(AUTH_KEYS.TOKEN, token);
  }

  setRefreshToken(token) {
    localStorage.setItem(AUTH_KEYS.REFRESH_TOKEN, token);
  }

  getToken() {
    return localStorage.getItem(AUTH_KEYS.TOKEN);
  }

  getRefreshToken() {
    return localStorage.getItem(AUTH_KEYS.REFRESH_TOKEN);
  }

  getJwtExpirationTimeOneMinuteBeforeExpire() {
    const decodedToken = this.decodeToken();
    const expires = new Date(decodedToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);

    return timeout;
  }

  async getOrCreateUserByAccessTokenAsync(token) {
    return axios.post(GET_USER_BY_ACCESS_TOKEN_URL, { token: token });
  }

  async getTokensByCodeAsync(code, redirectUrl) {
    return axios.get(TOKENS_URL, { params: { code: code, redirectUrl: redirectUrl } });
  }

  isLoggedIn() {
    const token = localStorage.getItem(AUTH_KEYS.TOKEN);
    if (!token) {
      return false;
    }

    const decodedToken = this.decodeToken();
    if (decodedToken.exp * 1000 < new Date()) {
      return false;
    }

    return true;
  }

  decodeToken() {
    return jwt_decode(this.getToken());
  }
}

const authService = new AuthService();

export default authService;
