import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar, HStack, Flex, useColorModePreference, useColorModeValue } from '@chakra-ui/react';
import Div from '../components/html-elements/div.component';
import WrapperButton from '../components/buttons/wrapper-button.component';
import Multiselect from '../components/shared/multiselect.component';
import useAuth from '../shared/hooks/auth.hook';
import groupService from '../services/group.service';
import userService from '../services/user.service';
import { ROUTES, VARIANT_KEYS, FONTS, FONT_FAMILY_NAME, GROUP_ROLES, STATIC_URL } from '../shared/constants';
import toastService from '../services/toast.service';

function InviteMembersPage() {
    const { user } = useAuth();
    const params = useParams();
    const navigate = useNavigate();
    const color = useColorModeValue('black', 'white')

    const [groupId, setGroupId] = useState(0);

    const [values, setValues] = useState([]);

    const loadOptions = async (search, loadedOptions, page) => {
        const options = [];
        let hasMore = true;

        try {
            const currentData = (await userService.findUsersToBeInvitedAsync(params.groupId, search, page, 5)).data.data;
            if (!currentData.length) {
                hasMore = false;
            } else {
                currentData.map((u, i) => {
                    options.push({
                        value: u.id,
                        selectedItem: <HStack h='30px' id={i} w='100%' key={`user_likes_${u.id}`}>
                            <HStack w='100%'>
                                <Avatar name={u.name} bg={u.img_url ? 'none' : u.avatar_color}
                                    src={u.img_url ? `${STATIC_URL}/${u.img_url}` : ''} size={'xs'} />

                                <Div variant={VARIANT_KEYS.color.color_black141414_whiteFFFFFF} fontFamily={FONT_FAMILY_NAME} fontSize='12px'>{u.name}</Div>
                            </HStack>
                        </HStack>,
                        label: <HStack id={i} w='100%' key={`user_likes_${u.id}`}>
                            <HStack w='100%'>
                                <Avatar name={u.name} bg={u.img_url ? 'none' : u.avatar_color}
                                    src={u.img_url ? `${STATIC_URL}/${u.img_url}` : ''} size={'sm'} />
                                <Div fontFamily={FONT_FAMILY_NAME} fontSize='14px'>{u.name}</Div>
                            </HStack>
                            {(u.isInvited || u.isMember || u.isApplied) &&
                                <Div>
                                    {u.isMember ? 'Member' : u.isInvited ? 'Invited' : u.isApplied ? 'Applied' : null}
                                </Div>}
                        </HStack>,
                        disabled: u.isInvited || u.isMember || u.isApplied
                    })
                })
            }
        } catch (error) {
            console.log(error);
        }

        return {
            options: options,
            hasMore,
            additional: {
                page: page + 1,
            },
        };
    };

    const getInitialData = async () => {
        try {
            const userRoleData = (await groupService.roleInGroupAsync(params.groupId)).data;
            if (!userRoleData.isSuccess || !(userRoleData.data === GROUP_ROLES.ADMIN || userRoleData.data === GROUP_ROLES.OWNER)) {
                navigate(ROUTES.HOME);

                return;
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const groupId = params.groupId || 0;
        setGroupId(groupId);

        if (groupId) {
            getInitialData(groupId);
        }
    }, []);

    const inviteMembersToGroup = async () => {
        try {
            const response = await groupService.inviteMembersToGroupAsync({ groupId: groupId, userIds: values.map((e) => e.value) });
            toastService.success(response.data.data);
            navigate(`${ROUTES.GROUP_DETAILS}/${groupId}`);
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    };

    const stylingButton = {
        variant: VARIANT_KEYS.bgAndColor.bg_oragneFDB71A_oragneFFB81C_color_black141414_black141414,
        fontSize: '22px',
        h: '48px',
        fontWeight: FONTS.normal[700],
        w: '100%',
        mt: 'auto',
        isDisabled: user.isMuted || !values || !values.length,
        _hover: {},
    }

    return (
        <Flex minH={'734px'} justifyContent={'space-between'} direction={'column'} w={{ sm: '340px' }}>
            <Div w='100%'>
                <Multiselect
                    loadOptions={loadOptions}
                    onChange={setValues}
                    placeholder={'Search by email'}
                    noOptionsMessage={'No users Found'}
                    debounceTime={500} />
            </Div>
            <Div mt='20px' w='100%'>
                <WrapperButton onClickHandle={inviteMembersToGroup} styling={stylingButton} message={'Invite'} />
            </Div>
        </Flex>
    );
}

export default InviteMembersPage;
