import axios from './axios.service';

const DEFAULT = '/posts';
const EDIT = `${DEFAULT}/edit`;
const HOME_POSTS = `${DEFAULT}/get-home-posts`;
const LIKED_POSTS = `${DEFAULT}/get-liked-posts`;
const GROUP_POSTS = `${DEFAULT}/get-group-posts`;
const USER_POSTS = `${DEFAULT}/get-user-posts`;
const USERS_WHO_LIKE_POSTS = `${DEFAULT}/user-likes-post`;
const REPORT = `${DEFAULT}/report`;
const REPOSTED = `${DEFAULT}/reposted`;
const DELETE_FROM_GROUP = `${DEFAULT}/delete-from-group`;

class PostService {
    async reportAsync(id, reason) {
        return axios.post(`${REPORT}/${id}`, { reason: reason });
    }

    async editAsync(uuid, payload) {
        return axios.patch(`${DEFAULT}/${uuid}`, payload);
    }

    async editRepostedAsync(id, repostedMessage) {
        return axios.put(`${REPOSTED}/${id}`, { repostedMessage: repostedMessage });
    }

    async createAsync(payload) {
        return axios.post(`${DEFAULT}`, payload);
    }

    async createGroupPostAsync(payload, groupId) {
        return axios.post(`${DEFAULT}/${groupId}`, payload);
    }

    async deleteFromGroupAsync(groupId, uuid) {
        return axios.post(DELETE_FROM_GROUP, { groupId, uuid });
    }

    async deleteAsync(uuid) {
        return axios.delete(`${DEFAULT}/${uuid}`);
    }

    async addComment(payload, uuid) {
        return axios.post(`${DEFAULT}/${uuid}/comments`, payload);
    }

    async getPostsInGroupAsync(groupId, options) {
        return axios.get(`${GROUP_POSTS}/${groupId}`, { ...options });
    }

    async getCommentsByUuidAsync(uuid) {
        return axios.get(`${DEFAULT}/${uuid}/comments`);
    }

    async authorPostByUuid(uuid) {
        return axios.get(`${EDIT}/${uuid}`);
    }

    async getByUuid(uuid) {
        return axios.get(`${DEFAULT}/${uuid}`);
    }

    async getHomePostsAsync(options) {
        return axios.get(HOME_POSTS, { ...options });
    }

    async getLikedPostsAsync(options) {
        return axios.get(LIKED_POSTS, { ...options });
    }

    async getUserPosts(userId, options) {
        return axios.get(`${USER_POSTS}/${userId}`, { ...options });
    }

    async getUsersWhoLikesPostAsync(id, page, limit, options) {
        return axios.get(
            `${USERS_WHO_LIKE_POSTS}/${id}`,
            {
                ...options,
                params: {
                    page: page,
                    limit: limit,
                },
            });
    }

    async changePostLikes(payload, uuId) {
        return axios.post(`${DEFAULT}/${uuId}/likes`, payload);
    }

    async createReplycomment(payload, uuid, parentCommentId) {
        return axios.post(`${DEFAULT}/${uuid}/comments/reply/${parentCommentId}`, payload);
    }
}

const postService = new PostService();

export default postService;
