import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { HStack, VStack, InputRightElement, Flex, Spinner } from '@chakra-ui/react';
import Link from '../components/html-elements/link.component';
import GroupMember from '../components/groups/group-member.component';
import Div from '../components/html-elements/div.component';
import Search from '../components/inputs/search-component';
import ExternalIcon from '../components/icons/external-icon.component';
import WrapperButton from '../components/buttons/wrapper-button.component';
import useInfiniteScroll from '../shared/hooks/infinitie-scroll.hook';
import userService from '../services/user.service';
import groupService from '../services/group.service';
import { VARIANT_KEYS, ROUTES, FONTS, COLOR_NAMES, GROUP_ROLES, GROP_MEMBER_ACTIONS } from '../shared/constants';
import { MdSearch } from 'react-icons/md';
import { debounce } from 'lodash';
import toastService from '../services/toast.service';

function MembersPage() {
    const [searchTerm, setSearchTerm] = useState('');
    const [people, setPeople] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [userRole, setUserRole] = useState(null);

    const params = useParams();
    const { groupId } = params;
    const navigate = useNavigate();

    const loadMore = async (options) => {
        try {
            const data = (await userService.getMembersInGroupAsync(groupId, searchTerm, pageNum, 10, options)).data.data;
            setPeople(prev => [...prev, ...data]);
            return data;
        } catch (error) {
            console.log(error);
        }
    }

    const {
        isLoading,
        isError,
        error,
        hasNextPage,
    } = useInfiniteScroll(loadMore, pageNum);

    const intObserver = useRef();

    const lastUsertRef = useCallback(node => {
        if (isLoading) {
            return;
        }

        if (intObserver.current) {
            intObserver.current.disconnect();
        }

        intObserver.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasNextPage) {
                setPageNum(prev => prev + 1);
            }
        });

        if (node) {
            intObserver.current.observe(node);
        }
    }, [isLoading, hasNextPage]);

    const initializeData = async () => {
        try {
            const userRoleData = (await groupService.roleInGroupAsync(params.groupId)).data;
            if (!userRoleData.isSuccess || !(userRoleData.data === GROUP_ROLES.ADMIN || userRoleData.data === GROUP_ROLES.OWNER)) {
                navigate(ROUTES.HOME);

                return;
            }

            setUserRole(userRoleData.data);
        } catch (error) {
            console.log(error);
        }
    }

    const handleChangeSearch = async (e) => {
        try {
            const search = e.target.value;
            setSearchTerm(search);
            setPeople([]);
            setPageNum(1);
        } catch (error) {
            console.log(error);
        }
    };

    const handleInputDebounce = debounce(handleChangeSearch, 500);

    const rejectMemberFromGroup = async (userId) => {
        try {
            const data = (await groupService.rejectUserFromGroupAsync(groupId, userId)).data;
            if (data.isSuccess) {
                setPeople(people => {
                    const result = [];
                    for (const person of people) {
                        if (person.id !== userId) {
                            result.push({ ...person });
                        }
                    }

                    return result;
                })
            }
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    const acceptMemberToGroup = async (userId) => {
        try {
            const data = (await groupService.acceptUserToGroupAsync(groupId, userId)).data;
            if (data.isSuccess) {
                setPeople(people => {
                    const result = [];
                    for (const person of people) {
                        if (person.id === userId) {
                            result.push({ ...person, action: GROP_MEMBER_ACTIONS.ACCEPT, role: GROUP_ROLES.MEMBER });

                            continue;
                        }

                        result.push({ ...person });
                    }

                    return result;
                })
            }
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    const removeInvitationForAdmin = async (userId) => {
        try {
            const data = (await groupService.removeInvitationForAdminAsync(groupId, userId)).data;
            if (data.isSuccess) {
                setPeople(people => {
                    const result = [];
                    for (const person of people) {
                        if (person.id === userId) {
                            result.push({ ...person, action: GROP_MEMBER_ACTIONS.ACCEPT });

                            continue;
                        }

                        result.push({ ...person });
                    }

                    return result;
                })
            }
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    const changeRoleFromAdminToMember = async (userId) => {
        try {
            const data = (await groupService.changeRoleFromAdminToMemberAsync(groupId, userId)).data;
            if (data.isSuccess) {
                setPeople(people => {
                    const result = [];
                    for (const person of people) {
                        if (person.id === userId) {
                            result.push({ ...person, role: GROUP_ROLES.MEMBER });
                        } else {
                            result.push({ ...person });
                        }
                    }

                    return result;
                });
            }
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    const removeInvitationForUser = async (userId) => {
        try {
            const data = (await groupService.removeInvitationForUserAsync(groupId, userId)).data;
            if (data.isSuccess) {
                setPeople(people => {
                    const result = [];
                    for (const person of people) {
                        if (person.id !== userId) {
                            result.push({ ...person });
                        }
                    }

                    return result;
                })
            }
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    const removeInvitation = async (userId, type) => {
        if (type === GROP_MEMBER_ACTIONS.INVITE_AS_ADMIN) {
            await removeInvitationForAdmin(userId);
        } else if (type === GROP_MEMBER_ACTIONS.INVITE_AS_MEMBER) {
            await removeInvitationForUser(userId);
        }
    };

    const removeMember = async (userId) => {
        try {
            const data = (await groupService.removeMemberAsync(groupId, userId)).data;
            if (data.isSuccess) {
                setPeople(people => {
                    const result = [];
                    for (const person of people) {
                        if (person.id !== userId) {
                            result.push({ ...person });
                        }
                    }

                    return result;
                });
            }
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    };

    const inviteAsAdmin = async (userId) => {
        try {
            const data = (await groupService.inviteUserAsAdminAsync(groupId, userId)).data;
            if (data.isSuccess) {
                setPeople(people => {
                    const result = [];
                    for (const person of people) {
                        if (person.id === userId) {
                            result.push({ ...person, action: GROP_MEMBER_ACTIONS.INVITE_AS_ADMIN });

                            continue;
                        }

                        result.push({ ...person });
                    }

                    return result;
                });
            }
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    };

    const toggleMuteMemberInGroup = async (userId, currMuteStatus) => {
        try {
            let data;
            if (currMuteStatus) {
                data = (await groupService.unmuteMemberAsync(groupId, userId)).data;
            }
            else {
                data = (await groupService.muteMemberAsync(groupId, userId)).data;
            }
            if (data.isSuccess) {
                setPeople(people => {
                    const result = [];
                    for (const person of people) {
                        if (person.id === userId) {
                            result.push({ ...person, isMutedInGroup: !currMuteStatus });
                        } else {
                            result.push({ ...person });
                        }
                    }

                    return result;
                });
            }
        } catch (error) {
            toastService.error(error.response.data.errorMessage)
        }
    };

    useEffect(() => {
        if (isError) {
            console.log(error);

            return;
        }

        if (!groupId) {
            navigate(ROUTES.HOME);

            return;
        }

        initializeData();
    }, [isError])

    const stylingPlaceholder = {
        fontSize: '13px',
        color: COLOR_NAMES.grayAFAFAF,
        fontWeight: FONTS.normal[300]
    }

    const stylingInputSearch = {
        variant: VARIANT_KEYS.bg.bg_grayE2E2E2_gray333333,
        fontSize: '13px',
        height: '48px',
        width: '100%',
        letterSpacing: '1.1px',
        fontWeight: FONTS.normal[400],
        pl: '10px',
    }

    const inputElementRight = <InputRightElement height='100%'>
        <WrapperButton
            message={<Flex><ExternalIcon styling={{ width: '22px', height: '22px', color: 'white' }} icon={MdSearch} /></Flex>}
            styling={{ variant: VARIANT_KEYS.bgAndColor.bg_transparent_color_black141414_whiteFFFFFF }}>
        </WrapperButton>
    </InputRightElement>

    return (
        <Div minH={'734px'} w={{ sm: '340px' }}>

            <HStack>
                <Link
                    to={`${ROUTES.INVITE_MEMBERS}/${groupId}`}
                    style={{
                        variant: VARIANT_KEYS.navLink.blue,
                        fontSize: '16px',
                        textAlign: 'start',
                        w: '100%'
                    }}>
                    + Invite new members
                </Link>
            </HStack>
            <VStack mt={'25px'}>
                <Div w='100%' textAlign='start' fontSize='14px'>Members</Div>
                <Div w='100%'>
                    <Search
                        outerValue={searchTerm}
                        placeholderStyling={stylingPlaceholder}
                        placeholder={'Search for members and invited people'}
                        handleChange={handleInputDebounce}
                        inputRightElement={inputElementRight}
                        styling={stylingInputSearch} />
                </Div>
            </VStack>
            <VStack w='100%'>
                {people.map((x, i) =>
                    <HStack ref={i + 1 === people.length ? lastUsertRef : null} w='100%' key={`group_members_${x.id}`}>
                        <GroupMember
                            user={x}
                            inviteAsAdmin={inviteAsAdmin}
                            removeInvitation={removeInvitation}
                            removeMember={removeMember}
                            userRole={userRole}
                            acceptMemberToGroup={acceptMemberToGroup}
                            rejectMemberFromGroup={rejectMemberFromGroup}
                            changeRoleFromAdminToMember={changeRoleFromAdminToMember}
                            toggleMuteMemberInGroup={toggleMuteMemberInGroup} />
                    </HStack>)
                }
            </VStack>

            {isLoading && pageNum > 2 && <Spinner />}
        </Div>
    )
}

export default MembersPage;
