import { mode } from "@chakra-ui/theme-tools";
import { COLOR_NAMES } from '../../shared/constants';

export const Span = {
    baseStyle: {},
    sizes: {},
    variants: {
        //color_black000000_whiteFFFFFF
        color_black000000_whiteFFFFFF: (props) => ({
            color: mode('black', 'white')(props),
        }),
        //color_gray5E5E5E_grayB4B4B4
        color_gray5E5E5E_grayB4B4B4: (props) => ({
            color: mode(COLOR_NAMES.gray5e5e5e, COLOR_NAMES.grayB4B4B4)(props),
        }),
        //color_gray424242_orangeFFB81C
        color_gray424242_orangeFFB81C: (props) => ({
            color: mode(COLOR_NAMES.gray424242, COLOR_NAMES.oragneFFB81C)(props),
        }),
        //color_gray2C3D23_greenA5DC86
        color_gray2C3D23_greenA5DC86: (props) => ({
            color: mode(COLOR_NAMES.gray2C3D23, COLOR_NAMES.greenA5DC86)(props),
        }),
        //color_gray9E9E9E_redF27474
        color_gray9E9E9E_redF27474: (props) => ({
            color: mode(COLOR_NAMES.gray9E9E9E, COLOR_NAMES.redF27474)(props),
        }),
        //color_gray757575_grayC4C4C4
        color_gray757575_grayC4C4C4: (props) => ({
            color: mode(COLOR_NAMES.gray757575, COLOR_NAMES.grayC4C4C4)(props),
        }),
        //color_gray757575_grayAFAFAF
        color_gray757575_grayAFAFAF: (props) => ({
            color: mode(COLOR_NAMES.gray757575, COLOR_NAMES.grayAFAFAF)(props),
        }),
    },
    defaultProps: {},
}

export default Span;
