export const DOMAIN_URL = process.env.REACT_APP_BACKEND_URL;
export const STATIC_URL = `https://s3.eu-central-1.amazonaws.com/knowme.modis-ops.net/${process.env.REACT_APP_STATIC_FOLDERS_PATH}`;
export const ONBOARDING_URL = 'http://google.com'; // TODO: add link to onboarding 
export const BACKOFFICE_URL = 'https://knowme-bo.modis-ops.net/';
export const GDPR_LINK = 'https://google.com/';

export const AUTH_KEYS = {
  TOKEN: 'token',
  REFRESH_TOKEN: 'refreshToken'
};

export const GROUP_ROLES = {
  UNKNOWN: 'UNKNOWN',
  OWNER: 'OWNER',
  MEMBER: 'MEMBER',
  ADMIN: 'ADMIN',
};

export const GROP_MEMBER_ACTIONS = {
  UNKNOWN: 'UNKNOWN',
  INVITE_AS_ADMIN: 'INVITE_AS_ADMIN',
  INVITE_AS_MEMBER: 'INVITE_AS_MEMBER',
  ACCEPT: 'ACCEPT',
  APPLY: 'APPLY',
};

export const COMMENT_MODE = {
  UNKNOWN: 'UNKNOWN',
  READ: 'READ',
  WRITE: 'WRITE',
};

export const LOCLA_STORAGE_KEYS = {
  LAST_SEARCHES_DESKTOP_KEY: 'lastSearchesDekstop',
  LAST_SEARCHES_MOBILE_KEY: 'lastSearchesMobile',
};

export const MEDIA = {
  MIN_WIDTH_750_PX: '(min-width: 750px)',
  MIN_WIDTH_1250_PX: '(min-width: 1250px)',
  MIN_WIDTH_550_PX: '(min-width: 550px)',
};

export const ROUTES = {
  DEFAULT: '/',
  AUTHENTICATION: '/oauth2/code',
  HOME: '/home',
  LOGIN: '/login',
  SIGNUP: '/signup',
  CONTACTS: '/contacts',
  CHANGE_EMAIL: '/change-email',
  USER_PROFILE: '/user-profile',
  MY_PROFILE: '/profile',
  EDIT_PROFILE: '/edit-profile',
  GROUPS: '/groups',
  GROUPS_SEARCH: '/groups-search',
  CREATE_GROUP: '/groups-create',
  GROUP_DETAILS: '/group-details',
  EDIT_GROUP: '/groups-edit',
  INVITE_MEMBERS: '/invite-members',
  MEMBERS: '/members',
  LIKED: '/liked',
  NOTIFICATIONS: '/notifications',
  NOTIFICATIONS_SETTINGS: 'notifications-settings',
  COMMENT_POST: '/comment-post',
  CREATE_POST: '/post-create',
  EDIT_POST: '/post-edit',
  USER_POSTS: '/user-posts',
  ONBOARDING: '/onboarding',
  BACK_OFFICE: '/back-office',
};

export const POST_TYPES = { HOME: 'home', LIKED: 'liked', GROUP: 'group', USER: 'user' };

export const SOCKET_EVENTS = {
  NEW_NOTIFICATION: 'new-notification',
  UNREAD_NOTIFICATIONS: 'unread-notifications',
  READ_NOTIFICATIONS: 'read-notifications',
  INITIAL_CONFIGURATIONS: 'initial-configurations',
  CONFIGURATION_CHANGE: 'configuration-change',
};

export const FONT_FAMILY_NAME = 'Roboto, sans-serif';

export const FONTS = {
  normal: {
    300: { fontWeight: 300 },
    400: { fontWeight: 400 },
    500: { fontWeight: 500 },
    700: { fontWeight: 700 },
  },
  italic: {
    300: {
      fontWeight: 300,
      fontStyle: 'italic',
    },
    400: {
      fontWeight: 400,
      fontStyle: 'italic',
    },
    500: {
      fontWeight: 500,
      fontStyle: 'italic',
    },
    700: {
      fontWeight: 700,
      fontStyle: 'italic',
    },
  },
  underlined: {
    300: {
      fontWeight: 300,
      textDecoration: 'underline',
      fontSize: '12px'
    }
  }
};

// Think about names and separation
export const COLOR_NAMES = {
  oragneFFB81C: '#FFB81C',
  oragneFDB71A: '#FDB71A',
  redF27474: '#F27474',
  gray333333: '#333333',
  gray3F3F3F: '#3F3F3F',
  gray686868: '#686868',
  gray666666: '#666666',
  gray929292: '#929292',
  grayC4C4C4: '#C4C4C4',
  grayB4B4B4: '#B4B4B4',
  gray757575: '#757575',
  grayAFAFAF: '#AFAFAF',
  gray4a4a4a: '#4a4a4a',
  gray424242: '#424242',
  gray3D403D: '#3D403D',
  grayF4F4F4: '#F4F4F4',
  grayE2E2E2: '#E2E2E2',
  gray5e5e5e: '#5E5E5E',
  gray9E9E9E: '#9E9E9E',
  gray2C3D23: '#2C3D23',
  grayE8E8E8: '#E8E8E8',
  gold906811: '#906811',
  black2B2E2A: '#2B2E2A',
  black141414: '#141414',
  black2C2F2A: '#2C2F2A',
  black212320: '#212320',
  black262626: '#262626',
  black161717: '#161717',
  blue4F93F1: '#4F93F1',
  blue19558D: '#19558D',
  blue5483A5: '#5483A5',
  blue2097F6: '#2097F6',
  blue9ED0F3: '#9ED0F3',
  redDE4946: '#DE4946',
  redFF5A43: '#FF5A43',
  green54A565: '#54A565',
  greenA5DC86: '#A5DC86',
  greenA9E5B6: '#A9E5B6',
  gren95A554: '#95A554',
  green54A58D: '#54A58D',
  purple7354A5: '#7354A5',
};

// Think about name and separation when ligth theme is apply it
export const VARIANT_KEYS = {
  bgAndColor: {
    bg_oragneFDB71A_oragneFFB81C_color_black141414_black141414: 'bg_oragneFDB71A_oragneFFB81C_color_black141414_black141414',
    bg_greenA9E5B6_green54A565_color_black141414_whiteFFFFFF: 'bg_greenA9E5B6_green54A565_color_black141414_whiteFFFFFF',
    bg_blue9ED0F3_blue5483A5_color_black141414_whiteFFFFFF: 'bg_blue9ED0F3_blue5483A5_color_black141414_whiteFFFFFF',
    bg_transparent_color_blue19558D_whiteFFFFFF: 'bg_transparent_color_blue19558D_whiteFFFFFF',
    bg_transparent_color_black141414_whiteFFFFFF: 'bg_transparent_color_black141414_whiteFFFFFF',
    bg_transparednt_gray666666_color_black141414_whiteFFFFFF: 'bg_transparednt_gray666666_color_black141414_whiteFFFFFF',
    bg_transparent_color_black141414_whiteFFFFFF_border_1px_FFB81C: 'bg_transparent_color_black141414_whiteFFFFFF_border_1px_FFB81C',
    bg_transparent_color_black141414_grayC4C4C4_border_1px_FFB81C: 'bg_transparent_color_black141414_grayC4C4C4_border_1px_FFB81C',
    bg_transparent_black2C2F2A_color_blue2097F6_blue4F93F1: 'bg_transparent_black2C2F2A_color_blue2097F6_blue4F93F1',
    bg_transparent_black2C2F2A_color_gray929292_gray929292: 'bg_transparent_black2C2F2A_color_gray929292_gray929292',
    bg_transparent_color_blue4F93F1: 'bg_transparent_color_blue4F93F1',
    bg_transparent_color_gray757575_orangeFFB81C: 'bg_transparent_color_gray757575_orangeFFB81C',
    bg_blue2097F6_color_black141414_whiteFFFFFF: 'bg_blue2097F6_color_black141414_whiteFFFFFF',
    bg_blue2097F6_gray757575_color_black141414_whiteFFFFFF: 'bg_blue2097F6_gray757575_color_black141414_whiteFFFFFF',
    bg_transparent_color_gray686868_border_1px_686868: 'bg_transparent_color_gray686868_border_1px_686868',
    bg_redDE4946_color_gray3D403D_whiteFFFFFF: 'bg_redDE4946_color_gray3D403D_whiteFFFFFF',
  },
  bg: {
    bg_grayE2E2E2_gray333333: 'bg_grayE2E2E2_gray333333',
    bg_whiteFFFFFF_black212320: 'bg_whiteFFFFFF_black212320',
  },
  bgAndborder: {
    bg_grayE2E2E2_gray333333_border_1px_grayE2E2E2_1px_gray686868: 'bg_grayE2E2E2_gray333333_border_1px_grayE2E2E2_1px_gray686868',
    bg_grayE8E8E8_black212320_border_1px_grayC4C4C4_1px_gray666666_radius_6px: 'bg_grayE8E8E8_black212320_border_1px_grayC4C4C4_1px_gray666666_radius_6px',
    bg_transparent_color_black141414_whiteFFFFFF_border_1px_FFB81C_whiteFFFFFF: 'bg_transparent_color_black141414_whiteFFFFFF_border_1px_FFB81C_whiteFFFFFF',
  },
  navLink: {
    white: 'white',
    blue: 'blue',
  },
  color: {
    color_gray5E5E5E_grayB4B4B4: 'color_gray5E5E5E_grayB4B4B4',
    color_black000000_whiteFFFFFF: 'color_black000000_whiteFFFFFF',
    color_gray424242_orangeFFB81C: 'color_gray424242_orangeFFB81C',
    color_gray2C3D23_greenA5DC86: 'color_gray2C3D23_greenA5DC86',
    color_gray9E9E9E_redF27474: 'color_gray9E9E9E_redF27474',
    color_gray757575_grayC4C4C4: 'color_gray757575_grayC4C4C4',
    color_gray757575_grayAFAFAF: 'color_gray757575_grayAFAFAF',
    color_black141414_whiteFFFFFF: 'color_black141414_whiteFFFFFF',
    color_gold906811_orangeFFB81C: 'color_gold906811_orangeFFB81C',
  },
  INPUT: {
    PRIMARY: 'primary',
  },
  TEXTAREA: {
    PRIMARY: 'primary',
  },
  SELECT: {
    PRIMARY: 'primary'
  }
};

export const AVATAR_MODE = {
  WITHOUT_PICTURE: 'WITHOUT_PICTURE',
  SELECT_PICTURE: 'SELECT_PICTURE',
};

export const GROUPS_CATEGORY = {
  FUNNY: 'Funny',
  MANAGEMENT: 'Management',
  SALES: 'Sales',
  HR_PROCESS: 'HR process',
};

export const BG_GROUPS_CATEGORY = {
  ALL: COLOR_NAMES.gray3F3F3F,
  FUNNY: COLOR_NAMES.gren95A554,
  MANAGEMENT: COLOR_NAMES.purple7354A5,
  SALES: COLOR_NAMES.green54A58D,
  HR_PROCESS: COLOR_NAMES.blue5483A5,
};

export const JOB_POSITIONS = {
  ['Backend Developer']: 'Backend Developer',
  ['Fullstack .NET Developer']: 'Fullstack .NET Developer',
  ['Fullstack Java Developer']: 'Fullstack Java Developer',
  ['Fullstack Web Application Developer']: 'Fullstack Web Application Developer',
  ['Integration Engineer']: 'Integration Engineer',
  ['Java Developer']: 'Java Developer',
  ['Junior Backend Developer']: 'Junior Backend Developer',
  ['Junior Frontend Web Application Developer']: 'Junior Frontend Web Application Developer',
  ['Junior Fullstack Web Application Developer']: 'Junior Fullstack Web Application Developer',
  ['Manager Software Development']: 'Manager Software Development',
  ['Mid Full Stack Web Developer']: 'Mid Full Stack Web Developer',
  ['Mid iOS Developer']: 'Mid iOS Developer',
  ['Product Delivery Lead']: 'Product Delivery Lead',
  ['Product Owner']: 'Product Owner',
  ['QA Automation Engineer']: 'QA Automation Engineer',
  ['QA Engineer']: 'QA Engineer',
  ['Resource Manager']: 'Resource Manager',
  ['Salesforce Developer']: 'Salesforce Developer',
  ['Senior Android & iOS Developer']: 'Senior Android & iOS Developer',
  ['Senior Android Developer']: 'Senior Android Developer',
  ['Senior Data Warehouse Engineer']: 'Senior Data Warehouse Engineer',
  ['Senior Frontend Web Developer']: 'Senior Frontend Web Developer',
  ['Senior Full Stack Java Developer']: 'Senior Full Stack Java Developer',
  ['Senior Fullstack Web Application Developer']: 'Senior Fullstack Web Application Developer',
  ['Senior Java Developer']: 'Senior Java Developer',
  ['Senior QA Engineer']: 'Senior QA Engineer',
  ['Software Development Delivery Lead']: 'Software Development Delivery Lead',
  ['Solution Analyst SWD']: 'Solution Analyst SWD',
  ['Tech Lead – QA']: 'Tech Lead – QA',
  ['Tech Lead - Web Development']: 'Tech Lead - Web Development',
  ['UX UI Designer']: 'UX UI Designer',
};

export const PROFILE_BACKGROUND_COLORS = [
  'orange',
  'red',
  'pink',
  'blue',
  'lime',
  'green',
];

export const LOCATIONS = [
  {
    location: 'Australia',
    countryCode: 'AU',
    internationalCountryCode: '+61',
  },
  {
    location: 'Singapore',
    countryCode: 'SH',
    internationalCountryCode: '+65',
  },
  {
    location: 'Bulgaria',
    countryCode: 'BG',
    internationalCountryCode: '+359',
  },
  {
    location: 'Japan',
    countryCode: 'JP',
    internationalCountryCode: '+81',
  },
  {
    location: 'Thailand',
    countryCode: 'TH',
    internationalCountryCode: '+66',
  },
  {
    location: 'Belgium',
    countryCode: 'BE',
    internationalCountryCode: '+32',
  },

  {
    location: 'France',
    countryCode: 'FR',
    internationalCountryCode: '+33',
  },
  {
    location: 'Germany',
    countryCode: 'DE',
    internationalCountryCode: '+49',
  },
  {
    location: 'Italy',
    countryCode: 'IT',
    internationalCountryCode: '+39',
  },
  {
    location: 'Netherlands',
    countryCode: 'NL',
    internationalCountryCode: '+31',
  },
  {
    location: 'Norway',
    countryCode: 'NO',

    internationalCountryCode: '+47',
  },
  {
    location: 'Poland',
    countryCode: 'PL',
    internationalCountryCode: '+48',
  },
  {
    location: 'Spain',
    countryCode: 'ES',
    internationalCountryCode: '+34',
  },
  {
    location: 'Sweden',
    countryCode: 'SE',
    internationalCountryCode: '+46',
  },
  {
    location: 'Switzerland',
    countryCode: 'CH',
    internationalCountryCode: '+41',
  },
  {
    location: 'UK',
    countryCode: 'GB',
    internationalCountryCode: '+44',
  },
  {
    location: 'USA',
    countryCode: 'US',
    internationalCountryCode: '+1',
  },
];

export const TIMERS = {
  TEN_MINUTES_IN_MILLISECONDS: 1000 * 60 * 10,
  FIVE_MINUTES_IN_MILLISECONDS: 1000 * 60 * 5,
};

export const NOTIFICATION_TYPES = {
  INVITE_USER_AS_ADMIN: 'INVITE_USER_AS_ADMIN', 
  INVITE_AS_BO_ADMIN: 'INVITE_AS_BO_ADMIN', 
  INVITE_AS_ONBOARDING_ADMIN: 'INVITE_AS_ONBOARDING_ADMIN',
  ACCEPTED_INVITE_USER_AS_ADMIN: 'ACCEPTED_INVITE_USER_AS_ADMIN',
  ACCEPTED_INVITE_AS_BO_ADMIN: 'ACCEPTED_INVITE_AS_BO_ADMIN',
  ACCEPTED_INVITE_AS_ONBOARDING_ADMIN: 'ACCEPTED_INVITE_AS_ONBOARDING_ADMIN',
  REJECTED_INVITE_USER_AS_ADMIN: 'REJECTED_INVITE_USER_AS_ADMIN',
  REJECTED_INVITE_AS_BO_ADMIN: 'REJECTED_INVITE_AS_BO_ADMIN',
  REJECTED_INVITE_AS_ONBOARDING_ADMIN: 'REJECTED_INVITE_AS_ONBOARDING_ADMIN',
  JOIN_GROUP: 'JOIN_GROUP', 
  LEAVE_GROUP: 'LEAVE_GROUP', 
  REMOVED_FROM_GROUP: 'REMOVED_FROM_GROUP', 
  DELETED_GROUP: 'DELETED_GROUP', 
  APPLY_TO_GROUP: 'APPLY_TO_GROUP', 
  ACCEPTED_IN_GROUP: 'ACCEPTED_IN_GROUP', 
  REJECTED_FROM_GROUP: 'REJECTED_FROM_GROUP', 
  INVITE_USER_TO_GROUP: 'INVITE_USER_TO_GROUP', 
  GROUP_INVITE_DECLINED: 'GROUP_INVITE_DECLINED', 
  JUSTIFIED_GROUP_REPORT: 'JUSTIFIED_GROUP_REPORT', 
  NEW_POST: 'NEW_POST', 
  POST_LIKE: 'POST_LIKE', 
  SHARED_POST: 'SHARED_POST', 
  COMMENTED_ON_POST: 'COMMENTED_ON_POST', 
  DELETED_POST_IN_GROUP: 'DELETED_POST_IN_GROUP', 
  EDITED_POST_BO: 'EDITED_POST_BO', 
  DELETED_POST_BO: 'DELETED_POST_BO', 
  MUTED_BO: 'MUTED_BO', 
  EDITED_COMMENT_BO: 'EDITED_COMMENT_BO',
  DELETED_COMMENT_BO: 'DELETED_COMMENT_BO',
  DELETED_COMMENT_IN_GROUP: 'DELETED_COMMENT_IN_GROUP', 
  PROFILE_REVIEW: 'PROFILE_REVIEW', 
  JOB_POSITION_CHANGE: 'JOB_POSITION_CHANGE', 
  CONFIGURATION_CHANGE: 'CONFIGURATION_CHANGE', 
  COMMENT_LIKE: 'COMMENT_LIKE',
};

export const DEFAULT_NOTIFICATION_SETTINGS = {
  [NOTIFICATION_TYPES.COMMENTED_ON_POST]: true,
  // MY COMMENTS REPLIES STILL NOT NOTIFICATION 2
  [NOTIFICATION_TYPES.SHARED_POST]: true,
  [NOTIFICATION_TYPES.NEW_POST]: true,
  [NOTIFICATION_TYPES.POST_LIKE]: true,
  [NOTIFICATION_TYPES.INVITE_USER_TO_GROUP]: true,
  // [NOTIFICATION_TYPES.INVITE_USER_AS_ADMIN]: true,
  // [NOTIFICATION_TYPES.INVITE_AS_BO_ADMIN]: true,
  // [NOTIFICATION_TYPES.INVITE_AS_ONBOARDING_ADMIN]: true,
  [NOTIFICATION_TYPES.JOIN_GROUP]: true,
  [NOTIFICATION_TYPES.LEAVE_GROUP]: true,
  [NOTIFICATION_TYPES.APPLY_TO_GROUP]: true,
  [NOTIFICATION_TYPES.ACCEPTED_IN_GROUP]: true,
  [NOTIFICATION_TYPES.REJECTED_FROM_GROUP]: true,
  [NOTIFICATION_TYPES.REMOVED_FROM_GROUP]: true,
  [NOTIFICATION_TYPES.GROUP_INVITE_DECLINED]: true,
  [NOTIFICATION_TYPES.DELETED_GROUP]: true,
  [NOTIFICATION_TYPES.DELETED_COMMENT_IN_GROUP]: true,
  [NOTIFICATION_TYPES.DELETED_POST_IN_GROUP]: true,
  [NOTIFICATION_TYPES.PROFILE_REVIEW]: true,
  [NOTIFICATION_TYPES.JOB_POSITION_CHANGE]: true,
  [NOTIFICATION_TYPES.MUTED_BO]: true,
  [NOTIFICATION_TYPES.DELETED_POST_BO]: true,
  [NOTIFICATION_TYPES.DELETED_COMMENT_BO]: true,
  [NOTIFICATION_TYPES.CONFIGURATION_CHANGE]: true,
  [NOTIFICATION_TYPES.EDITED_POST_BO]: true,
  [NOTIFICATION_TYPES.JUSTIFIED_GROUP_REPORT]: true,
  [NOTIFICATION_TYPES.COMMENT_LIKE]: true,
}
// Group for user's UI
export const NOTIFICATION_SETTINGS_GROUPS = {
  COMMENTS_NOTIFICATIONS: [
    NOTIFICATION_TYPES.COMMENTED_ON_POST,
    NOTIFICATION_TYPES.COMMENT_LIKE,
    // MY COMMENTS REPLIES STILL NOT NOTIFICATION 2
  ],
  POSTS_NOTIFICATIONS: [
    NOTIFICATION_TYPES.SHARED_POST,
    NOTIFICATION_TYPES.NEW_POST,
    NOTIFICATION_TYPES.POST_LIKE,
  ],
  GROUP_NOTIFICATIONS: [
    NOTIFICATION_TYPES.INVITE_USER_TO_GROUP,
    // NOTIFICATION_TYPES.INVITE_USER_AS_ADMIN,
    // NOTIFICATION_TYPES.INVITE_AS_BO_ADMIN,
    // NOTIFICATION_TYPES.INVITE_AS_ONBOARDING_ADMIN,
    NOTIFICATION_TYPES.JOIN_GROUP,
    NOTIFICATION_TYPES.LEAVE_GROUP,
    NOTIFICATION_TYPES.APPLY_TO_GROUP,
    NOTIFICATION_TYPES.ACCEPTED_IN_GROUP,
    NOTIFICATION_TYPES.REJECTED_FROM_GROUP,
    NOTIFICATION_TYPES.REMOVED_FROM_GROUP,
    NOTIFICATION_TYPES.GROUP_INVITE_DECLINED,
    NOTIFICATION_TYPES.DELETED_GROUP,
    NOTIFICATION_TYPES.DELETED_COMMENT_IN_GROUP,
    NOTIFICATION_TYPES.DELETED_POST_IN_GROUP,
  ],
  PERSONAL_NOTIFICATIONS: [
    NOTIFICATION_TYPES.PROFILE_REVIEW,
    NOTIFICATION_TYPES.JOB_POSITION_CHANGE,
  ],
  BACK_OFFICE_NOTIFICATIONS: [
    NOTIFICATION_TYPES.MUTED_BO,
    NOTIFICATION_TYPES.DELETED_POST_BO,
    NOTIFICATION_TYPES.DELETED_COMMENT_BO,
    NOTIFICATION_TYPES.CONFIGURATION_CHANGE,
    NOTIFICATION_TYPES.EDITED_POST_BO,
    NOTIFICATION_TYPES.JUSTIFIED_GROUP_REPORT,
  ]
}
// Names for user's UI
export const NOTIFICATION_SETTINGS_NAMES = {
  [NOTIFICATION_TYPES.COMMENTED_ON_POST]: 'My post comments',
  // MY COMMENTS REPLIES STILL NOT NOTIFICATION 2
  [NOTIFICATION_TYPES.SHARED_POST]: 'My post sharing',
  [NOTIFICATION_TYPES.NEW_POST]: 'New post',
  [NOTIFICATION_TYPES.POST_LIKE]: 'My post likes',
  [NOTIFICATION_TYPES.INVITE_USER_TO_GROUP]: 'Group invites',
  //  These notification can not be switched off because they brings IMPORTANT information
  //  [NOTIFICATION_TYPES.INVITE_USER_AS_ADMIN]: 'Group admin invites', // inv user as group admin  
  //  [NOTIFICATION_TYPES.INVITE_AS_BO_ADMIN]: [true],  
  //  [NOTIFICATION_TYPES.INVITE_AS_ONBOARDING_ADMIN]: true, 
  [NOTIFICATION_TYPES.JOIN_GROUP]: 'Group joining',
  [NOTIFICATION_TYPES.LEAVE_GROUP]: 'Group leavers',
  [NOTIFICATION_TYPES.APPLY_TO_GROUP]: 'Private group application',
  [NOTIFICATION_TYPES.ACCEPTED_IN_GROUP]: 'Group acceptance',
  [NOTIFICATION_TYPES.REJECTED_FROM_GROUP]: 'Group rejection',
  [NOTIFICATION_TYPES.REMOVED_FROM_GROUP]: 'Removal from a group',
  [NOTIFICATION_TYPES.GROUP_INVITE_DECLINED]: 'Group invite decline',
  [NOTIFICATION_TYPES.DELETED_GROUP]: 'Group deletion',
  [NOTIFICATION_TYPES.DELETED_COMMENT_IN_GROUP]: 'Comment deletion',
  [NOTIFICATION_TYPES.DELETED_POST_IN_GROUP]: 'Post deletion',
  [NOTIFICATION_TYPES.PROFILE_REVIEW]: 'Profile review',
  [NOTIFICATION_TYPES.JOB_POSITION_CHANGE]: 'Job position change',
  [NOTIFICATION_TYPES.MUTED_BO]: 'BO mute',
  [NOTIFICATION_TYPES.DELETED_POST_BO]: 'Deleted post from BO',
  [NOTIFICATION_TYPES.DELETED_COMMENT_BO]: 'Deleted comment from BO',
  [NOTIFICATION_TYPES.CONFIGURATION_CHANGE]: 'Configuration change',
  [NOTIFICATION_TYPES.EDITED_POST_BO]: 'Edited post from BO',
  [NOTIFICATION_TYPES.JUSTIFIED_GROUP_REPORT]: 'Justified group report',
  [NOTIFICATION_TYPES.COMMENT_LIKE]: 'My comment likes',
}

export const NOTIFICATION_ACTIONS = {
  NO_ACTION: 'NO_ACTION',
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
};

export const BO_ADMIN_USER = {
  name: 'Backoffice Admin',
  avatar_color: COLOR_NAMES.oragneFFB81C,
};

export const POST_FILE_TYPES = {
  imageType: 'image',
  pdfType: 'pdf',
  videoType: 'video',
}

export const MAP_FROM_MIME_TYPE_TO_FILE_TYPE = {
  'image/jpeg': POST_FILE_TYPES.imageType,
  'image/jpg': POST_FILE_TYPES.imageType,
  'image/png': POST_FILE_TYPES.imageType,
  'image/gif': POST_FILE_TYPES.imageType,
  'application/pdf': POST_FILE_TYPES.pdfType,
  'video/mp4': POST_FILE_TYPES.videoType,
  'video/x-msvideo': POST_FILE_TYPES.videoType,
  'video/quicktime': POST_FILE_TYPES.videoType,
  'video/x-ms-wmv': POST_FILE_TYPES.videoType,
};

export const CONFIGURATION_KEYS = {
  registration: 'registration',
  posting: 'posting',
  groupsPosting: 'groupsPosting',
  postsEditing: 'postsEditing',
  comment: 'comment',
  groupComment: 'groupComment',
  commentsEditing: 'commentsEditing',
  groupCreation: 'groupCreation',
};

export const CONFIGURATIONS = {
  [CONFIGURATION_KEYS.registration]: { isActive: true, messageUnavailable: 'Registration configuration is currently disabled. Please, check your notifications for further details.' },
  [CONFIGURATION_KEYS.posting]: { isActive: true, messageUnavailable: 'Posting configuration is currently disabled. Please, check your notifications for further details.' },
  [CONFIGURATION_KEYS.groupsPosting]: { isActive: true, messageUnavailable: 'Group posting configuration is currently disabled. Please, check your notifications for further details.' },
  [CONFIGURATION_KEYS.postsEditing]: { isActive: true, messageUnavailable: 'Post editing configuration is currently disabled. Please, check your notifications for further details.' },
  [CONFIGURATION_KEYS.comment]: { isActive: true, messageUnavailable: 'Comments configuration is currently disabled. Please, check your notifications for further details.' },
  [CONFIGURATION_KEYS.groupComment]: { isActive: true, messageUnavailable: 'Group comments configuration is currently disabled. Please, check your notifications for further details.' },
  [CONFIGURATION_KEYS.commentsEditing]: { isActive: true, messageUnavailable: 'Comments editing configuration is currently disabled. Please, check your notifications for further details.' },
  [CONFIGURATION_KEYS.groupCreation]: { isActive: true, messageUnavailable: 'Group creation configuration is currently disabled. Please, check your notifications for further details.' },
};
