import React from 'react';
import PostsFeed from '../components/posts/posts-feed';
import postService from '../services/post.service';
import { POST_TYPES } from '../shared/constants';

function LikedPage() {
    return <PostsFeed type={POST_TYPES.LIKED} getPostsFn={postService.getLikedPostsAsync} />
}

export default LikedPage;
