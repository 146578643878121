import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UserPofileViewPage from './user-profile-view-page.component';
import useAuth from '../shared/hooks/auth.hook';
import { ROUTES } from '../shared/constants';

function UserProfilePage() {
    const location = useLocation();
    const navigation = useNavigate();
    const { user } = useAuth();

    useEffect(() => {
        const id = location.pathname.split('/').pop();

        if (user.id === id) {
            navigation(ROUTES.MY_PROFILE);
        }
    }, [])

    return <UserPofileViewPage isMyProfile={false} />
}

export default UserProfilePage;
