import React, { Fragment } from 'react';
import { Flex, useDisclosure } from '@chakra-ui/react';
import Div from '../html-elements/div.component';
import ExternalIcon from '../icons/external-icon.component';
import { COLOR_NAMES } from '../../shared/constants';
import { MdFavorite } from 'react-icons/md';
import LikesCoverModal from '../shared/likes-cover-modal.component';
import postService from '../../services/post.service';

function PostLikes({ postId, likesCount, isLikedByMe }) {
    const { isOpen: isShowUsersOpen, onOpen: onShowUsersOpen, onClose: onShowUsersClose } = useDisclosure();
    const getUsersWhoLikedPost = postService.getUsersWhoLikesPostAsync;

    return (
        <Fragment>
            <Flex w='100%'>
                <Flex cursor={'pointer'} mr={'8px'} alignItems={'center'}>
                    <ExternalIcon
                        onClick={onShowUsersOpen}
                        icon={MdFavorite}
                        styling={
                            {
                                w: '16px',
                                h: '16px',
                                p: '0px',
                                color: isLikedByMe ? COLOR_NAMES.redDE4946 : 'white',
                                bg: 'transparent',
                            }} />
                </Flex>
                <Div
                    cursor={'pointer'}
                    onClick={onShowUsersOpen}
                    color={'white'}
                    opacity='60%'
                    pt='2px'
                    fontSize='16px'>
                    {likesCount}
                </Div>
            </Flex>
            <LikesCoverModal
                componentId={postId}
                isOpen={isShowUsersOpen}
                isClose={onShowUsersClose}
                getUsersWhoLiked={getUsersWhoLikedPost}>
            </LikesCoverModal>
        </Fragment>
    )
}

export default PostLikes;
