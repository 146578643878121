import React, { Fragment } from 'react';
import {
    Drawer,
    DrawerContent,
    DrawerOverlay,
    DrawerBody,
    forwardRef,
    useColorModeValue,
} from '@chakra-ui/react';
import Div from '../html-elements/div.component';

function ControlMenuMobile({
    drawwerButton,
    isDrrawerOpen,
    onDrawerClose,
    h,
    children }) {
    const Button = forwardRef((pros, ref) => drawwerButton);
    const color = useColorModeValue('rgba(240, 240, 240, 0.95)','rgba(43, 46, 42, 0.9)')

    return (
        <Fragment>
            <Button />
            <Drawer
                isOpen={isDrrawerOpen}
                placement='bottom'
                onClose={onDrawerClose}
                finalFocusRef={Button}>
                <DrawerOverlay bg={color} />
                <DrawerContent bg={'transparent'}>
                    <DrawerBody borderTopRadius={'30px'}>
                        <Div
                            h={h}
                            w={'100%'}
                            _active={{ bg: 'transparent' }}
                            _hover={{ bg: 'transparent' }}
                            _focus={{}}
                            borderRadius='10px'>
                            {children}
                        </Div>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Fragment>
    )
}

export default ControlMenuMobile;
