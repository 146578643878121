import React, { useEffect, useState, useRef, useCallback } from 'react';
import { HStack, VStack, Flex, Spinner, Avatar } from '@chakra-ui/react';
import Div from '../html-elements/div.component';
import WrapperButton from '../buttons/wrapper-button.component';
import userService from '../../services/user.service';
import useAuth from '../../shared/hooks/auth.hook';
import useInfiniteScroll from '../../shared/hooks/infinitie-scroll.hook';
import { VARIANT_KEYS, FONTS, FONT_FAMILY_NAME, STATIC_URL } from '../../shared/constants';
import toastService from '../../services/toast.service';

function LikesUsersListModal({ componentId, getUsersWhoLiked }) {
    const { user } = useAuth();

    const [data, setData] = useState([]);
    const [pageNum, setPageNum] = useState(1);

    const loadMore = async (options) => {
        try {
            const data = (await getUsersWhoLiked(componentId, pageNum, 5, options)).data.data;
            setData(prev => [...prev, ...data]);

            return data;
        } catch (error) {
            console.log(error);
        }
    }

    const {
        isLoading,
        isError,
        error,
        hasNextPage,
    } = useInfiniteScroll(loadMore, pageNum);

    const intObserver = useRef();

    const lastUsertRef = useCallback(node => {
        if (isLoading) {
            return;
        }

        if (intObserver.current) {
            intObserver.current.disconnect();
        }

        intObserver.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasNextPage) {
                setPageNum(prev => prev + 1);
            }
        });

        if (node) {
            intObserver.current.observe(node);
        }
    }, [isLoading, hasNextPage]);

    const toggleFollow = async (userId, isFollowedByMe) => {
        try {
            if (isFollowedByMe) {
                await userService.follow(userId);
            } else {
                await userService.unfollow(userId);
            }

            setData(prev => {
                const data = [];
                for (const user of prev) {
                    if (user.id === userId) {
                        data.push({ ...user, isFollowedByMe: isFollowedByMe })
                    } else {
                        data.push({ ...user });
                    }
                }

                return data;
            });
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    };

    useEffect(() => {
        if (isError) {
            console.log(error);
        }
    }, [isError]);

    const stylingButton = {
        h: '18px',
        pr: '10px',
        pl: '10px',
        pt: '2px',
        pb: '2px',
        gap: '10px',
        borderRadius: '2px',
        fontSize: '12px',
        fontWeight: FONTS.normal[300].fontWeight,
    }

    return (
        <Flex pt='24px' h='100%' direction={'column'} justifyContent={'space-between'}>
            <VStack gap={4} w='100%'>
                {data && data.length
                    ? (
                        data.map((u, i) => {
                            return (
                                <HStack
                                    ref={i + 1 === data.length ? lastUsertRef : null}
                                    w='100%'
                                    key={`user_likes_${data[i].id}`}
                                    >
                                    <HStack w='100%'>
                                        <Avatar
                                            name={u.name}
                                            bg={u.img_url ? 'none' : u.avatar_color}
                                            src={u.img_url ? `${STATIC_URL}/${u.img_url}` : ''}
                                            size={'lg'} />
                                        <Div fontFamily={FONT_FAMILY_NAME} fontSize='16px'>{u.name}</Div>
                                    </HStack>
                                    <Div textAlign='right' w='100%'>
                                        {u.isFollowedByMe
                                            ? (<WrapperButton
                                            	styling={{ ...stylingButton, variant: VARIANT_KEYS.bgAndColor.bg_blue2097F6_gray757575_color_black141414_whiteFFFFFF }}
                                                onClickHandle={() => toggleFollow(u.id, false)}
                                                message={'Unfollow'} />
                                            ) : (user.id !== u.id
                                                ? <WrapperButton
                                            	styling={{ ...stylingButton, variant: VARIANT_KEYS.bgAndColor.bg_blue2097F6_color_black141414_whiteFFFFFF }}
                                                    onClickHandle={() => toggleFollow(u.id, true)}
                                                    message={'Follow'} />
                                                : null
                                            )}
                                    </Div>
                                </HStack>)
                        })
                    )
                    : !isLoading && pageNum === 1 ? <Div>No Likes</Div> : null
                }
                {isLoading && pageNum > 2 && <Spinner />}
            </VStack>
        </Flex>
    )
}

export default LikesUsersListModal;
