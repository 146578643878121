import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { HStack, Image, VStack, Badge, Flex } from '@chakra-ui/react';
import Div from '../html-elements/div.component';
import Span from '../html-elements/span.component';
import ExternalIcon from '../icons/external-icon.component';
import WrapperButton from '../buttons/wrapper-button.component';
import useAuth from '../../shared/hooks/auth.hook';
import useKnowMeMediaQuery from '../../shared/hooks/media-query.hook';
import {
    GROUPS_CATEGORY,
    BG_GROUPS_CATEGORY,
    VARIANT_KEYS,
    FONTS, ROUTES,
    FONT_FAMILY_NAME,
    COLOR_NAMES,
    STATIC_URL,
} from '../../shared/constants';
import { MdPeopleAlt } from 'react-icons/md';
import imagePeople from '../../static/images/people.svg';

function Group({ data, toggleJoinToGroup, applyToPrivateGroup }) {
    const { user } = useAuth();
    const navigate = useNavigate();
    const media = useKnowMeMediaQuery();
    const wrapperRef = useRef(null);
    const followRef = useRef(null);

    const handleClickOutside = (event) => {
        if (isClickedOutsideButton(event)) {
            navigate(`${ROUTES.GROUP_DETAILS}/${data.id}`);
        }
    };

    const isClickedOutsideButton = (event) => {
        return (
            wrapperRef &&
            wrapperRef.current.contains(event.target) &&
            !followRef.current.contains(event.target)
        );
    };

    const getButtonGroup = () => {
        if (data.type === 'public' || (data.type === 'private' && data.isCurrentUserIsSubscribed)) {
            return <WrapperButton
                styling={{
                    variant: data.isCurrentUserIsSubscribed
                        ? VARIANT_KEYS.bgAndColor.bg_transparent_color_gray686868_border_1px_686868
                        : VARIANT_KEYS.bgAndColor.bg_oragneFDB71A_oragneFFB81C_color_black141414_black141414,
                    fontSize: '16px',
                    h: '40px',
                    fontWeight: FONTS.normal[500],
                    w: '100%',
                }}
                onClickHandle={() => toggleJoinToGroup(data)}
                message={data.isCurrentUserIsSubscribed ? 'Joined' : 'Join'} />
        }

        return <WrapperButton
            styling={{
                variant: data.isCurrentUserIsApplied
                    ? VARIANT_KEYS.bgAndColor.bg_transparent_color_gray686868_border_1px_686868 // Change names
                    : VARIANT_KEYS.bgAndColor.bg_oragneFDB71A_oragneFFB81C_color_black141414_black141414,
                fontSize: '16px',
                h: '40px',
                fontWeight: FONTS.normal[500],
                w: '100%',
            }}
            onClickHandle={() => applyToPrivateGroup(data)}
            message={data.isCurrentUserIsApplied ? 'Applied' : 'Apply'} />
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div ref={wrapperRef} style={{ width: media.isLargerThan750 ? '206px' : '100%' }}>
            <Div variant={VARIANT_KEYS.bgAndborder.bg_grayE8E8E8_black212320_border_1px_grayC4C4C4_1px_gray666666_radius_6px} w={'100%'}>
                <VStack
                    spacing={3}
                    h={media.isLargerThan750 ? '262px' : '192px'}>
                    <Div w='100%' h='100px'>
                        <Image
                            objectFit={'cover'}
                            borderRadius='6px'
                            src={data.img_url
                                ? `${STATIC_URL}/${data.img_url}`
                                : imagePeople}
                            w={'100%'}
                            h={'100%'}
                            alt='' />
                    </Div>
                    <Flex
                        pb='10px' justifyContent={media.isLargerThan750 ? 'space-between' : 'start'}
                        flexDirection={'column'}
                        h='100%'
                        w='100%'
                        pl='10px'
                        pr='10px'>
                        <Div>
                            <Flex alignItems={'center'} w='100%' justifyContent={'start'}>
                                <Badge
                                    textTransform={'inherit'}
                                    fontFamily={FONT_FAMILY_NAME}
                                    fontWeight={FONTS.normal[500]}
                                    mr='10px'
                                    pt='1px'
                                    pb='18px'
                                    pl='6px'
                                    pr='6px'
                                    h='16px'
                                    fontSize={'12px'}
                                    color='white'
                                    borderRadius='6px'
                                    bg={BG_GROUPS_CATEGORY[data.category]}>
                                    {GROUPS_CATEGORY[data.category]}
                                </Badge>
                                <HStack spacing={1} mr='10px'>
                                    <Span
                                        borderRadius='12px'
                                        h='6px'
                                        w='6px'
                                        bg={data.type === 'public'
                                            ? COLOR_NAMES.greenA5DC86
                                            : COLOR_NAMES.redF27474} />
                                    <Span fontSize='14px' variant={data.type === 'public'
                                        ? VARIANT_KEYS.color.color_gray2C3D23_greenA5DC86
                                        : VARIANT_KEYS.color.color_gray9E9E9E_redF27474}>
                                        {data.type}
                                    </Span>
                                </HStack>
                                <HStack spacing={1}>
                                    <Span variant={VARIANT_KEYS.color.color_gray757575_grayC4C4C4}>
                                        <Flex>
                                            <ExternalIcon icon={MdPeopleAlt} styling={{ w: '16px', h: '16px' }} />
                                        </Flex>
                                    </Span>
                                    <Span fontSize='14px' variant={VARIANT_KEYS.color.color_gray757575_grayC4C4C4}>
                                        {data.membersCount}
                                    </Span>
                                </HStack>
                            </Flex>

                            {media.isLargerThan750 && <Div mt='5px' w='100%' textAlign='start'>{data.name}</Div>}
                        </Div>
                        {media.isLargerThan750
                            ? (<Div>
                                {user.id !== data.ownerId
                                    ? <div ref={followRef}>
                                        {getButtonGroup()}
                                    </div>
                                    : <div ref={followRef}>
                                        <WrapperButton
                                            styling={{
                                                variant: VARIANT_KEYS.bgAndColor.bg_transparent_color_gray686868_border_1px_686868,
                                                pr: '16px',
                                                pl: '16px',
                                                pt: '10px',
                                                pb: '10px',
                                                fontSize: '16px',
                                                h: '40px',
                                                fontWeight: FONTS.normal[500],
                                                w: '100%',
                                            }}
                                            message={'Owner'} />
                                    </div>
                                }
                            </Div>)
                            : (
                                <HStack h={'100%'} alignItems={'center'} spacing={0}>
                                    <Div w='100%' textAlign='start'>{data.name}</Div>
                                    {user.id !== data.ownerId
                                        ? <div ref={followRef}>
                                            {getButtonGroup()}
                                        </div>
                                        : <div ref={followRef}>
                                            <WrapperButton
                                                styling={{
                                                    variant: VARIANT_KEYS.bgAndColor.bg_transparent_color_gray686868_border_1px_686868,
                                                    pr: '16px',
                                                    pl: '16px',
                                                    pt: '10px',
                                                    pb: '10px',
                                                    fontSize: '16px',
                                                    h: '38px',
                                                    fontWeight: FONTS.normal[500],
                                                    w: '138px',
                                                }}
                                                message={'Owner'} />
                                        </div>
                                    }
                                </HStack>
                            )
                        }
                    </Flex>
                </VStack>
            </Div>
        </div>
    )
}

export default Group;
