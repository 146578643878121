import { mode } from "@chakra-ui/theme-tools";
import { COLOR_NAMES, FONTS, FONT_FAMILY_NAME } from '../../shared/constants';

export const MultiSelect = {
    baseStyle: {
        control: (styles) => ({
            ...styles,
            backgroundColor: mode('', COLOR_NAMES.black262626),
            border: `1px solid ${COLOR_NAMES.gray666666}`,
            ':hover': { border: `1px solid ${COLOR_NAMES.gray666666}` },
            ':focusVisible': { border: `1px solid ${COLOR_NAMES.gray666666}` },
            boxShadow: 'none',
            borderRadius: '4px',
            minHeight: '100px'
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: mode('', COLOR_NAMES.black262626),
                ':hover': { backgroundColor: COLOR_NAMES.gray3D403D },
            };
        },
        dropdownIndicator: (styles) => {
            return {
                ...styles,
                color: mode('', COLOR_NAMES.gray666666),
                ':hover': { color: COLOR_NAMES.gray666666 },
            };
        },
        menu: (styles) => {
            return { ...styles, backgroundColor: mode('', COLOR_NAMES.black262626) };
        },
        indicatorSeparator: (styles) => {
            return { ...styles, backgroundColor: COLOR_NAMES.black262626 };
        },
        clearIndicator: (styles) => {
            return {
                ...styles,
                ':hover': { color: 'white' },
            };
        },
        input: (styles) => {
            return {
                ...styles,
                color: mode('', 'white'),
                fontFamily: FONT_FAMILY_NAME,
                fontWeight: FONTS.normal[300].fontWeight,
                letterSpacing: '1.1px'
            };
        },
        placeholder: (styles) => {
            return {
                ...styles,
                fontFamily: FONT_FAMILY_NAME,
                fontWeight: FONTS.normal[300].fontWeight,
                letterSpacing: '1.1px',
            };
        },
        loadingIndicator: (styles) => {
            return {
                ...styles,
                fontFamily: FONT_FAMILY_NAME,
            };
        },
        multiValue: (styles) => {
            return {
                ...styles,
                cursor: 'pointer',
                backgroundColor: mode(COLOR_NAMES.gray5e5e5e, COLOR_NAMES.gray3D403D),
            };
        },
        multiValueRemove: (styles) => {
            return {
                ...styles,
                ':hover': { color: 'white' },
                cursor: 'pointer',
            };
        },
    },
    defaultProps: {},
}

export default MultiSelect;
