import axios from './axios.service';

const DEFAULT = '/groups';
const CHECK_IF_USER_IS_OWNER_OF_GROUP_URL = `${DEFAULT}/check-auth-user-is-owner`;
const JOIN_TO_GROUP = `${DEFAULT}/join-to-group`;
const UNJOIN_GROUP = `${DEFAULT}/unjoin-group`;
const DELETE = `${DEFAULT}/delete`;
const INVITE_USER_AS_ADMIN = `${DEFAULT}/invite-user-as-admin`;
const REMOVE_INVITATION_FOR_ADMIN = `${DEFAULT}/remove-invitation-for-admin`;
const REMOVE_MEMBER = `${DEFAULT}/remove-member`;
const REMOVE_INVITATION_FOR_USER = `${DEFAULT}/remove-invitation-for-user`;
const MAKE_ADMIN_AS_MEMBER = `${DEFAULT}/make-admin-as-member`;
const ROLE_IN_GROUP = `${DEFAULT}/role-in-group`;
const REJECT = `${DEFAULT}/reject`;
const ACCEPT = `${DEFAULT}/accept`;
const APPLY = `${DEFAULT}/apply`;
const MUTE_MEMBER = `${DEFAULT}/mute-member`;
const UNMUTE_MEMBER = `${DEFAULT}/unmute-member`;
const REPORT_GROUP = `${DEFAULT}/report`;
const ACCEPT_INVITE_TO_GROUP = `${DEFAULT}/accept-invite-to-group`;
const DENY_INVITE_TO_GROUP = `${DEFAULT}/deny-invite-to-group`;
const ACCEPT_INVITE_AS_ADMIN = `${DEFAULT}/accept-invite-as-admin`;
const REJECT_INVITE_AS_ADMIN = `${DEFAULT}/reject-invite-as-admin`;

class GroupService {
    async reportGroupAsync(id, reason) {
        return axios.post(`${REPORT_GROUP}/${id}`, { reason: reason });
    }

    async findGroupsAsync(topic, search, page, limit = 5, options) {
        return axios.get(
            DEFAULT,
            {
                ...options,
                params: {
                    topic: topic,
                    search: encodeURIComponent(search),
                    page: page,
                    limit: limit,
                },
            });
    }

    async roleInGroupAsync(id) {
        return axios.get(`${ROLE_IN_GROUP}/${id}`);
    }

    async createAsync(payload) {
        return axios.post(DEFAULT, payload);
    }

    async deleteAsync(groupId) {
        return axios.post(DELETE, { groupId: groupId });
    }

    async editAsync(payload, groupId) {
        return axios.put(`${DEFAULT}/${groupId}`, payload);
    }

    async findGroupsByCategoryAsync(search, page = 1, limit = 6, options) {
        return this.findGroupsAsync('category', search, page, limit, options);
    }

    async findGroupsByNameAsync(search, page = 1, limit = 6, options) {
        return this.findGroupsAsync('name', search, page, limit, options);
    }

    async checkIfAuthUserIsOwnerAsync(groupId) {
        return axios.get(CHECK_IF_USER_IS_OWNER_OF_GROUP_URL, { params: { groupId: groupId } });
    }

    async getGroupByIdAsync(id) {
        return axios.get(`${DEFAULT}/${id}`);
    }

    async inviteMembersToGroupAsync(payload) {
        return axios.patch(DEFAULT, payload);
    }

    async muteMemberAsync(groupId, userId) {
        return axios.post(MUTE_MEMBER, { groupId: groupId, userId: userId });
    }

    async unmuteMemberAsync(groupId, userId) {
        return axios.post(UNMUTE_MEMBER, { groupId: groupId, userId: userId });
    }

    async removeMemberAsync(groupId, userId) {
        return axios.post(REMOVE_MEMBER, { groupId: groupId, userId: userId });
    }

    async removeInvitationForUserAsync(groupId, userId) {
        return axios.post(REMOVE_INVITATION_FOR_USER, { groupId: groupId, userId: userId });
    }

    async removeInvitationForAdminAsync(groupId, userId) {
        return axios.post(REMOVE_INVITATION_FOR_ADMIN, { groupId: groupId, userId: userId });
    }

    async rejectUserFromGroupAsync(groupId, notificationId) {
        return axios.post(REJECT, { groupId: groupId, notificationId });
    }

    async acceptUserToGroupAsync(groupId, notificationId) {
        return axios.post(ACCEPT, { groupId: groupId, notificationId: notificationId });
    }

    async applyToGroupAsync(groupId) {
        return axios.post(APPLY, { groupId: groupId });
    }

    async changeRoleFromAdminToMemberAsync(groupId, userId) {
        return axios.post(MAKE_ADMIN_AS_MEMBER, { groupId: groupId, userId: userId });
    }

    async inviteUserAsAdminAsync(groupId, userId) {
        return axios.post(INVITE_USER_AS_ADMIN, { groupId: groupId, userId: userId });
    }

    async joinToGroup(groupId) {
        return axios.post(JOIN_TO_GROUP, { groupId: groupId });
    }

    async unjoinToGroup(groupId) {
        return axios.post(UNJOIN_GROUP, { groupId: groupId });
    }

    async acceptInvite(groupId, notificationId) {
        return axios.post(ACCEPT_INVITE_TO_GROUP, { groupId: groupId, notificationId: notificationId });
    }
    
    async denyInvite(groupId, notificationId) {
        return axios.post(DENY_INVITE_TO_GROUP, { groupId: groupId, notificationId: notificationId });
    }

    async acceptInviteAsAdmin(groupId, notificationId) {
        return axios.post(ACCEPT_INVITE_AS_ADMIN, { groupId: groupId, notificationId: notificationId });
    }

    async rejectInviteAsAdmin(groupId, notificationId) {
        return axios.post(REJECT_INVITE_AS_ADMIN, { groupId: groupId, notificationId: notificationId });
    }
}

const groupService = new GroupService();

export default groupService;
