import React, { Fragment, useEffect, useState, } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex, HStack, VStack, Image, Divider, Badge, useMediaQuery, useDisclosure } from '@chakra-ui/react';
import Div from '../components/html-elements/div.component';
import Span from '../components/html-elements/span.component';
import WrapperButtonLink from '../components/buttons/wrapper-button-link.component';
import ExternalIcon from '../components/icons/external-icon.component';
import ControlMenuDekstop from '../components/shared/control-menu-dekstop.component';
import ControlMenuMobile from '../components/shared/control-menu-mobile.component';
import ControlMenuModal from '../components/shared/control-menu-modal.component';
import Link from '../components/html-elements/link.component';
import Dialog from '../components/shared/dialog.component';
import ReportReason from '../components/shared/report-reason.component';
import PostsFeed from '../components/posts/posts-feed';
import useConfigurations from '../shared/hooks/configurations.hook';
import groupService from '../services/group.service';
import postService from '../services/post.service';
import toastService from '../services/toast.service';
import {
    ROUTES,
    VARIANT_KEYS,
    FONTS,
    COLOR_NAMES,
    MEDIA,
    FONT_FAMILY_NAME,
    BG_GROUPS_CATEGORY,
    GROUPS_CATEGORY,
    GROUP_ROLES,
    STATIC_URL,
    POST_TYPES,
    CONFIGURATION_KEYS,
} from '../shared/constants';
import imagePeople from '../static/images/people.svg';
import { MdPeopleAlt, MdOutlineSettings, MdFlag } from 'react-icons/md';
import { CgArrowRightR } from 'react-icons/cg';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { BiDotsHorizontalRounded } from 'react-icons/bi';

function GroupDetailsPage() {
    const params = useParams();
    const { configurations } = useConfigurations();
    const navigate = useNavigate();

    const [groupInfo, setGroupInfo] = useState(null);

    const [isLargerThan750] = useMediaQuery(MEDIA.MIN_WIDTH_750_PX);

    const initilizeGroupData = async () => {
        const id = params.id;

        try {
            const response = await groupService.getGroupByIdAsync(id);
            const data = response.data.data;
            setGroupInfo(data);
        } catch (err) {
            // notification
            console.log(err.message);
        }
    }

    useEffect(() => {
        if (!params || !params.id) {
            navigate(ROUTES.HOME);

            return;
        }
        initilizeGroupData();
    }, [])

    useEffect(() => {
        if (!configurations[CONFIGURATION_KEYS.groupsPosting].isActive) {
            toastService.warning(configurations[CONFIGURATION_KEYS.groupsPosting].messageUnavailable);
        }
    }, [configurations])

    const isOwner = () => {
        return groupInfo && groupInfo.role === GROUP_ROLES.OWNER;
    };

    const isMember = () => {
        return groupInfo && (groupInfo.role === GROUP_ROLES.MEMBER || groupInfo.isMember);
    };

    const isAdmin = () => {
        return groupInfo && groupInfo.role === GROUP_ROLES.ADMIN;
    };

    const [isLargerThan550] = useMediaQuery(MEDIA.MIN_WIDTH_550_PX);

    const { isOpen: isDrrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure()
    const { isOpen: isReasonOpen, onOpen: onReasonOpen, onClose: onReasonClose } = useDisclosure();
    const { isOpen: isDeleteopen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
    const { isOpen: isLeaveopen, onOpen: onLeaveOpen, onClose: onLeaveClose } = useDisclosure();

    const leaveGroup = async () => {
        try {
            await groupService.unjoinToGroup(groupInfo.id);

            navigate(ROUTES.HOME);
        } catch (error) {
            console.log(error);
        }
    }

    const deleteGroup = async () => {
        try {
            const response = await groupService.deleteAsync(groupInfo.id);
            toastService.success(response.data.data);
            navigate(ROUTES.HOME);
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    const reportGroup = async (reason) => {
        try {
            const { data, isSuccess, errorMessage } = (await groupService.reportGroupAsync(groupInfo.id, reason)).data;
            if (isSuccess) {
                setGroupInfo(prev => ({ ...prev, reportedByMeOn: new Date(Date.now()).toLocaleString() }))
            };
            // Notify
            toastService.success(data);
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }

        onReasonClose();
    }

    const reportGroupMobile = async (reason) => {
        await reportGroup(reason);

        onCloseResonHandleMobile();
    }

    const onCloseResonHandleMobile = () => {
        onReasonClose();
        onDrawerClose();
    }

    const onDeleteCloseHandleMobile = () => {
        onDeleteClose();
        onDrawerClose();
    }

    const onLeaveCloseHandleMobile = () => {
        onLeaveClose();
        onDrawerClose();
    }

    const reportReason = groupInfo && <HStack onClick={onReasonOpen}>
        <Flex alignItems={'center'} h='100%'><ExternalIcon styling={{ w: '24px', h: '24px' }} icon={MdFlag} /></Flex>
        {!groupInfo.reportedByMeOn ?
            <Fragment>
                <VStack spacing={0}>
                    <Div textAlign='start' w='100%' fontSize='14px'>Report group</Div>
                </VStack>
                <ControlMenuModal isLargerThan550={isLargerThan550} isOpen={isReasonOpen} onClose={onCloseResonHandleMobile}>
                    <ReportReason handleOnClick={isLargerThan550 ? reportGroup : reportGroupMobile} />
                </ControlMenuModal>
            </Fragment>
            : <Div textAlign='start' w='100%' fontSize='14px' opacity='50%'>{`Reported on: ${new Date(groupInfo.reportedByMeOn).toLocaleString()}`}</Div>}
    </HStack>

    const membersLink = <Link to={`${ROUTES.MEMBERS}/${params.id}`}>
        <HStack>
            <Flex alignItems={'center'} h='100%'>
                <ExternalIcon styling={{ w: '24px', h: '24px' }} icon={MdPeopleAlt} />
            </Flex>
            <VStack spacing={0}>
                <Div textAlign='start' w='100%' fontSize='14px'>Members</Div>
            </VStack>
        </HStack>
    </Link>

    const mainControlContent = <Flex pl='20px' justifyContent={'space-around'} direction={'column'} h='100%'>
        {isOwner() &&
            <Fragment>
                <Link to={`${ROUTES.EDIT_GROUP}/${params.id}`}>
                    <HStack>
                        <Flex alignItems={'center'} h='100%'>
                            <ExternalIcon styling={{ w: '24px', h: '24px' }} icon={MdOutlineSettings} />
                        </Flex>
                        <VStack spacing={0}>
                            <Div textAlign='start' w='100%' fontSize='14px'>Settings</Div>
                        </VStack>
                    </HStack>
                </Link>
                {membersLink}
                <HStack onClick={onDeleteOpen} h='36px'>
                    <Flex alignItems={'center'} h='100%'>
                        <ExternalIcon styling={{ w: '24px', h: '24px' }} icon={RiDeleteBin5Fill} />
                    </Flex>
                    <Div fontSize='14px'>Delete group</Div>
                    <Dialog
                        isOpen={isDeleteopen}
                        onClose={isLargerThan550 ? onDeleteClose : onDeleteCloseHandleMobile}
                        onAction={deleteGroup}
                        messageBody={`Are you shure you want to delete selected group`}
                        messageHeader={`Delete group`} />
                </HStack>
            </Fragment>
        }

        {isAdmin() && membersLink}

        {isMember() && !isOwner() &&
            <Fragment>
                <HStack onClick={onLeaveOpen} h='36px'>
                    <Flex alignItems={'center'} h='100%'>
                        <ExternalIcon styling={{ w: '24px', h: '24px' }} icon={CgArrowRightR} />
                    </Flex>
                    <Div fontSize='14px'>Leave this group</Div>
                    <Dialog
                        isOpen={isLeaveopen}
                        onClose={isLargerThan550 ? onLeaveClose : onLeaveCloseHandleMobile}
                        onAction={leaveGroup}
                        messageAction={'Leave'}
                        messageBody={`Are you shure you want to leave this group`}
                        messageHeader={`Leave group`} />
                </HStack>
                {reportReason}
            </Fragment>
        }
    </Flex>

    const reasonControlContent = <Flex pl='20px' justifyContent={'space-around'} direction={'column'} h='100%'>{reportReason}</Flex>

    const dekstopControl = isMember()
        ? <ControlMenuDekstop isLargerThan550={isLargerThan550} h={'140px'}>
            {mainControlContent}
        </ControlMenuDekstop>
        : <ControlMenuDekstop isLargerThan550={isLargerThan550} h={'140px'}>
            {reasonControlContent}
        </ControlMenuDekstop>

    const mobileControl = isMember()
        ? <ControlMenuMobile
            drawwerButton={<ExternalIcon onClick={onDrawerOpen} styling={{ fontSize: '26px' }} icon={BiDotsHorizontalRounded} />}
            isDrrawerOpen={isDrrawerOpen}
            onDrawerClose={onDrawerClose}
            h={'140px'}>
            {mainControlContent}
        </ControlMenuMobile>
        : <ControlMenuMobile
            drawwerButton={<ExternalIcon onClick={onDrawerOpen} styling={{ fontSize: '26px' }} icon={BiDotsHorizontalRounded} />}
            isDrrawerOpen={isDrrawerOpen}
            onDrawerClose={onDrawerClose}
            h={'140px'}>
            {reasonControlContent}
        </ControlMenuMobile>

    return (
        <Div minH={'725px'}>
            {
                groupInfo &&
                (
                    <Fragment>
                        <Div h={'420px'}>
                            <Div h='190px'>
                                <Image
                                    objectFit={'cover'}
                                    position={'relative'}
                                    bottom={isLargerThan750 ? '50px' : '0px'}
                                    borderRadius='8px'
                                    src={groupInfo.img_url
                                        ? `${STATIC_URL}/${groupInfo.img_url}`
                                        : imagePeople}
                                    w={'100%'}
                                    h={'240px'}
                                    alt='' />
                            </Div>
                            <Div position='relative' top='50px' >
                                <HStack w={'100%'} letterSpacing={'1.1px'} mt={isLargerThan750 ? '0px' : '34px'} pl='24px' pr='24px'>
                                    <HStack spacing={5} w='100%'>
                                        <Div wordBreak={'break-word'} textAlign='start' fontSize={isLargerThan750 ? '31px' : '24px'}>
                                            <Span variant={VARIANT_KEYS.color.color_gray424242_orangeFFB81C}>
                                                {groupInfo.name}
                                            </Span>
                                        </Div>
                                        <Div>
                                            <Badge
                                                textTransform={'inherit'}
                                                fontFamily={FONT_FAMILY_NAME}
                                                fontWeight={FONTS.normal[500]}
                                                pt='1px'
                                                pb='18px'
                                                pl='6px'
                                                pr='6px'
                                                h='16px'
                                                fontSize={'12px'}
                                                color='white'
                                                borderRadius='6px'
                                                bg={BG_GROUPS_CATEGORY[groupInfo.category]}>
                                                {GROUPS_CATEGORY[groupInfo.category]}
                                            </Badge>
                                        </Div>
                                        <HStack spacing={1}>
                                            <Span
                                                borderRadius='12px'
                                                h='6px'
                                                w='6px'
                                                bg={groupInfo.type === 'public'
                                                    ? COLOR_NAMES.greenA5DC86
                                                    : COLOR_NAMES.redF27474} />
                                            <Span fontSize='14px' variant={groupInfo.type === 'public'
                                                ? VARIANT_KEYS.color.color_gray2C3D23_greenA5DC86
                                                : VARIANT_KEYS.color.color_gray9E9E9E_redF27474}>
                                                {groupInfo.type}
                                            </Span>
                                        </HStack>
                                        <HStack spacing={1}>
                                            <Span variant={VARIANT_KEYS.color.color_gray757575_grayC4C4C4}>
                                                <Flex>
                                                    <ExternalIcon icon={MdPeopleAlt} styling={{ w: '16px', h: '16px' }} />
                                                </Flex>
                                            </Span>
                                            <Span fontSize='14px' variant={VARIANT_KEYS.color.color_gray757575_grayC4C4C4}>
                                                {groupInfo.membersCount}
                                            </Span>
                                        </HStack>
                                    </HStack>
                                </HStack>
                                <HStack letterSpacing={'1.1px'} mt='14px' alignItems={'start'} pl='24px' pr='24px'>
                                    <Span variant={VARIANT_KEYS.color.color_gray757575_grayC4C4C4}>{groupInfo.description}</Span>
                                </HStack>
                                {isMember()
                                    ? <HStack w='100%' justifyContent={'space-between'} letterSpacing={'1.1px'} mt='34px' spacing={6} pl='24px' pr='24px'>
                                        <WrapperButtonLink
                                            styling={{
                                                h: '36px',
                                                borderRadius: '4px',
                                                variant: VARIANT_KEYS.bgAndColor.bg_transparent_color_black141414_grayC4C4C4_border_1px_FFB81C,
                                                fontWeight: FONTS.normal[300],
                                                disabled: !configurations[CONFIGURATION_KEYS.groupsPosting].isActive
                                            }}
                                            disabled={!configurations[CONFIGURATION_KEYS.groupsPosting].isActive}
                                            to={ROUTES.CREATE_POST}
                                            state={{ groupId: params.id }}
                                            message={'Create a post'} />
                                        {isLargerThan550
                                            ? dekstopControl
                                            : mobileControl
                                        }
                                    </HStack>
                                    : <Flex w='100%' justifyContent={'end'} letterSpacing={'1.1px'} mt='34px' pl='24px' pr='24px'>
                                        {isLargerThan550
                                            ? dekstopControl
                                            : mobileControl
                                        }
                                    </Flex>
                                }
                                <Divider mt={'35px'} bg={COLOR_NAMES.grayC4C4C4} />
                            </Div>
                        </Div>
                        {groupInfo.type === 'private' && !groupInfo.isMember
                            ? <Div position='relative' top={isLargerThan550 ? '30px' : '80px'}>
                                You don`t see content in this group because you are not a member, apply for it!
                            </Div>
                            : <Div mt={isLargerThan750 ? '40px' : '80px'}>
                                <PostsFeed
                                    type={POST_TYPES.GROUP}
                                    getPostsFn={postService.getPostsInGroupAsync}
                                    moreParamsForGetPostsFn={[params.id]}
                                    group={{ id: params.id, isAdmin: isAdmin(), isMember: isMember(), isOwner: isOwner() }} />
                            </Div>}
                    </Fragment>
                )
            }
        </Div>
    )
}

export default GroupDetailsPage;
