import React from 'react';
import { useLocation } from 'react-router-dom';
import { VStack } from '@chakra-ui/react'
import WrapperButtonNavLink from '../buttons/wrappr-button-nav-link.component';
import ExternalIcon from '../icons/external-icon.component';
import NotificationBadge from '../notifications/notification-badge.component';
import Div from '../html-elements/div.component';
import useNotificationsCount from '../../shared/hooks/useNotificationsCount.hook';
import { ROUTES, FONTS, VARIANT_KEYS } from '../../shared/constants';
import { MdPermContactCalendar, MdNotificationsActive, MdFavorite, MdGroups, MdPersonAddAlt } from 'react-icons/md';
import { AiFillHome } from 'react-icons/ai';

function SideBar() {
    const location = useLocation();
    const path = location.pathname;
    const { hasNewNotifications } = useNotificationsCount();

    const routingInfos = [
        {
            message: 'Home',
            icon: <ExternalIcon icon={AiFillHome} />,
            routing: ROUTES.HOME,
            isActive: ROUTES.HOME === path,
        },
        {
            message: 'Contacts',
            icon: <ExternalIcon icon={MdPermContactCalendar} />,
            routing: ROUTES.CONTACTS,
            isActive: ROUTES.CONTACTS === path || path.startsWith(ROUTES.USER_PROFILE),
        },
        {
            message: 'Notifications',
            icon: <ExternalIcon icon={MdNotificationsActive} />,
            routing: ROUTES.NOTIFICATIONS,
            isActive: ROUTES.NOTIFICATIONS === path,
        },
        {
            message: 'Liked',
            icon: <ExternalIcon icon={MdFavorite} />,
            routing: ROUTES.LIKED,
            isActive: ROUTES.LIKED === path,
        },
        {
            message: 'Groups',
            icon: <ExternalIcon icon={MdGroups} />,
            routing: ROUTES.GROUPS,
            isActive: ROUTES.GROUPS === path ||
                ROUTES.CREATE_GROUP === path ||
                path.startsWith(ROUTES.EDIT_GROUP) ||
                path.startsWith(ROUTES.GROUP_DETAILS),
        },
        {
            message: 'Onboarding',
            icon: <ExternalIcon icon={MdPersonAddAlt} />,
            routing: ROUTES.ONBOARDING,
        },
    ]

    const styling = {
        fontWeight: FONTS.normal[400],
        variant: VARIANT_KEYS.navLink.white,
        fontSize: '16px',
        height: 'auto',
        lineheight: '28px',
        letterSpacing: 1.1,
    }

    const navigations = routingInfos.map((info, i) => {
        return (
            <Div key={i} position='relative' >
                {
                    info.message === 'Notifications' && hasNewNotifications
                        ? <NotificationBadge style={{ w: '15px', h: '15px', fontSize: '8px', top: '0px', left: '22px' }}
                            notificationsCount={hasNewNotifications} />
                        : null
                }
                <WrapperButtonNavLink
                    to={info.routing}
                    message={info.message}
                    isActive={info.isActive}
                    styling={{ ...styling, leftIcon: info.icon }} />
            </Div>)
    })

    return (
        <VStack spacing={4} alignItems="start">
            {navigations}
        </VStack>
    )
}

export default SideBar;
