import React, { useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useStyleConfig } from '@chakra-ui/react';

const sleep = ms =>
    new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, ms);
    });

// Make for light color mode
function Multiselect({ loadOptions, onChange, placeholder, noOptionsMessage, debounceTime }) {
    const [inputValue, setInputValue] = useState([]);
    const styles = useStyleConfig('MultiSelect', {});


    const debounce = async (search, loadedOptions, { page }) => {
        await sleep(debounceTime || 0);

       return await loadOptions(search, loadedOptions, page);
    }

    // Prevent close
    const onInputChange = (str, { action }) => {
        if (action === 'set-value') {
            return false;
        }

        setInputValue(str);
    };

    return (
        <AsyncPaginate
            styles={styles}
            inputValue={inputValue}
            loadOptions={debounce}
            onChange={onChange}
            formatOptionLabel={(option, { context }) =>
                context === 'menu' ? option.label : option.selectedItem ? option.selectedItem : option.label}
            isMulti
            additional={{ page: 1 }}
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            placeholder={placeholder}
            isOptionDisabled={(option) => option.disabled}
            noOptionsMessage={() => noOptionsMessage}
            onInputChange={onInputChange} />
    )
}

export default Multiselect;
