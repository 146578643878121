import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Circle, Flex, HStack, useColorModeValue, useOutsideClick } from '@chakra-ui/react';
import WrapperButtonLink from '../../components/buttons/wrapper-button-link.component';
import WrapperButtonNavLink from '../buttons/wrappr-button-nav-link.component';
import WrapperButton from '../buttons/wrapper-button.component';
import ExternalIcon from '../icons/external-icon.component';
import NotificationBadge from '../notifications/notification-badge.component';
import useNotificationsCount from '../../shared/hooks/useNotificationsCount.hook';
import { ROUTES, VARIANT_KEYS, COLOR_NAMES, FONTS } from '../../shared/constants';
import { MdHome, MdPermContactCalendar, MdFavorite, MdNotificationsActive, MdOutlineAdd, MdGroups } from 'react-icons/md';

function BottomMobileNavigation() {
    const [isButtonShown, setIsButtonShown] = useState(false);
    const ref = useRef();
    const iconButtonBgColor = useColorModeValue(COLOR_NAMES.oragneFDB71A, COLOR_NAMES.oragneFFB81C);
    const iconButtonColor = useColorModeValue('black', 'white');
    const bgColorTemplate = useColorModeValue(COLOR_NAMES.grayE2E2E2, COLOR_NAMES.black161717);

    const location = useLocation();
    const path = location.pathname;

    const { hasNewNotifications } = useNotificationsCount();

    useOutsideClick({
        ref: ref,
        handler: () => setTimeout(() => setIsButtonShown(false), 100),
    });

    const circle = <Circle size='45px' bg={iconButtonBgColor} color={iconButtonColor}>
        <ExternalIcon icon={MdOutlineAdd} styling={{ w: '26px', h: '28px' }} />
    </Circle>

    const stylingNavigationIcon = { w: '26px', h: '24px' };

    const routingInfos = [
        {
            message: <ExternalIcon icon={MdHome} styling={stylingNavigationIcon} />,
            routing: ROUTES.HOME,
            isActive: ROUTES.HOME === path,
        },
        {
            message: <ExternalIcon icon={MdPermContactCalendar} styling={stylingNavigationIcon} />,
            routing: ROUTES.CONTACTS,
            isActive: ROUTES.CONTACTS === path || path.startsWith(ROUTES.USER_PROFILE),
        },
        {
            message: <ExternalIcon icon={MdNotificationsActive} styling={stylingNavigationIcon} />,
            routing: ROUTES.NOTIFICATIONS,
            isActive: ROUTES.NOTIFICATIONS === path,
        },
        {
            message: <ExternalIcon icon={MdFavorite} styling={stylingNavigationIcon} />,
            routing: ROUTES.LIKED,
            isActive: ROUTES.LIKED === path,
        },
        {
            message: <ExternalIcon icon={MdGroups} styling={stylingNavigationIcon} />,
            routing: ROUTES.GROUPS,
            isActive: ROUTES.GROUPS === path || path.startsWith(ROUTES.GROUP_DETAILS),
        },
    ]

    return (
        <HStack
            sx={{ position: 'fixed', bottom: '0px', w: '100%' }}
            bg={bgColorTemplate}
            justifyContent={'space-around'}
            h={'95px'} >

            {isButtonShown
                ? (
                    <HStack w='260px' spacing={0} justifyContent={'space-around'} position='absolute' bottom='120px'>
                        <WrapperButtonLink
                            to={ROUTES.CREATE_POST}
                            message={'New Post'}
                            styling={
                                {
                                    fontWeight: FONTS.normal[500],
                                    position: 'absolute',
                                    left: '2px',
                                    bottom: '-6px',
                                    borderRadius: '15px',
                                    h: '35px',
                                    w: '120px',
                                    variant: VARIANT_KEYS.bgAndColor.bg_greenA9E5B6_green54A565_color_black141414_whiteFFFFFF,
                                }} />
                        <WrapperButtonLink
                            to={ROUTES.CREATE_GROUP}
                            message={'New Group'}
                            styling={
                                {
                                    fontWeight: FONTS.normal[500],
                                    position: 'absolute',
                                    bottom: '-6px',
                                    right: '1px',
                                    borderRadius: '15px',
                                    h: '35px',
                                    w: '120px',
                                    variant: VARIANT_KEYS.bgAndColor.bg_blue9ED0F3_blue5483A5_color_black141414_whiteFFFFFF,
                                }} />
                    </HStack>)
                : null
            }

            <div style={{ position: 'absolute', bottom: '75px', margin: '0px' }} ref={ref}>
                <WrapperButton
                    onClickHandle={() => setIsButtonShown(true)}
                    styling={{ borderRadius: 'full', p: '0px' }}
                    message={circle} />
            </div>

            {
                routingInfos.map((info, i) => {
                    return <Flex position='relative' key={`${info.routing}${i}`}>
                        <WrapperButtonNavLink
                            to={info.routing}
                            message={info.message}
                            isActive={info.isActive}
                            styling={{ variant: VARIANT_KEYS.navLink.white, p: '0px' }} />
                        {
                            hasNewNotifications && info.routing === ROUTES.NOTIFICATIONS
                                ? <NotificationBadge style={{ top: '0px', right: '0px' }}
                                    notificationsCount={hasNewNotifications} />
                                : null
                        }
                    </Flex>

                })
            }
        </HStack>
    )
}

export default BottomMobileNavigation;
