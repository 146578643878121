import React from 'react';
import Div from '../html-elements/div.component';

function LargeContainerComponent({ media, children }) {
    return (
        <Div
            pt={'0px'}
            align='center'
            margin='auto'
            overflowY={!media.isLargerThan750 ? 'auto' : 'none'}
            w='100%'
            h={'100%'}
            bg={'inherit'}>
            {children}
        </Div>
    )
}

export default LargeContainerComponent;
