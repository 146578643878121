import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import GroupActionPage from './group-action-page.component';
import groupService from '../services/group.service';
import { ROUTES } from '../shared/constants';

function GroupEditPage() {
    const params = useParams();
    const navigate = useNavigate();
    const [group, setGroup] = useState(null);

    const initilizeGroupData = async () => {
        try {
            if (!params || !params.id) {
                navigate(ROUTES.HOME);
            }

            const responseIsOwner = await groupService.checkIfAuthUserIsOwnerAsync(params.id);
            if (!responseIsOwner.data.data) {
                navigate(ROUTES.HOME);

                return;
            }

            const responseGroup = await groupService.getGroupByIdAsync(params.id);
            setGroup(responseGroup.data.data);
        } catch (err) {
            console.log(err);
            navigate(ROUTES.HOME);
        }
    }

    useEffect(() => {
        initilizeGroupData();
    }, [])

    return <GroupActionPage dataPreview={{ ...group }} />
}

export default GroupEditPage;
