import React from 'react';
import { Flex } from '@chakra-ui/react';
import Group from './group.component';

function GroupList({ data, toggleJoinToGroup, applyToPrivateGroup }) {
    return (
        <Flex rowGap={'25px'} justifyContent={'space-between'} flexWrap={'wrap'}>
            {data.map((d) =>
                <Group
                    key={`${d.id}_group`}
                    toggleJoinToGroup={toggleJoinToGroup}
                    applyToPrivateGroup={applyToPrivateGroup}
                    data={d} />
            )}
        </Flex>
    )
}

export default GroupList;
