import React from 'react';
import { Input as CustomInput } from '@chakra-ui/react';
import Input from './input.component';

function FormInput({
    name,
    type = 'text',
    label,
    placeholder,
    isRequired,
    isDisabled,
    isReadOnly,
    inputStyling,
    errorStyling,
    labelStyling,
    formikProps
}) {
    return <Input
        element={CustomInput}
        name={name}
        type={type}
        label={label}
        placeholder={placeholder}
        isRequired={isRequired}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        inputStyling={inputStyling}
        errorStyling={errorStyling}
        labelStyling={labelStyling}
        formikProps={formikProps} />
}

export default FormInput;
