import React from 'react';
import { Box } from '@chakra-ui/react';
import { FONT_FAMILY_NAME } from '../../shared/constants';

function WrapperTextBox({ message, styling }) {
    const { fontWeight, as, ...rest } = styling || {};

    return (
        <Box
            {...as || { as: 'span' }}
            fontWeight={fontWeight}
            fontFamily={FONT_FAMILY_NAME}
            {...rest}>
            {message}
        </Box>)
}

export default WrapperTextBox;
