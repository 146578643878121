import React from 'react';
import { Link } from 'react-router-dom';
import { HStack, Avatar, VStack } from '@chakra-ui/react'
import Div from '../html-elements/div.component';
import Span from '../html-elements/span.component';
import { STATIC_URL, VARIANT_KEYS, FONTS } from '../../shared/constants';

const RecentSearches = ({ queries }) => {
    return (
        <Div>
            <Span
                variant={VARIANT_KEYS.color.color_gray5E5E5E_grayB4B4B4}
                marginTop='20px'
                letterSpacing='1.1px'
                fontSize='12px'
                fontWeight={FONTS.normal[400]}>
                Recent searches
            </Span>
            <HStack justifyContent={'start'} marginTop={'15px'} spacing={3} flexWrap='wrap'>
                {queries.map((item, i) => (
                    <Link
                        key={item.name + i.toString()}
                        to={item.url}>
                        <VStack lineHeight='31px' marginBottom={4} w='75px'>
                            <Avatar name={item.name} bg={item.imgLink ? 'none' : item.avatar_color}
                                src={item.imgLink ? `${STATIC_URL}/${item.imgLink}` : ''} size={'md'} />
                            <Div
                                fontSize='14px'
                                height='28px'
                                lineHeight={'14px'}
                                fontWeight={FONTS.normal[300].fontWeight}
                                paddingRight='2px'
                                paddingLeft='2px'
                                textAlign='center'>
                                {`${item.name}`}
                            </Div>
                        </VStack>
                    </Link>
                ))}
            </HStack>
        </Div>
    );
};

export default RecentSearches;
