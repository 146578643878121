import React from 'react';
import { useLocation } from 'react-router-dom';
import { Flex, Avatar, InputLeftElement } from '@chakra-ui/react';
import MobileNavigationGoBackButton from '../buttons/mobile-navigation-go-back-button.component';
import Div from '../html-elements/div.component';
import WrapperButtonLink from '../buttons/wrapper-button-link.component';
import IdleTimerComponent from '../shared/IdleTimerComponent';
import Search from '../inputs/search-component';
import WrapperButton from '../buttons/wrapper-button.component';
import ExternalIcon from '../icons/external-icon.component';
import useAuth from '../../shared/hooks/auth.hook';
import { VARIANT_KEYS, ROUTES, COLOR_NAMES, FONTS, STATIC_URL } from '../../shared/constants';
import { buildTitleBaseOnPathname } from '../../shared/utils/common.utils';
import { MdSearch } from 'react-icons/md';

function TopMobileNavigation({ isLargeContainer, media, handleSetSearchText }) {
    const location = useLocation();
    const title = buildTitleBaseOnPathname(location.pathname, ROUTES);
    const { user } = useAuth();

    const avatarPicture = <Avatar name={user.name} bg={user.img_url ? 'none' : user.avatar_color}
        src={user.img_url ? `${STATIC_URL}/${user.img_url}` : ''} size={'xs'} />

    const handleSearchField = (e) => {
        const text = e.target.value.trim();
        handleSetSearchText(text);
    }

    const stylingPlaceholder = {
        fontSize: '13px',
        color: COLOR_NAMES.grayAFAFAF,
        fontWeight: FONTS.normal[300],
    }

    const stylingInputSearch = {
        variant: VARIANT_KEYS.bg.bg_grayE2E2E2_gray333333,
        fontSize: '13px',
        height: '48px',
        width: '100%',
        letterSpacing: '1.1px',
        fontWeight: FONTS.normal[400],
        pl: '40px',
        pr: '8px',
        placeholder: 'Search for posts',
        border: 'none',
    }

    const inputElemetLeft = <InputLeftElement h='100%'>
        <WrapperButton
            message={<ExternalIcon styling={{ w: '15px', h: '15px', color: COLOR_NAMES.grayAFAFAF, mt: '6px' }} icon={MdSearch} />}
            styling={{ variant: VARIANT_KEYS.bgAndColor.bg_transparent_color_black141414_whiteFFFFFF }}>
        </WrapperButton>
    </InputLeftElement>

    return (
        <Div variant={media && media.bgColorPage}>
            <IdleTimerComponent />
            <Flex
                p={isLargeContainer && '10px'}
                h={isLargeContainer && '116px'}
                mb={isLargeContainer ? '20px' : '32px'}
                justifyContent={'space-between'}
                alignItems={isLargeContainer ? 'end' : 'center'}
                direction={'row'}
                w={isLargeContainer ? '100%' : { sm: '360px', md: '470px' }}>
                {location.pathname === ROUTES.HOME
                    ? <Div w='40px'></Div>
                    : <MobileNavigationGoBackButton stlyling={{ fontSize: '22px' }} />
                }
                <Div fontSize='20px'>{title}</Div>
                {
                    !(location.pathname.includes('edit') || location.pathname.includes('create'))
                        ? <WrapperButtonLink
                            to={ROUTES.MY_PROFILE}
                            message={avatarPicture}
                            styling={
                                {
                                    marginRight: '3px',
                                    lineHeight: '10px',
                                    variant: VARIANT_KEYS.navLink.white,
                                    padding: '0px',
                                    paddingTop: isLargeContainer ? '5px' : '0px'
                                }} />

                        : <Div w='40px'></Div>
                }
            </Flex>
            {location.pathname === ROUTES.HOME &&
                <Div w='340px' mb='16px' pb='20px'>
                    <Search
                        inputLeftElement={inputElemetLeft}
                        placeholderStyling={stylingPlaceholder}
                        styling={stylingInputSearch}
                        handleChange={handleSearchField} />
                </Div>
            }
        </Div>
    )
}

export default TopMobileNavigation;
