import React from 'react';
import { Button } from '@chakra-ui/react';
import WrapperTextBox from './wrapper-text-box.component';

function WrapperButton({ onClickHandle, type, message, styling }) {
    const { fontWeight, leftIcon, rightIcon, ...rest } = styling || {};

    return (
        <Button
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            onClick={onClickHandle}
            type={type || 'button'}
            {...rest}>
            <WrapperTextBox message={message} styling={fontWeight}></WrapperTextBox>
        </Button>
    )
}

export default WrapperButton;
