import { useEffect } from 'react';
import axios from '../../services/axios.service';
import authService from '../../services/auth.service';
import useAuth from '../hooks/auth.hook';
import { ROUTES } from '../constants';

const useResponseInterceptor = () => {
    const { logout } = useAuth();

    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            (response) => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 401 && 
                    (error?.response?.data.errorMessage === 'Refresh Token has expired' || 
                    error?.response?.data.errorMessage === 'Refresh Token has been revoked')) {
                    await logout();
                    window.location.href = ROUTES.DEFAULT;

                    return;
                }

                // Just in case
                if (error?.response?.status === 401 && !prevRequest?._retry) {
                    prevRequest._retry = true;

                    const resposne = await authService.refreshTokenAsync();
                    if (!resposne.data.isSuccess) {
                        return Promise.reject(error);
                    }
                    
                    const accessToken = resposne.data.data;
                    authService.setToken(accessToken);
                    prevRequest.headers['Authorization'] = `Bearer ${accessToken}`;

                    return axios(prevRequest);
                }

                return Promise.reject(error);
            }
        );

        return () => axios.interceptors.response.eject(interceptor);
    }, [])
}

export default useResponseInterceptor;
