// Change in feature maybe we have to create new folder form and use there emlements
import React from 'react';
import { Field } from 'formik';
import { FormControl, Select, FormLabel } from '@chakra-ui/react';
import { COLOR_NAMES, FONT_FAMILY_NAME } from '../../shared/constants';

// It is possible to add colorMode here
function FormDropDown({ kvp, label, name, formikProps, styling }) {
    const keys = Object.keys(kvp);

    return (
        <Field>
            {({ field, form }) => (
                <FormControl>
                    <FormLabel fontFamily={FONT_FAMILY_NAME} fontSize={'14px'}>{label}</FormLabel>
                    <Select
                        {...styling}
                        onChange={field.onChange}
                        value={formikProps.values[name]}
                        name={name}>
                        {keys.map((k, i) => {
                            return <option
                                style={{ backgroundColor: COLOR_NAMES.gray333333 }}
                                key={`${i}_${k}`}
                                value={k}>
                                {kvp[k]}
                            </option>
                        })}
                    </Select>
                </FormControl>
            )}
        </Field>
    )
}

export default FormDropDown;
