import axios from './axios.service';

const DEFAULT = '/statistics'
const DASHBOARD = `${DEFAULT}/dashboard`;
const SET_USER_ACTIVE = `${DEFAULT}/dashboard/setUserActive`;
const SET_USER_IDLE = `${DEFAULT}/dashboard/setUserIdle`;

class StatisticsService {
    getDashboardStatistics() {
        return axios.get(DASHBOARD);
    }

    async setUserActive() {
        return axios.post(SET_USER_ACTIVE, {});
    }

    async setUserIdle() {
        return axios.post(SET_USER_IDLE, {});
    }
}

const statisticsService = new StatisticsService();

export default statisticsService;
