import React from 'react'
import {
    Flex,
    Button,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    useColorMode,
} from '@chakra-ui/react'
import { COLOR_NAMES, VARIANT_KEYS, FONT_FAMILY_NAME } from '../../shared/constants';

function Dialog({
    isOpen,
    onClose,
    onAction,
    variantActionButton = VARIANT_KEYS.bgAndColor.bg_redDE4946_color_gray3D403D_whiteFFFFFF,
    messageAction = 'Delete',
    variantCancleButton = VARIANT_KEYS.bgAndborder.bg_transparent_color_black141414_whiteFFFFFF_border_1px_FFB81C_whiteFFFFFF,
    messageBody,
    messageHeader,
    blockScrollOnMount = false }) {
    const cancelRef = React.useRef()
    const { colorMode } = useColorMode();

    const handleOnDelete = () => {
        onClose();
        onAction();
    }

    const bg = colorMode === 'dark'
        ? COLOR_NAMES.black2B2E2A
        : 'white';

    return (
        <AlertDialog
            blockScrollOnMount={blockScrollOnMount}
            closeOnOverlayClick={false}
            isCentered={true}
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose} >
            <AlertDialogOverlay>
                <AlertDialogContent bg={bg}>
                    <AlertDialogHeader fontFamily={FONT_FAMILY_NAME} fontSize='lg' fontWeight='bold'>
                        {messageHeader}
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        {messageBody}
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Flex w='100%' justifyContent={'space-between'}>
                            <Button
                                _focusVisible={{}}
                                fontFamily={FONT_FAMILY_NAME}
                                variant={variantCancleButton}
                                ref={cancelRef}
                                onClick={onClose}>
                                Cancel
                            </Button>
                            <Button fontFamily={FONT_FAMILY_NAME} variant={variantActionButton} onClick={handleOnDelete}>
                                {messageAction}
                            </Button>
                        </Flex>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default Dialog;
