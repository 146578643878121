import React, { Fragment } from 'react';
import { Divider } from '@chakra-ui/react';
import Post from './post.component';
import useKnowMeMediaQuery from '../../shared/hooks/media-query.hook';
import { COLOR_NAMES } from '../../shared/constants';

function PostsList({
  posts,
  onClickReport,
  handleDeletePost,
  toggleFollow,
  userFollows,
  includePeopWhoLikesAndCommentIt,
  repostMode = false,
  addGroupAvatarToPost = false,
  addUserInteractionHeaderToPost = false,
}) {
  const media = useKnowMeMediaQuery();
  const currentPosts = posts && posts.length
    ? posts.map((p, i, row) => {
      return (
        <Fragment key={`${p.id}_fragment`}>
          {i + 1 === row.length
            ? <Post
              data={p}
              toggleFollow={toggleFollow}
              isFollowedByMe={userFollows[p.author]}
              includePeopWhoLikesAndCommentIt={
                includePeopWhoLikesAndCommentIt
              }
              pl={media.isLargerThan750 ? '40px' : '16px'}
              pr={media.isLargerThan750 ? '40px' : '16px'}
              repostMode={repostMode}
              onClickReport={onClickReport}
              handleDeletePost={handleDeletePost}
              addGroupAvatarToPost={addGroupAvatarToPost}
              addUserInteractionHeaderToPost={addUserInteractionHeaderToPost} />
            : <Fragment>
              <Post
                data={p}
                toggleFollow={toggleFollow}
                isFollowedByMe={userFollows[p.author]}
                includePeopWhoLikesAndCommentIt={includePeopWhoLikesAndCommentIt}
                pl={media.isLargerThan750 ? '40px' : '16px'}
                pr={media.isLargerThan750 ? '40px' : '16px'}
                repostMode={repostMode}
                onClickReport={onClickReport}
                handleDeletePost={handleDeletePost}
                addGroupAvatarToPost={addGroupAvatarToPost}
                addUserInteractionHeaderToPost={addUserInteractionHeaderToPost} />
              <Divider mt={'35px'} mb={'35px'} bg={COLOR_NAMES.grayC4C4C4} />
            </Fragment>
          }
        </Fragment>
      );
    })
    : 'No Posts';

  return currentPosts;
}

export default PostsList;
