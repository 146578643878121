import React from 'react';
import Modal from './modal.component';
import WrapperButton from '../buttons/wrapper-button.component';
import Div from '../html-elements/div.component';
import { VARIANT_KEYS, FONTS } from '../../shared/constants';

function ControlMenuModal({ isOpen, onClose, isLargerThan550, children }) {
    return (
        <Modal
            size={!isLargerThan550 ? 'full' : {}}
            scrollBehavior='inside'
            w={isLargerThan550 ? '420px' : '100vw'}
            h={isLargerThan550 ? '800px' : {}}
            isOpen={isOpen}
            isCentered={true}
            bgBlureColor={'rgba(43, 46, 42, 0.9)'}
            blockScrollOnMount={false}
            headerContent={<Div mt='24px' mb='10px' letterSpacing='1.1px' fontSize='20px' textAlign='center' w='100%'>Why are you reporting this?</Div>}
            footerContent={
                <WrapperButton
                    styling={{
                        variant: VARIANT_KEYS.bgAndborder.bg_transparent_color_black141414_whiteFFFFFF_border_1px_FFB81C_whiteFFFFFF,
                        fontSize: '16px',
                        h: '40px',
                        letterSpacing: '1.1px',
                        fontWeight: FONTS.normal[500],
                        w: '100%',
                        mt: '20px',
                        mb: '24px',
                    }}
                    onClickHandle={onClose}
                    message={'Close'} />
            }>
            {children}
        </Modal>
    )
}

export default ControlMenuModal;
