import React from 'react';
import { Modal as ChakraModal, ModalOverlay, ModalContent, ModalBody, ModalFooter, ModalHeader, useColorModeValue, useColorMode } from '@chakra-ui/react';

function Modal({
    w,
    h,
    size,
    bg,
    bgBlureColor,
    isOpen,
    isCentered,
    footerContent,
    headerContent,
    blockScrollOnMount = true,
    scrollBehavior,
    children }) {
    return (
        <ChakraModal size={size} blockScrollOnMount={blockScrollOnMount} scrollBehavior={scrollBehavior} isOpen={isOpen} isCentered={isCentered}>
            <ModalOverlay bg={bgBlureColor} />
            <ModalContent w={w} h={h} bg={bg}>
                {headerContent && <ModalHeader>{headerContent}</ModalHeader>}
                <ModalBody>
                    {children}
                </ModalBody>
                {footerContent && <ModalFooter>{footerContent}</ModalFooter>}
            </ModalContent>
        </ChakraModal>
    )
}

export default Modal;
