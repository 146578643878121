import { mode } from "@chakra-ui/theme-tools";
import { COLOR_NAMES } from '../../shared/constants';

export const Link = {
    baseStyle: {},
    sizes: {},
    variants: {
        white: (props) => ({
            bg: mode('transparent', 'transparent')(props),
            color: mode('#000000', '#FFFFFF')(props),
            _hover: '',
        }),
        blue: (props) => ({
            color: mode(COLOR_NAMES.blue4F93F1, COLOR_NAMES.blue4F93F1)(props),
            bg: mode('transparent', 'transparent')(props),
            _hover: {},
        }),
    },
    defaultProps: {},
}

export default Link;
