import React, { Fragment } from 'react';
import {
    forwardRef,
    Flex,
    useDisclosure,
    MenuItem,
    Button,
    MenuList,
    Menu,
    MenuButton,
    HStack,
    useMediaQuery,
    Drawer,
    DrawerOverlay,
    DrawerBody,
    DrawerContent,
    useColorModeValue,
} from '@chakra-ui/react';
import WrapperButton from '../buttons/wrapper-button.component';
import ExternalIcon from '../icons/external-icon.component';
import Div from '../html-elements/div.component';
import Modal from '../shared/modal.component';
import ModalActionBarContent from './modal-action-bar-content.component';
import CopyToClipboard from '../shared/copy-to-clipboard.component';
import { MEDIA, FONTS } from '../../shared/constants';
import { RiShareForwardLine } from 'react-icons/ri';
import { AiOutlineLink } from 'react-icons/ai';

function Repost({ data, repostedMessage, setRepostedMessage, colors }) {
    const [isLargerThan550] = useMediaQuery(MEDIA.MIN_WIDTH_550_PX);

    return (
        <Fragment>
            {isLargerThan550
                ? <DekstopRepost
                    data={data}
                    repostedMessage={repostedMessage}
                    setRepostedMessage={setRepostedMessage}
                    isLargerThan550={isLargerThan550}
                    colors={colors} />
                : <MobileRepost
                    data={data}
                    repostedMessage={repostedMessage}
                    setRepostedMessage={setRepostedMessage}
                    isLargerThan550={isLargerThan550}
                    colors={colors} />
            }
        </Fragment>
    )
}

export default Repost;

function DekstopRepost({ data, repostedMessage, setRepostedMessage, isLargerThan550, colors }) {
    const { isOpen: isShareOpen, onOpen: onShareOpen, onClose: onShareClose } = useDisclosure();

    return (
        <Fragment>
            <ModalRepost
                data={data}
                onShareClose={onShareClose}
                isShareOpen={isShareOpen}
                setRepostedMessage={setRepostedMessage}
                repostedMessage={repostedMessage}
                isLargerThan550={isLargerThan550} />

            <Div borderRadius='4px' _hover={{ bg: colors.hoverColor }} w='22%' >
                <Menu gutter={-5}>
                    <MenuButton
                        as={Button}
                        p='0px'
                        bg={'transparent'}
                        _active={{ bg: 'transparent' }}
                        _hover={{ bg: 'transparent' }}
                        _focusVisible={{ border: 'none' }}>
                        <Flex letterSpacing={'1.1px'} alignItems={'center'} fontWeight={FONTS.normal[400].fontWeight}>
                            <ExternalIcon icon={RiShareForwardLine} styling={
                                {
                                    w: '18px',
                                    h: '18px',
                                    p: '0px',
                                    bg: 'transparent'
                                }} />
                            <Div pt='2px' ml='6px' fontSize={'14px'}>Share</Div>
                        </Flex>
                    </MenuButton>
                    <MenuList
                        h='120px'
                        w={isLargerThan550 ? '300px' : '100vw'}
                        _hover={{}}
                        bg={colors.bgColor}
                        position={'absolute'}
                        right={isLargerThan550 ? '-28px' : '-85px'}
                        bottom={isLargerThan550 ? {} : '0'}>
                        <MenuItem
                            h='100%'
                            w={'100%'}
                            _active={{ bg: 'transparent' }}
                            _hover={{ bg: 'transparent' }}
                            _focus={{}}
                            borderRadius='10px'
                            closeOnSelect={false}>
                            <Flex pl='20px' justifyContent={'space-around'} direction={'column'} h='100%'>
                                <HStack onClick={onShareOpen}>
                                    <Div><ExternalIcon icon={RiShareForwardLine} styling={{ w: '16px', h: '16px', p: '0px' }} /></Div>
                                    <Div>Repost</Div>
                                </HStack>
                                <Div>
                                    <CopyToClipboard
                                        w='102px'
                                        iconLeft={true}
                                        copiedMessageGetHeigth={false}
                                        icon={AiOutlineLink}
                                        copiedValue={`${window.location.host}/comment-post/${data.uuid}`}
                                        value={'Copy link'} />
                                </Div>
                            </Flex>
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Div>
        </Fragment>
    )
}

function MobileRepost({ data, repostedMessage, setRepostedMessage, isLargerThan550, colors }) {
    const { isOpen: isDrrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure()
    const { isOpen: isShareOpen, onOpen: onShareOpen, onClose: onShareClose } = useDisclosure();
    const backdropColor = useColorModeValue('rgba(240, 240, 240, 0.95)','rgba(43, 46, 42, 0.9)')

    function openShareAndCloseDrawer() {
        onDrawerClose();
        onShareOpen();
    }

    const Button = forwardRef((pros, ref) => <WrapperButton
        onClickHandle={onDrawerOpen}
        styling={{
            bg: 'transparent',
            _active: { bg: 'transparent' },
            _hover: { bg: colors.hoverColor },
            _focus: {},
        }}
        message={
            <Flex letterSpacing={'1.1px'} alignItems={'center'} fontWeight={FONTS.normal[400].fontWeight}>
                <ExternalIcon
                    icon={RiShareForwardLine}
                    styling={
                        {
                            w: '18px',
                            h: '18px',
                            p: '0px',
                            bg: 'transparent'
                        }} />
                <Div pt='2px' ml='6px' fontSize={'14px'}>Share</Div>
            </Flex>} />);

    return (
        <Fragment>
            <Button />
            <Drawer
                isOpen={isDrrawerOpen}
                placement='bottom'
                onClose={onDrawerClose}
                finalFocusRef={Button}>
                <DrawerOverlay bg={backdropColor} />
                <DrawerContent bg={'transparent'}>
                    <DrawerBody borderTopRadius={'30px'}>
                        <Div
                            h='102px'
                            w={'100%'}
                            _active={{ bg: 'transparent' }}
                            _hover={{ bg: 'transparent' }}
                            _focus={{}}
                            borderRadius='10px'>
                            <Flex pl='20px' justifyContent={'space-around'} direction={'column'} h='100%'>
                                <HStack cursor={'pointer'} onClick={openShareAndCloseDrawer}>
                                    <Div><ExternalIcon icon={RiShareForwardLine} styling={{ w: '16px', h: '16px', p: '0px' }} /></Div>
                                    <Div>Repost</Div>
                                </HStack>
                                <Div>
                                    <CopyToClipboard
                                        w='102px'
                                        iconLeft={true}
                                        copiedMessageGetHeigth={false}
                                        icon={AiOutlineLink}
                                        copiedValue={`${window.location.host}/comment-post/${data.uuid}`}
                                        value={'Copy link'} />
                                </Div>
                            </Flex>
                        </Div>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
            <ModalRepost
                data={data}
                onShareClose={onShareClose}
                setRepostedMessage={setRepostedMessage}
                repostedMessage={repostedMessage}
                isLargerThan550={isLargerThan550}
                isShareOpen={isShareOpen}
                blockScrollOnMount={false} />
        </Fragment>
    )
}

function ModalRepost({ data, onShareClose, isShareOpen, setRepostedMessage, repostedMessage, isLargerThan550, blockScrollOnMount = true }) {
    return (
        <Modal
            blockScrollOnMount={blockScrollOnMount}
            w={isLargerThan550 ? '555px' : '100vw'}
            h={isLargerThan550 ? '855px' : '100%'}
            isOpen={isShareOpen}
            isCentered={true}
            bgBlureColor={'rgba(43, 46, 42, 0.9)'} >
            <ModalActionBarContent
                data={data}
                onClose={onShareClose}
                repostedMessage={repostedMessage}
                onChangeMessageHandle={(e) => setRepostedMessage(e.target.value)} />
        </Modal>
    )
}
