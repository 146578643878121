import React, { Fragment } from 'react';
import { Flex, Divider, useColorModeValue } from '@chakra-ui/react';
import ExternalIcon from '../icons/external-icon.component';
import Div from '../html-elements/div.component';
import { COLOR_NAMES, FONTS } from '../../shared/constants';
import { BsArrowRight } from 'react-icons/bs';

function ReportReason({ handleOnClick }) {
    const icon = <ExternalIcon styling={{ fontSize: '30px' }} icon={BsArrowRight} />
    const color = useColorModeValue(COLOR_NAMES.grayC4C4C4, COLOR_NAMES.gray3D403D)
    const messages = [
        { message: 'Suspicious, spam, or fake' },
        { message: 'Harassment or hateful speech' },
        { message: 'Violence or physical harm' },
        { message: 'Adult content' },
        { message: 'Intellectual property' },
    ];

    const notReasonApplyMessage = 'I still don`t want see this';

    const rowStyling = {
        pr: '10px',
        pl: '20px',
        _hover: { bg: color },
        h: '52px',
        letterSpacing: '1.1px',
        borderRadius: '6px',
        cursor: 'pointer',
    }

    return (
        <Fragment>
            {messages.map((m, i) => {
                return (
                    <Div  {...rowStyling} onClick={() => handleOnClick(m.message)} key={`reason_${i}`}>
                        <Flex h='100%' alignItems={'center'} justifyContent={'space-between'}>
                            <Div>{m.message}</Div>
                            <Flex>{icon}</Flex>
                        </Flex>
                    </Div>
                )
            })}

            <Divider mb='26px' mt={'10px'} bg={COLOR_NAMES.grayC4C4C4} />

            <Div mb='26px' letterSpacing={'1.1px'} {...FONTS.normal[700]} textAlign='start'>None of the reporting reasons apply.</Div>
            <Div {...rowStyling} onClick={() => handleOnClick(notReasonApplyMessage)}>
                <Flex h='100%' alignItems={'center'} justifyContent={'space-between'}>
                    <Div>{notReasonApplyMessage}</Div>
                    <Flex>{icon}</Flex>
                </Flex>
            </Div>
        </Fragment>
    )
}

export default ReportReason;
