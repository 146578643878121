import { toast } from 'react-toastify';

class ToastService {
    success(message, autoClose = 2000) {
        toast.success(message, {
            autoClose: autoClose
        });

        this.clearWaitingQueue();
    };

    error(message, autoClose = 2000) {
        toast.error(message, {
            autoClose: autoClose,
        });

        this.clearWaitingQueue();
    };

    warning(message, autoClose = 2000) {
        toast.warning(message, {
            autoClose: autoClose
        });

        this.clearWaitingQueue();
    };

    info(message, autoClose = 2000) {
        toast.info(message, {
            autoClose: autoClose
        });
        
        this.clearWaitingQueue();
    };

    clearWaitingQueue() {
        toast.clearWaitingQueue();
    }
}

const toastService = new ToastService();

export default toastService;