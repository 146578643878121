import React, { useEffect, useState } from 'react';
import { Flex, FormControl, FormLabel, Switch, VStack } from '@chakra-ui/react';
import Div from '../components/html-elements/div.component';
import { DEFAULT_NOTIFICATION_SETTINGS, FONTS, NOTIFICATION_SETTINGS_GROUPS, NOTIFICATION_SETTINGS_NAMES, } from '../shared/constants';
import notificationService from '../services/notification.service';
import toastService from '../services/toast.service';

function NotificationsSettingsPage() {
    const [userSettings, setUserSettings] = useState(DEFAULT_NOTIFICATION_SETTINGS);
    const [sortedNotificationsList, setSortedNotificationsList] = useState({});

    useEffect(() => {
        getNotificationSettings();

    }, []);

    const getNotificationSettings = async () => {
        try {
            const defaultSettings = DEFAULT_NOTIFICATION_SETTINGS;
            const result = (await notificationService.getSettings()).data.data;
            result.forEach(notifType => defaultSettings[notifType] = false);

            setUserSettings(defaultSettings)

            const settingsEntries = Object.entries(userSettings)
            const settingsArrOfObjectsKVP = settingsEntries.map(kvp => {
                return Object.create({}, { type: { value: kvp[0] }, value: { value: kvp[1] } })
            })

            setSortedNotificationsList(sortSettings(settingsArrOfObjectsKVP));
        } catch (error) {
            console.log(error)
        }
    }

    const singleSettingComponent = (setting) => {
        return <Flex key={setting.type} justifyContent={'center'} alignItems={'center'} marginRight={'10px'}>
            <FormLabel htmlFor={setting.type} fontSize={'18px'} opacity={'0.8'}>
                {NOTIFICATION_SETTINGS_NAMES[setting.type]}
            </FormLabel>
            <Switch
                id={setting.type}
                size='md'
                pb='8px'
                colorScheme='green'
                defaultChecked={setting.value}
                onChange={(e) => handleSettingsChange(e, setting.type)} />
        </Flex>
    }

    const handleSettingsChange = async (e, notifType) => {
        const newValue = e.target.checked;
        try {
            await notificationService.editSetting({ notifType: notifType });
            setUserSettings(prev => { return { ...prev, [notifType]: newValue } });
        } catch (error) {
            toastService.error(error.response.data.errorMessage);
        }
    }

    const sortSettings = (settingsList) => {
        settingsList.sort((a, b) => (a.type > b.type) ? 1 : -1)
        const commentNotifications = [];
        const postNotifications = [];
        const groupNotifications = [];
        const personalNotification = [];
        const backOfficeNotification = [];

        settingsList.forEach(setting => {
            if (NOTIFICATION_SETTINGS_GROUPS.COMMENTS_NOTIFICATIONS.includes(setting.type)) {
                commentNotifications.push(singleSettingComponent(setting));
            }
            else if (NOTIFICATION_SETTINGS_GROUPS.POSTS_NOTIFICATIONS.includes(setting.type)) {
                postNotifications.push(singleSettingComponent(setting));
            }
            else if (NOTIFICATION_SETTINGS_GROUPS.GROUP_NOTIFICATIONS.includes(setting.type)) {
                groupNotifications.push(singleSettingComponent(setting));
            }
            else if (NOTIFICATION_SETTINGS_GROUPS.PERSONAL_NOTIFICATIONS.includes(setting.type)) {
                personalNotification.push(singleSettingComponent(setting));
            }
            else if (NOTIFICATION_SETTINGS_GROUPS.BACK_OFFICE_NOTIFICATIONS.includes(setting.type)) {
                backOfficeNotification.push(singleSettingComponent(setting));
            }
        });

        const sorted = {}
        sorted.commentNotifications = commentNotifications;
        sorted.postNotifications = postNotifications;
        sorted.groupNotifications = groupNotifications;
        sorted.personalNotification = personalNotification;
        sorted.backOfficeNotification = backOfficeNotification;

        return sorted;
    }

    return (
        <VStack>
            <Div w='100%' fontSize={'26px'} fontWeight={FONTS.normal[500].fontWeight} mb='20px'>Notification settings</Div>
            <FormControl>
                <VStack p='20px'>
                    <VStack w='100%' pb='20px'>
                        <Div fontWeight={FONTS.normal[500].fontWeight} w={'100%'} fontSize='22px' textAlign='left'>Personal notifications</Div>
                        <Flex w='100%' flexWrap={'wrap'} flexDir={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'12px'}>
                            {sortedNotificationsList.personalNotification}
                        </Flex>
                    </VStack>
                    <VStack w='100%' pb='20px'>
                        <Div fontWeight={FONTS.normal[500].fontWeight} w={'100%'} fontSize='22px' textAlign='left'>Posts notifications</Div>
                        <Flex w='100%' flexWrap={'wrap'} flexDir={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'12px'}>
                            {sortedNotificationsList.postNotifications}
                        </Flex>
                    </VStack>
                    <VStack w='100%' pb='20px'>
                        <Div fontWeight={FONTS.normal[500].fontWeight} w={'100%'} fontSize='22px' textAlign='left'>Comments notifications</Div>
                        <Flex w='100%' flexWrap={'wrap'} flexDir={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'12px'}>
                            {sortedNotificationsList.commentNotifications}
                        </Flex>
                    </VStack>
                    <VStack w='100%' pb='20px'>
                        <Div fontWeight={FONTS.normal[500].fontWeight} w={'100%'} fontSize='22px' textAlign='left'>Group notifications</Div>
                        <Flex w='100%' flexWrap={'wrap'} flexDir={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'12px'}>
                            {sortedNotificationsList.groupNotifications}
                        </Flex>
                    </VStack>
                    <VStack w='100%' pb='20px'>
                        <Div fontWeight={FONTS.normal[500].fontWeight} w={'100%'} fontSize='22px' textAlign='left'>Back office notifications</Div>
                        <Flex w='100%' flexWrap={'wrap'} flexDir={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'12px'}>
                            {sortedNotificationsList.backOfficeNotification}
                        </Flex>
                    </VStack>
                </VStack>
            </FormControl>
        </VStack>
    )
}

export default NotificationsSettingsPage;