export function hasSpecialCharactersInPassword(string) {
  if (!string) return false;
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^.,:;{}[\]<>()_\-+='"`~#/\\|])[A-Za-z\d@$!%*?&^.,:;{}[\]<>()_\-+='"`~#/\\|]{7,}$/;

  return regex.test(string);
}

export const hasSpecialCharacters = (str) => {
  const pattern = /[^A-Za-z0-9_\s]/gm;
  return pattern.test(str);
};

export const isEmailValid = (email) => {
  const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return pattern.test(email);
};

export const isNameValid = (name) => {
  const regName = /[a-zA-Z]+\s[a-zA-Z]+$/;
  return regName.test(name);
};

export const isPhoneValid = (phone) => {
  const regName = /[0-9]+$/;
  return regName.test(phone);
};

export const containsLettersAndUnderscoreOnly = (word) => {
  const regName = /^[a-zA-Z_ ]*$/;
  return regName.test(word);
};

export const containsLettersOnly = (word) => {
  const regName = /[A-Z][a-z]+/;
  return regName.test(word);
};

export const getRandomElementFromArr = (arr) => {
  return arr[getRandomNumber(arr.length)];
};

export const getRandomNumber = (maxNumber) => {
  return Math.floor(Math.random() * maxNumber);
};