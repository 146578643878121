import SideBar from './side-bar.component';
import { useNavigate } from 'react-router-dom';
import { Center, VStack, Box, Tooltip } from '@chakra-ui/react';
import WrapperButtonLink from '../buttons/wrapper-button-link.component';
import WrapperButton from '../buttons/wrapper-button.component';
import ExternalIcon from '../icons/external-icon.component';
import useAuth from '../../shared/hooks/auth.hook';
import useConfigurations from '../../shared/hooks/configurations.hook';
import statisticsService from '../../services/statistics.service';
import { ROUTES, FONTS, VARIANT_KEYS, CONFIGURATION_KEYS } from '../../shared/constants';
import { TiPencil } from 'react-icons/ti';
import { MdOutlineGroup, MdLogout } from 'react-icons/md';
import ThemeMode from '../chakra/theme-mode.component';

function LeftNavigation() {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const { configurations } = useConfigurations();

    const logoutApp = async () => {
        // Temporary solution
        try {
            await statisticsService.setUserIdle();
        } catch (error) { }

        await logout();
        navigate(ROUTES.DEFAULT);
    }

    const knowMeButtonStyling = {
        fontWeight: FONTS.normal[700],
        variant: VARIANT_KEYS.bgAndColor.bg_oragneFDB71A_oragneFFB81C_color_black141414_black141414,
        border: '6px',
        pt: '1px',
        pb: '1px',
        pr: '14px',
        pl: '14px',
        fontSize: '14px',
        height: '28px',
        gap: '10px',
        letterSpacing: '2px'
    };

    const bigButtonStyling = {
        fontWeight: FONTS.normal[500],
        _hover: {},
        pt: '10px',
        pb: '10px',
        pr: '38px',
        pl: '24px',
        w: '160px',
        fontSize: '16px',
        height: '48px',
    }

    const logoutButtonStyling = {
        fontWeight: FONTS.normal[400],
        variant: VARIANT_KEYS.bgAndColor.bg_transparent_color_gray757575_orangeFFB81C,
        fontSize: '16px',
        lineheight: '28px',
        letterSpacing: 1.1,
        leftIcon: <ExternalIcon icon={MdLogout} />
    }

    return (
        <Box h='100vh'>
            <VStack h='100vh' alignItems='start'>
                <Box flexGrow={1} marginTop='18px' w='100%'>
                    <Center>
                        <WrapperButtonLink to={ROUTES.HOME} message={'KnowMe'} styling={knowMeButtonStyling} />
                    </Center>
                </Box>

                <Box flexGrow={5}>
                    <SideBar />
                </Box>

                <Box flexGrow={3} w='100%'>
                    <VStack spacing={4}>
                        <Tooltip label={configurations[CONFIGURATION_KEYS.posting].isActive ? '' : configurations[CONFIGURATION_KEYS.posting].messageUnavailable} placement={'auto-start'}>
                            <Center>
                                <WrapperButtonLink
                                    to={ROUTES.CREATE_POST}
                                    message={'New Post'}
                                    styling={
                                        {
                                            ...bigButtonStyling,
                                            variant: VARIANT_KEYS.bgAndColor.bg_greenA9E5B6_green54A565_color_black141414_whiteFFFFFF,
                                            disabled: !configurations[CONFIGURATION_KEYS.posting].isActive,
                                            leftIcon: <ExternalIcon icon={MdOutlineGroup} />,
                                        }} />
                            </Center>
                        </Tooltip>
                        <Tooltip label={configurations[CONFIGURATION_KEYS.groupCreation].isActive ? '' : configurations[CONFIGURATION_KEYS.groupCreation].messageUnavailable} placement={'auto-start'}>
                            <Center>
                                <WrapperButtonLink to={ROUTES.CREATE_GROUP}
                                    message={'New Group'}
                                    styling={
                                        {
                                            ...bigButtonStyling,
                                            variant: VARIANT_KEYS.bgAndColor.bg_blue9ED0F3_blue5483A5_color_black141414_whiteFFFFFF,
                                            disabled: !configurations[CONFIGURATION_KEYS.groupCreation].isActive,
                                            leftIcon: <ExternalIcon icon={TiPencil} />,
                                        }} />
                            </Center>
                        </Tooltip>
                    </VStack>
                </Box>
                <ThemeMode />
                <Box flexGrow={1}>
                    <WrapperButton onClickHandle={logoutApp} message={'Log out'} styling={logoutButtonStyling} />
                </Box>
            </VStack>
        </Box>
    )
}

export default LeftNavigation;
