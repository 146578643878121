import React, { Fragment, useState } from 'react';
import { Flex, HStack, Divider, useColorModeValue } from '@chakra-ui/react';
import WrapperButton from '../buttons/wrapper-button.component';
import ExternalIcon from '../icons/external-icon.component';
import Repost from '../posts/repost.component';
import Div from '../html-elements/div.component';
import PostLikes from './post-likes.component';
import postService from '../../services/post.service';
import { COLOR_NAMES, FONTS, COMMENT_MODE } from '../../shared/constants';
import { MdOutlineComment, MdFavorite } from 'react-icons/md';

function ActionBarPost({ commentsCount, data, commentModeHandle }) {
    const [isLikedByMe, setIsLikedByMe] = useState(data.isLikedByMe || false);
    const [likesCount, setLikesCount] = useState(data.likesCount || 0);

    const [repostedMessage, setRepostedMessage] = useState('');
    const buttonHoverColor = useColorModeValue(COLOR_NAMES.grayC4C4C4, COLOR_NAMES.gray3D403D);
    const bg = useColorModeValue('white', COLOR_NAMES.black161717);

    const handleLike = async () => {
        const increment = isLikedByMe ? -1 : 1;

        try {
            const response = await postService.changePostLikes({ likes: increment }, data.uuid);
            if (response.data.isSuccess !== true) {
                throw new Error(response.data.message);
            }

            setLikesCount(response.data.data);
            setIsLikedByMe(prev => !prev);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Fragment>
            <Flex justifyContent={'start'} w='100%'>
                <HStack w='100%'>
                    <Div w='50%'>
                        <PostLikes postId={data.id} likesCount={likesCount} isLikedByMe={isLikedByMe} />
                    </Div>
                    <Flex
                        onClick={() => commentModeHandle(COMMENT_MODE.READ)}
                        opacity='60%'
                        letterSpacing={'1.1px'}
                        justifyContent={'end'}
                        w='50%'>
                        <Div cursor={'pointer'} fontSize='16px'>{commentsCount} comments</Div>
                    </Flex>
                </HStack>
            </Flex>
            <Divider mb='15px' mt={'26px'} bg={COLOR_NAMES.grayC4C4C4} />
            <Flex justifyContent={'space-between'}>
                <Div borderRadius='4px' _hover={{ bg: buttonHoverColor }} w='18%' >
                    <WrapperButton
                        onClickHandle={handleLike}
                        styling={{
                            p: '0px',
                            bg: 'transparent',
                            _active: { bg: 'transparent' },
                            _hover: { bg: 'transparent' },
                            _focusVisible: { border: 'none' },
                        }}
                        message={
                            <Flex letterSpacing={'1.1px'} alignItems={'center'} fontWeight={FONTS.normal[400].fontWeight}>
                                <ExternalIcon icon={MdFavorite} styling={
                                    {
                                        w: '18px',
                                        h: '18px',
                                        p: '0px',
                                        bg: 'transparent'
                                    }} />
                                <Div pt='2px ' ml='6px' fontSize={'14px'}>Like</Div>
                            </Flex>} />
                </Div>
                <Div borderRadius='4px' _hover={{ bg: buttonHoverColor }} w='30%' >
                    <WrapperButton
                        styling={{
                            p: '0px',
                            bg: 'transparent',
                            _active: { bg: 'transparent' },
                            _hover: { bg: 'transparent' },
                            _focusVisible: { border: 'none' },
                        }}
                        onClickHandle={() => commentModeHandle(COMMENT_MODE.WRITE)}
                        message={
                            <Flex letterSpacing={'1.1px'} alignItems={'center'} fontWeight={FONTS.normal[400].fontWeight}>
                                <ExternalIcon icon={MdOutlineComment} styling={
                                    {
                                        w: '18px',
                                        h: '18px',
                                        p: '0px',
                                        bg: 'transparent'
                                    }} />
                                <Div pt='2px' ml='6px' fontSize={'14px'}>Comment</Div>
                            </Flex>} />
                </Div>
                <Repost data={data} repostedMessage={repostedMessage} setRepostedMessage={setRepostedMessage} colors={{ hoverColor: buttonHoverColor, bgColor: bg }} />
            </Flex>
        </Fragment>
    )
}

export default ActionBarPost;
